import React, { useState } from "react";
import SideBar from "../components/sidebar-navbar";
import { containerStyle } from "../components/styles/styles";
import DashboardRoutes from "./dashboardRoute";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const classes = containerStyle();
  const location = useLocation();

  
  return (
    <>
      <div className={classes.root}>
        {
          location.pathname != '/dashboard' &&
          <SideBar iconButton drawerSection noFullAppBar logoOnClose appBarTitle="Dashboard" />
        }
        <main className={classes.content}>
          {
            location.pathname != '/dashboard' &&
            <div className={classes.toolbar} />
          }
          <DashboardRoutes />
        </main>
      </div> 
    </>
    
  );
};

export default Dashboard;
