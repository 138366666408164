import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { addAmount, setPrice } from "../../../../../../../store/userData";
import { getPlanSteps } from "../../../../../../../store/steps";

import CustomSelect from "../../../../../../common/Select";
import AuthButton from "../../../../../../Containers/AuthButton";
import PricingCard from "../../../../../../UI/PricingCard";
import Form from "../../../../../../Containers/Form";


import styles from "./index.module.css";
import { getInsuranceCalculator } from "../../../../../../../store/insuranceCalculator";
import axios from "axios";
import { CustomFormControl } from "../../../../../../common";
import { TextField } from "@mui/material";

const infoPopUp = () => {
  return (
    <div className={styles["info-container"]}>
      <p>This is a cancer test that covers breast cancer, lung and ovarian cancers</p>
      <p>Required Sample</p>
      <p>Blood</p>
    </div>
  );
};

const CancerScreeningCheckUp = ({ firstName, setFirstName, lastName, setLastName, phone, setPhone, email, setEmail, promo, setPromo, buyOther, setBuyOther, setGender, setStep, setService, setAmount, allValue, allField, setAllValue, setAllField ,amount}) => {
  const { values: stepsValues } = useSelector(getPlanSteps);
  const [sex, setSex] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [hospitalLocation, setHospitalLocation] = useState("");
  const [hospital, setHospital] = useState("");
  const [spouse, setSpouse] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [cLoad, setCload] = useState(false);
  const [cancerData, setCancerData] = useState({
    cost: '',
    screenings: []
  });

  const { loading, error } = useSelector(getInsuranceCalculator);
  const [screeningData, setScreeningData] = useState([]);

  const fetchCancerScreen = async () => {
    setCload(true);
    let currentSex;
    if (sex == 'man') {
      currentSex = 'csm';
      setGender('csm');
    } else if (sex == 'woman') {
      currentSex = 'csw';
      setGender('csw');
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/cancer/${currentSex}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        },);
      setCload(false);
      setCancerData(data.data);
      setScreeningData(data.data.screenings);
      let newObject = {};
      const myService = data?.data?.screenings?.map((d, index) => {
        const name = d.name;
        return { field: name, value: 0, type: "cancer-screening", ans: "Yes" };

      });
      console.log(myService);
      setService(myService);
      setAmount(data.data.cost);
      dispatch(addAmount(data.data.cost - (promo / 100 * data.data.cost)));
      dispatch(setPrice(data.data.cost - (promo/100 * data.data.cost)))
    } catch (err) {
      console.log(err);
    }
  }





  useEffect(() => {
    fetchCancerScreen();
  }, [sex, promo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(2);
  };

  return (
    <Form onSubmit={handleSubmit} className="py-5">
      <div className="row">
        <label htmlFor="text">Sex</label>
        <CustomSelect
          label="Select Options"
          value={sex}
          onChange={({ target }) => {
            setSex(target.value);
          }}
          loading={loading}
          items={[
            { value: "man", label: "Man" },
            { value: "woman", label: "Woman" },
          ]}
        />
      </div>
      <CustomSelect
        label={'Buy For'}
        value={buyOther}
        onChange={({ target }) => setBuyOther(target.value)}
        loading={false}
        required
        items={[
          { value: "myself", label: "Myself" },
          { value: "other", label: "Others" },
        ]}
      />
      {
        buyOther == 'other' &&
        <>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'FirstName'}
              label={'First Name'}
              variant="outlined"
              value={firstName}
              required
              onChange={({ target }) => setFirstName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'LastName'}
              label={'Last Name'}
              variant="outlined"
              value={lastName}
              required
              onChange={({ target }) => setLastName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'Phone'}
              label={'Phone Number'}
              variant="outlined"
              value={phone}
              required
              onChange={({ target }) => setPhone(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'email'}
              label={'Email Address'}
              variant="outlined"
              value={email}
              required
              onChange={({ target }) => setEmail(target.value)}
            />
          </CustomFormControl>
        </>
      }

      <div>
        <p className="mb-7 mt-7 font-bold">{sex && `These are services offered for ${sex}`}</p>
        {
          (!cLoad && screeningData.length > 0) ?
            screeningData?.map((data, index) => {
              return (
                <>
                  <div className={styles["disabled-field"]}>{data.name}</div>
                  <label htmlFor="text">{data.description}</label>
                </>
              )
            })
            :
            <div className="w-full flex justify-center text-[26px] font-bold">Select an option</div>
        }
      </div>
      <div className="pb-10">
        <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
      </div>
      <PricingCard disabled={true} />
      {promo &&
        <div className='flex'>
          <div>{promo}% discount</div>
          <div className='ml-[10px] line-through font-bold'>N{amount}</div>
        </div>}
    </Form>
  );
};

export default CancerScreeningCheckUp;
