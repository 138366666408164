import React, { useState }  from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

function CategoryList() {
    const [selectTab, setSelectTab] = useState({
        id:null,
        name:'All'
    });

    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 200;
    };
    
    const slideRight = () => {
      var slider = document.getElementById('slider');
      slider.scrollLeft = slider.scrollLeft + 200;
    };
  return (
    <div className='flex justify-between pt-4'>
        <div className='w-full items-center flex'>
            <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideLeft} size={40} />
            <div id='slider' className='w-full flex items-center h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]'>
                <div  onClick={()=>  { setSelectTab({id:null,name:'All'}); }} className={`${selectTab.name == 'All' ? 'bg-[#1B1266]  text-white':'border border-[#17599A] text-[#17599A] rounded-[24px]'} px-2 rounded-[16px] flex justify-center items-center min-w-[41px] h-[34px] cursor-pointer mr-[12px]`}>All</div>
                <div className={`${selectTab.name == "dental" ? 'bg-kdarkblue4  text-white':'border border-[#17599A] text-[#17599A] rounded-[24px]'} px-2 rounded-[16px] flex justify-center min-w-[50px] h-[44px] items-center p-2 cursor-pointer mr-[12px]`}>Dental</div>
            </div>
            <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideRight} size={40} />
        </div>
    </div>
        
  )
}

export default CategoryList