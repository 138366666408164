import React, { useEffect, useState } from "react";

import styles from "./index.module.css";
import Process from "./Process";
import FormContainer from "../../Containers/FormContainer";
import CreatePlan from "./widgets/CreatePlan";
import Biodata from "./widgets/Biodata";
import MedicalService from "./widgets/MedicalService";
import MedicalServiceBenefits from "./widgets/MedicalServiceBenefits";
import { useParams } from "react-router-dom";
import { getBuyPlanSteps, setSteps } from "../../../utils/helper";
import { BUY_PLAN_STEPS_TITLE, BUY_PLAN_URLS } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitalLocation } from "../../../store/hospitals";
import ModalLoader from "../../common/Loader";
import { getInsuranceCalculator, stopLoading } from "../../../store/insuranceCalculator";
import Alert from "../../common/Alert";
import { getPlanSteps, updateSteps } from "../../../store/steps";
import _ from "lodash";
import Dependents from "./widgets/Dependents";
import { IoArrowBackOutline } from "react-icons/io5";
import { getUserInfo } from "../../../store/userData";

const BuyPlan = () => {
  const { values } = useSelector(getPlanSteps);
  // const { sex, type } = useSelector(getUserInfo);
  // const [steps, setSteps] = useState(values);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentStep = getBuyPlanSteps(params.step);
  const { loading, error } = useSelector(getInsuranceCalculator);

  // console.log(values);

  useEffect(() => {
    if (error) Alert.notication("error", "Error", error);

    setSteps(values, currentStep, dispatch);
    dispatch(fetchHospitalLocation());
    dispatch(stopLoading());
  }, [dispatch, error]);

  useEffect(() => {
    if (!currentStep) return history.push(BUY_PLAN_URLS.CREATE_YOUR_PLAN);
  }, [currentStep, history, dispatch]);

  return (
    <div className={styles["plan-wrapper"]}>
      <div>Testing Components</div>
    </div>
  );
};

export default BuyPlan;
