import { BUY_PLAN_STEPS_TITLE, DENTAL_CARE_STEPS, OPTICAL_CARE_STEPS, STEPS } from "./constants";
import { updateSteps } from "../store/steps";
import _, { join } from "lodash";

import axios from "axios";
import { enrolleeDetailAPI } from "./apis";


const token = localStorage.getItem("token");

export const fetchPromo = async (setPromo) => {
  try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v2/promo`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
      }
      });

      console.log(data);
      if(data?.data?.is_used) {
        setPromo(0);
      }else {
        setPromo(data?.data?.discount_percent);
      }
      

  }catch(err) {
      console.log(err);
  }
}


export const postBuyOther = async (obj,toast,history) => {
  try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/plans/purchase-for-others`,
          obj, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}`
          }
      });

      history.push('/dashboard/wellnesshmo');
      toast.success(data?.message, {
          position: "bottom-right"
      });
  } catch (err) {
      toast.error(err?.response?.data?.message, {
          position: "bottom-right"
      });
      console.log(err);
  }
}

export const checkEnrolleeStatus = async(status) => {
  let isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
  isEnrollee = isEnrollee?.insurance_no;
    try {
        const {data} = await axios.get(`${enrolleeDetailAPI}`,
        {
          params: {
            insurance_no: isEnrollee
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        },);
        
        return data?.data?.enrollee?.status;
    }catch(err) {
        console.log(err);
    }
}

export const getBuyPlanSteps = (step) => {
  switch (step) {
    case "create-your-plan":
      return BUY_PLAN_STEPS_TITLE.CREATE_YOUR_PLAN;
    case "biodata":
      return BUY_PLAN_STEPS_TITLE.BIODATA;
    case "dependents":
      return BUY_PLAN_STEPS_TITLE.DEPENDENT;
    case "medical-services":
      return BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES;
    case "medical-services-benefits":
      return BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES_BENEFITS;
    default:
      return ;
  }
};

export const getDentalCareSteps = (step) => {
  switch (step) {
    case "create-dental-care":
      return DENTAL_CARE_STEPS.STEP_ONE;
    case "other-services-one":
      return DENTAL_CARE_STEPS.STEP_TWO;
    case "other-services-two":
      return DENTAL_CARE_STEPS.STEP_THREE;
    default:
      return DENTAL_CARE_STEPS.STEP_ONE;
  }
};

export const getOpticalCareSteps = (step) => {
  switch (step) {
    case "create-optical-care":
      return OPTICAL_CARE_STEPS.STEP_ONE;
    case "other-services-one":
      return OPTICAL_CARE_STEPS.STEP_TWO;
    case "other-services-two":
      return OPTICAL_CARE_STEPS.STEP_THREE;
    default:
      return OPTICAL_CARE_STEPS.STEP_ONE;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function numberWithCommas(number) {
  if (!number) return number;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function resetSteps(dispatch) {
  dispatch(updateSteps(STEPS));
}

export const setSteps = (values, currentStep, dispatch) => {
  const index = values.findIndex((step) => step.title === currentStep);
  const newIndex = index - 1;
  const newProcesses = _.cloneDeep(values);

  if (newIndex >= 0) {
    newProcesses[newIndex].activeBorder = "border-active";
    newProcesses[index].isActive = true;
    newProcesses[newIndex].completed = true;

    newProcesses.forEach((process, _index) => {
      if (_index !== newIndex) {
        process.activeBorder = "border-inactive";
      }
      if (_index !== index) {
        process.isActive = false;
      }
    });
  }
  // setSteps(newProcesses);
  dispatch(updateSteps(newProcesses));
};


export function CombineDateAndTime(date, time) {
  let separatedTime = time.split(":");
  console.log(separatedTime);
  let joinedTime = `${separatedTime[0]}:${separatedTime[1]}`
 console.log(joinedTime);
  let combined = `${date}T${joinedTime}`;
  return combined;
};
