import React, { useState } from 'react'
import {CustomFormControl,ModalLoader,PasswordField} from '../../../common';
import {AuthButton} from '../../../Containers';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';

function ChangePassword() {
    const [step, setStep] = useState(1);
    const [password, setPassword] = useState({ password: "", showPassword: false, invalid: false });
    const [newPassword, setNewPassword] = useState({ password: "", showPassword: false, invalid: false });
    const [confirmPassword, setConfirmPassword] = useState({ password: "", showPassword: false, invalid: false });
    const history = useHistory();
    const email = localStorage.getItem('email');
    const [loading, setLoading] = useState(false);

    const verifyLogin = async(e) => {
        e.preventDefault();
        setLoading(true);
        try {
        const resp = await axios.request({
            baseURL: `${process.env.REACT_APP_API_URL}/v1`,
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Headers" : "Content-Type",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
            },
            url:'/login',
            method:'POST',
            data:{ email: email, password: password.password }
          })
            setLoading(false);
            handleCurrentPassword();
        }catch(err) {
            setLoading(false);
            toast.error(err?.response?.data?.message,{
                position:"bottom-right"
            });
            console.log(err);
        }
    }
    const handleCurrentPassword = () => {
        setStep(2);
    }

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        if(newPassword.password.length < 8) {
          toast.error('Password should have atleast 8 characters',{
            position:"bottom-right"
          });
        }
        else if(newPassword.password !== confirmPassword.password) {
          toast.error('Kindly Confirm the right password',{
            position:"bottom-right"
          });
        } else {
          setLoading(true);
          try {
            const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/update-pwd`,{
              email,
              password: newPassword.password,
              password_confirmation: confirmPassword.password
            });
            setLoading(false);
            toast.success(data?.message,{
              position:"bottom-right"
            });
            history.push('/dashboard/profile')
          }catch(err) {
            toast.error(err?.response?.data?.message,{
                position:"bottom-right"
            });
            setLoading(false);
            console.log(err);
          }
        }
        
      };

  return (
    <>

        {
            step == 1 &&
            <div className='bg-white w-full min-h-[90vh] p-[12px]'>
                <ModalLoader visible={loading} />
                <div className="mb-10 flex items-center">
                    <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                        <IoArrowBackOutline />
                    </button>
                    <p className="ml-1.5">Back</p>
                </div>
                <div className='w-full flex flex-col justify-center items-center h-[90%]'>
                    <div className='text-[#E6072C] font-bold text-[24px]'>Change Password</div>
                    <div>Fill in your current password</div>
                    <form className='w-full md:w-[60%]' onSubmit={verifyLogin}>
                        <CustomFormControl styles={{ mt: 7 }}>
                            <PasswordField
                                values={password}
                                onChange={(prop) =>
                                    ({ target }) => {
                                        setPassword({...password, password:target.value})
                                    }}
                                onClick={() => {
                                    setPassword({
                                        ...password,
                                        showPassword: !password.showPassword,
                                    });
                                }}
                                onMouseDown={(event) =>  {
                                    event.preventDefault();
                                }}
                                required
                            />
                        </CustomFormControl>
                        <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
                    </form>
                </div>  
            </div>
        }
        {
            step == 2 &&
            <div className='bg-white w-full min-h-[90vh] p-[12px]'>
                <ModalLoader visible={loading} />
                <div className="mb-10 flex items-center">
                    <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                        <IoArrowBackOutline />
                    </button>
                    <p className="ml-1.5">Back</p>
                </div>
                <div className='w-full flex flex-col justify-center items-center h-[90%]'>
                    <div className='text-[#E6072C] font-bold text-[24px]'>Change Password</div>
                    <div>Fill in your new password</div>
                    <form className='w-full md:w-[60%]' onSubmit={handleUpdatePassword}>
                        <CustomFormControl styles={{ mt: 7 ,width:'1'}}>
                        <PasswordField
                            values={newPassword}
                            onChange={(prop) =>
                                ({ target }) => {
                                    setNewPassword({...newPassword, password:target.value})
                                }}
                                onClick={() => {
                                    setNewPassword({
                                        ...newPassword,
                                        showPassword: !newPassword.showPassword,
                                    });
                                }}
                                onMouseDown={(event) =>  {
                                    event.preventDefault();
                                }}
                            required
                            />
                        </CustomFormControl>
                        <CustomFormControl styles={{ mt: 2 }}>
                            <PasswordField
                                values={confirmPassword}
                                onChange={(prop) =>
                                    ({ target }) => {
                                    setConfirmPassword({...confirmPassword, password: target.value})
                                }}
                                onClick={() => {
                                    setConfirmPassword({
                                        ...confirmPassword,
                                        showPassword: !confirmPassword.showPassword,
                                      });
                                }}
                                onMouseDown={(event) =>  {
                                    event.preventDefault();
                                }}
                                required
                            />
                        </CustomFormControl>
                        <AuthButton className="bg-[#EC4D67]">Save Changes</AuthButton>
                    </form>
                </div>  
            </div>
        }
    </>
    
  )
}

export default ChangePassword