import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getInsuranceCalculator } from "../../../../store/insuranceCalculator";
import { addAmount, addField, getUserInfo, setPrice, setPriceStat } from "../../../../store/userData";
import { BUY_PLAN_STEPS_TITLE, BUY_PLAN_URLS, VALID_SERVICES } from "../../../../utils/constants";
import CustomSelect from "../../../common/Select";
import AuthButton from "../../../Containers/AuthButton";
import Form from "../../../Containers/Form";
import PricingCard from "../../../UI/PricingCard";
import _ from "lodash";
import usePaystack from "../../../hooks/usePaystack";
import usePaystackMethods from "../../../hooks/usePaystackMethods";
import { numberWithCommas, resetSteps, setSteps } from "../../../../utils/helper";
import { getPlanSteps } from "../../../../store/steps";
import { toast } from "react-toastify";
import DiscountModal from "../../../common/Modal/discountModal";

const MedicalServiceBenefits = ({ buyOther, setBuyOther, promo, setPromo, firstName, lastName, email, phone, pickedHospital, setPickedHospital }) => {
  const { fields, type, sex } = useSelector(getUserInfo);
  const { values: stepsValues } = useSelector(getPlanSteps);
  const [values, setValues] = useState(fields);
  const [data, setData] = useState([]);
  const { amount_paid,isNormalPrice,normalPrice  } = useSelector(getUserInfo);
  const [openModal, setOpenModal] = useState(false);
  const handlePayment = usePaystack();
  const { onSuccess, onClose } = usePaystackMethods(buyOther, promo, firstName, lastName, phone, email, pickedHospital, toast);
  const { calculatorData } = useSelector(getInsuranceCalculator);
  const dispatch = useDispatch();
  const history = useHistory();
  const [cost, setCost] = useState(amount_paid);
  const [removePromo, setRemovePromo] = useState(false);
  const benefits = calculatorData.benefits;

  useEffect(() => {
    setSteps(stepsValues, BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES_BENEFITS, dispatch);
  }, [dispatch]);


  // useEffect(() => {
  //   let count;
  //   if (promo) {
  //     count = promo / 100 * amount_paid;
  //   } else {
  //     count = 0;
  //   }
  //   let newValue = amount_paid - count;
  //   dispatch(addAmount(newValue));

  // }, [promo]);


  useEffect(() => {
    if (!sex || !type) {
      resetSteps(dispatch);
      return history.push(BUY_PLAN_URLS.CREATE_YOUR_PLAN);
    }
    const newData = benefits.filter((value) => !VALID_SERVICES.includes(value.name.toLowerCase()));
    const initialValues = _.cloneDeep(fields);
    for (const data of newData) {
      initialValues[data.name] = { field: "", value: "", type: "benefits", ans: "" };
    }
    setData(newData);
    setValues(initialValues);
    dispatch(addField(initialValues));
  }, [history, benefits, dispatch]);

  const handleChange = (value, name, fields, points) => {
    const newValues = _.cloneDeep(values);

    newValues[name].field = name;
    newValues[name].value = value;
    newValues[name].ans = fields[points.lastIndexOf(value)];
    setValues(newValues);

    dispatch(addField(newValues));
    if(promo != 0) {
      dispatch(setPriceStat(true));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let count;
    if (promo) {
      dispatch(setPrice(amount_paid));
      dispatch(setPriceStat(true));
      //count = promo / 100 * amount_paid;
    } else {
      count = 0;
    }
    let newValue = amount_paid - count;
    setCost(amount_paid);
    dispatch(addAmount(amount_paid));

    if (promo) {
      setRemovePromo(true);
      setOpenModal(true);
    } else {
      handlePayment(onSuccess, onClose);
      history.push(BUY_PLAN_URLS.MEDICAL_SERVICES_BENEFITS);
    }



  }

  const onExit = () => {
    setOpenModal(false);
  }

  return (
    <>
      {
        (openModal && promo) &&
        <DiscountModal close={onExit} isSuccess={openModal} handlePayment={handlePayment} onSuccess={onSuccess} onClose={onClose} cost={cost} />
      }

      <div className="py-5 w-full">
        <Form onSubmit={handleSubmit}>
          {data.length > 0 ? (
            data.map((benefit, index) => (
              <CustomSelect
                key={benefit.name + index}
                label={benefit.name}
                value={values[benefit.name]?.field}
                onChange={({ target }) =>
                  handleChange(target.value, benefit.name, benefit.value.names, benefit.value.points)
                }
                items={benefit.value.names}
                points={benefit.value.points}
              />
            ))
          ) : (
            <p></p>
          )}
          <AuthButton className="my-5 bg-[#E6072C]">Proceed</AuthButton>
          <PricingCard disabled={false} />
          {
            (promo !=0 && !removePromo) && <div className="font-bold">10% discount available ,kindly proceed to payment</div>
          }
        </Form>
      </div>
    </>
  );
};

export default MedicalServiceBenefits;
