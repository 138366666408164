import React from 'react'
import { useHistory } from 'react-router-dom';
import { sidebarNavBarStyles } from '../../styles/styles';
import ArrowBackIcon from "@mui/icons-material/ArrowRight";
import styles from "./index.module.css";


function Plan({ title, subtitle, image,link,icon }) {
    const classes = sidebarNavBarStyles();
    const history = useHistory();
  return (
    <div
      className={styles["newrightSide"]}
      onClick={() => {
        history.push(link);
      }}
    >
      <div className='mr-[20px]'>
        {icon}
      </div>
      <div className={"flex w-full justify-between"}>
        <div className={styles["image-view"]}>
          <div className={styles["rightSide-header"]}>
            <div className={styles["rightSide-title"]}><div className='font-bold'>{title}</div></div>
            <div className={styles["rightSide-subtitle"]}>{subtitle}</div>
          </div>
        </div>
        <ArrowBackIcon color="disabled" onClick={() => {}} className={classes.closeIcon} />
      </div>
    </div>
  )
}

export default Plan