import React from "react";

const NotFound = () => {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <h1 className="text-xl font-medium">Error 404!</h1>
      <p>The page you are looking for is not here! </p>
    </div>
  );
};

export default NotFound;
