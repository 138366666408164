import React, { useState } from "react";
import { resetPasswordPageStyle } from "./resetStyle";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "../../../store/auth";
import Loader from "../../common/Loader";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import LeftSide from "../Auth/LeftSide";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import styles from './index.module.css';

const NewPassword = () => {
  const classes = resetPasswordPageStyle();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const email = localStorage.getItem('email');
  const history = useHistory();

  const [newPass, setNewPass] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPass, setConfirmPass] = useState({
    password: "",
    showPassword: false,
  });
  const admin = useSelector(getAuth);

  const handleChangePasswordVisibility = () => {
    setNewPass({ ...newPass, showPassword: !newPass.showPassword });
  };

  const handleChangeConPasswordVisibility = () => {
    setConfirmPass({ ...confirmPass, showPassword: !confirmPass.showPassword });
  };

  const handleConPasswordChange = (prop) => (event) => {
    setConfirmPass({ ...confirmPass, [prop]: event.target.value });
  };

  const handlePasswordChange = (prop) => (event) => {
    setNewPass({ ...newPass, [prop]: event.target.value });
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if(newPass.password.length < 8) {
      toast.error('Password should have atleast 8 characters',{
        position:"bottom-right"
      });
    }
    else if(newPass.password !== confirmPass.password) {
      toast.error('Kindly Confirm the right password',{
        position:"bottom-right"
      });
    } else {
      setLoading(true);
      try {
        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/update-pwd`,{
          email,
          password: newPass.password,
         password_confirmation: confirmPass.password
        });
        setLoading(false);
        toast.success(data?.message,{
          position:"bottom-right"
        });
        history.push('/')
      }catch(err) {
        setLoading(false);
        console.log(err);
      }
    }
    
  };

  return (
    <div className="w-[100%] h-full flex justify-center">
      <LeftSide />
      <Loader visible={loading} />
      <div className="w-full ">
        <form className={`${classes.formContainer} ${styles['auth-form']}`} onSubmit={handleUpdatePassword}>
          <p className={classes.resetP}>Reset your password</p>
          <p className={classes.resetText}>Provide your new password</p>
          <div className={classes.inputSection}>
            <label>New Password</label> <br />
            <label className={classes.formLabel}>
              <input
                type={newPass.showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={newPass.password}
                onChange={handlePasswordChange("password")}
                placeholder="***********"
                className={classes.formInput}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              />
              <IconButton onClick={handleChangePasswordVisibility}>
                {newPass.showPassword ? <VisibilityIcon color="disabled" /> : <VisibilityOffIcon color="disabled" />}
              </IconButton>
            </label>
          </div>
          <br />
          <div className={classes.inputSection}>
            <label>Confirm Password</label> <br />
            <label className={classes.formLabel}>
              <input
                type={confirmPass.showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={confirmPass.password}
                onChange={handleConPasswordChange("password")}
                placeholder="***********"
                className={classes.formInput}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
              />
              <IconButton onClick={handleChangeConPasswordVisibility}>
                {confirmPass.showPassword ? <VisibilityIcon color="disabled" /> : <VisibilityOffIcon color="disabled" />}
              </IconButton>
            </label>
          </div>
          <br />
          <button className={classes.submitform}>Save Changes</button>
        </form>
      </div>
      
    </div>
  );
};

export default NewPassword;
