import React, { useEffect, useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { CustomFormControl, ModalLoader } from '../../../common'
import styles from "./index.module.css";
import { useHistory } from 'react-router-dom';
import Form from '../../../Containers/Form';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { AuthButton } from '../../../Containers';



function VerifyDetail() {
    const enrolleeDetail = JSON.parse(localStorage.getItem('enrollee'));
    const [enrolleeId, setEnrolleeId] = useState({ value: enrolleeDetail?.insurance_no, invalid: false });
    const [client, setClient] = useState({ value: enrolleeDetail?.company?.name, invalid: false });
    const [email, setEmail] = useState({ value:enrolleeDetail?.email, invalid: false });
    const [firstName, setFirstName] = useState({ value: enrolleeDetail?.firstname, invalid: false });
    const [lastName, setLastName] = useState({ value: enrolleeDetail?.lastname, invalid: false });
    const [password, setPassword] = useState({ value: "", invalid: false });
    const [phoneNumber, setPhoneNumber] = useState({ value: enrolleeDetail?.phone, invalid: false });
    const [planType, setPlanType] = useState({ value: enrolleeDetail?.plan?.name, invalid: false });
    const [date, setDate] = useState({ value: "", invalid: false });
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    useEffect(() => {
        if(!enrolleeDetail) {
            history.push('/wellnesshmo/verifyId')
        }
    },[]);

  

  const validInput = (value, prevData, setData) => {
    if (!value.trim()) {
      const newData = { ...prevData };
      newData.invalid = true;
      setData(newData);
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (!validInput(firstName.value, firstName, setFirstName)) return;
    if (!validInput(lastName.value, lastName, setLastName)) return;
    if (!validInput(password.value, password, setPassword)) return;
    if (!validInput(phoneNumber.value, phoneNumber, setPhoneNumber)) return;

    const creationData = {
      email: email.value,
      password: password.value,
      first_name: firstName.value,
      last_name: lastName.value,
      phone_number: phoneNumber.value,
    };
     
    
  };


    return (
        <div className={styles["plan-wrapper"]}>
            <div className="mb-10 flex items-center">
              <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                <IoArrowBackOutline />
              </button>
              <p className="ml-1.5">Back</p>
            </div>
            <div className={styles["container"] + 'block lg:flex lg:justify-between'}>
                <ModalLoader visible={loading} />
                <div className={styles["title-container"]}>
                    <p className={styles["title"]}>Verify Enrollee Detail</p>
                    <p className={styles["sub-title"]}>
                        Please would you like to proceed.
                    </p>
                </div>
                <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2'>
                    <Form className={styles["form"]} onSubmit={handleRegister}>
                        <div className={styles["form-control"]}>
                            <CustomFormControl styles={{ mt: 0 }}>
                                <TextField
                                    disabled
                                    id="first-name"
                                    label="Enrollee Id"
                                    variant="outlined"
                                    error={enrolleeId.invalid}
                                    value={enrolleeId.value}   
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="first-name"
                                label="Name"
                                variant="outlined"
                                error={firstName.invalid}
                                value={firstName.value+ ' ' + lastName.value}
                                
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="fit-name"
                                label="Email"
                                variant="outlined"
                                error={email.invalid}
                                value={email.value}
                                
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="client"
                                label="Client"
                                variant="outlined"
                                error={client.invalid}
                                value={client.value}
                                
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="first-name"
                                label="Plan Type"
                                variant="outlined"
                                error={planType.invalid}
                                value={planType.value}
                                />
                            </CustomFormControl>
                        </div>
                        <div className="flex justify-between"></div>
                        <div>
                          <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
                          <div className="flex items-center mt-3 justify-center">
                            <p className="mr-3">Can't remember your Enrollee ID?</p>
                            <Link to="/contact-us" variant="body2">
                              Chat With us
                            </Link>
                          </div>
                        </div>
                    </Form>
                </div>      
            </div>
        </div>
    )
}

export default VerifyDetail