import React,{useEffect,useState} from 'react'
import Card from '../../../common/Card'
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';
import hospitalImage from '../../../../assets/images/hospital-fill.svg';
import barcode from '../../../../assets/images/barcode-box.svg';
import dependents from '../../../../assets/images/dependents.svg';
import video from '../../../../assets/images/video-camera.svg';
import profile from '../../../../assets/images/profile-fill.svg';
import discuss from '../../../../assets/images/discuss-fill.svg';
import CustomSelect from '../../../common/Select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalList, fetchHospitalLocation, getHospitals } from '../../../../store/hospitals';
import medicine from '../../../../assets/images/medicine.svg';
import { useLocation } from 'react-router-dom';


function SeePartner() {
    const baseUrl = "/dashboard";
    const params = useParams();
    const history = useHistory();
    const [hospital, setHospital] = useState("");
    const { list, locations, loading} = useSelector(getHospitals);
    const [hospitalLocation, setHospitalLocation] = useState("");
    let isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
    isEnrollee = isEnrollee?.insurance_no;
    const routePath = useLocation();

    const dispatch = useDispatch();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);
    useEffect(() => {
      dispatch(fetchHospitalLocation())
    },[]);

    const handleHospitalLocationChange = ({ target }) => {
      setHospitalLocation(target.value);
      dispatch(fetchHospitalList(target.value));
    };


    return (
    <div className="px-[36px] bg-[#ffffff]">
      <div className="mb-[10px] lg:mb-10 flex items-center py-[20px]">
        {params.step && (
          <>
            <button className="bg-[#4b3bae] text-white p-[0.2em] rounded-[50%] cursor-pointer" onClick={() => history.goBack()}>
              <IoArrowBackOutline />
            </button>
            <p className="ml-1.5">Back</p>
          </>
        )}
      </div>
      <div className="mb-[30px]">
        <div className="w-[50%] hidden sm:block">
          <div className="text-[#EC4D67] text-[24px] font-bold">Select Hospital Location</div>
          <div className='text-[16px]'>Select a location where you would like to receive your care</div>
        </div>
        <CustomSelect
          label="Hospital Location"
          value={hospitalLocation}
          onChange={handleHospitalLocationChange}
          loading={loading}
          items={locations}
        />
        {/* <CustomSelect
          label="Hospital"
          value={hospital}
          onChange={({ target }) => hanldeSetHospital(target.value)}
          loading={loading}
          items={list}
        /> */}
        {
        list?.length > 0 &&
        <div>
          <div className='font-bold mt-4 mb-2'>All available hospital(s)</div>
          {
            list?.map((d,index) => {
              return (
                <div className='mb-2'>-{' '}{d.name}<br/>{d.address}</div>
              )
            })
          }
        </div>}
      </div>  
      <div>
        <div className="text-[#0F0B26] font-bold">Our Offers</div>
        <div className="text-[#918F9F]">Get an overview of what you can do with Wellness</div>
        <div className="grid gap-2 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3">
          
        {
            isEnrollee ? 
            <Card
              title={"Telemedicine"}
              subtitle={"speak to a doctor from the comfort of your home"}
              image={video}
              link={`${baseUrl}/telemedicine`}
            />
            : 
            <Card
              title={"Let's talk"}
              subtitle={"We are here should you need help"}
              image={video}
              link={`${baseUrl}/contact`}
            />
          }
          
          {
            isEnrollee && 
            <Card
              title={"Request for monthly drug refill"}
              subtitle={"Out of drugs? You can easily request for a refill here"}
              image={medicine}
              link={`${baseUrl}/requestrefill`}
            />
          }
          {
            isEnrollee &&
            <Card
              title={"Request for Secondary Care"}
              subtitle={"Want a benefit that you aren't covered for? Don't worry we got you"}
              image={barcode}
              link={`${baseUrl}/medical-checkup`}
            />
          }
          {
            isEnrollee &&
            <Card
              title={"Dependents"}
              subtitle={"View a list of all your dependents"}
              image={dependents}
              link={`${baseUrl}/dependents`}
            />
          }
          
        </div>
        
      </div>
      <div>
        <div className="text-[#0F0B26] font-bold">Self-service</div>
        <div className="text-[#918F9F]">Make your request, we are here to grant them</div>
        <div className="grid gap-2 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3">
            <Card
              title={"Book Hospital Visit"}
              subtitle={"Access our health coverage at a  hospital near to you."}
              image={hospitalImage }
              link={isEnrollee ? `${baseUrl}/hospitalvisit`: `${baseUrl}/wellnesshmo/buy-your-plan`}
            />
          <Card
            title={"Request Authorization code"}
            subtitle={"Access our health coverage at a  hospital near to you."}
            image={barcode}
            link={isEnrollee ? `${baseUrl}/authcode`: `${baseUrl}/wellnesshmo/buy-your-plan`}
          />
          <Card
            title={"Request HMO Card"}
            subtitle={"Access our health coverage at a  hospital near to you."}
            image={profile}
            link={isEnrollee ? `${baseUrl}/hmocard`: `${baseUrl}/wellnesshmo/buy-your-plan`}
          />
          <Card
            title={"Contact Us"}
            subtitle={"Access custom health plans we have available"}
            image={discuss}
            link={`${baseUrl}/contact`}
          />
          <Card
            title={"Add Dependents"}
            subtitle={"add a dependant"}
            image={dependents}
            link={`${baseUrl}/adddependant`}
          />
        </div>
      </div>
    </div>
  )
}

export default SeePartner