import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

// function getTotalAmount(objs) {
//   const array = Object.keys(objs);
//   console.log(objs, "objs");
//   return array.reduce((acc, key) => {
//     return (acc += objs[key].value > 0 ? objs[key].value * 1000 : 0);
//   }, 0);
// }

let slice = createSlice({
  name: "dentalCare",
  initialState: {
    dentalCareValue: [],
    loading: false,
  },
  reducers: {
    fetchedDentalCare: (data, { payload }) => {
      data.loading = false;
      data.dentalCareValue = payload.data.dental_optical_primary_care;
    },
    fetchedDataStarted: (data) => {
      data.loading = true;
    },
    fetchedFailed: (data) => {
      data.loading = false;
    },
    resetDental: (data) => {
      data = {
        dentalCareValue:[],
        loading:false
      }
    }
  },
});

const { fetchedDataStarted, fetchedFailed, fetchedDentalCare,resetDental } = slice.actions;

export default slice.reducer;

const dentalUrl = "dental-optical/care/dental";

export const fetchDentalCare = () =>
  apiCallBegan({
    url: `${dentalUrl}`,
    method: "GET",
    baseUrl: `${process.env.REACT_APP_API_URL}/v2/`,
    onSuccess: fetchedDentalCare.type,
    onStart: fetchedDataStarted.type,
    onError: fetchedFailed.type,
  });

export const getDentCare = (state) => state.entities.dentalCare;

export const resetDentalCare = (state) => (dispatch) =>
dispatch({type: resetDental.type })