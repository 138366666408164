import React from 'react';
import Doctor from '../../assets/images/doc.jpg';
import { FaUserGraduate, FaMapMarkerAlt,FaYoutube, FaLinkedin, FaTwitter, FaFacebook  } from "react-icons/fa";


function DocDetail() {
    const socialMediaList=[
        {
            id:1,
            icon:<FaYoutube />,
            url:''
        },
        {
            id:2,
            icon:<FaLinkedin />,
            url:''
        },
        {
            id:3,
            icon:<FaTwitter />,
            url:''
        },
        {
            id:4,
            icon:<FaFacebook />,
            url:''
        }
    ]
  return (
    <>
        <div className='grid grid-cols-1 md:grid-cols-3 border-[1px] p-5 mt-5 rounded-lg'>
            <div>
                <img src={Doctor}
                    width={200}
                    height={200}
                    alt='doctor-image'
                    className='rounded-lg w-full h-[280px] object-cover'
                />
            </div>
            {/* Doctor Info  */}
            <div className='col-span-2 mt-5 flex md:px-10 flex-col gap-3 items-baseline'>
                <h2 className='font-bold text-2xl'>Daniel william</h2>
                <h2 className='flex gap-2 text-gray-500 text-md'>
                    <FaUserGraduate />
                    <span>12 of Experince</span>
                </h2>
                <h2 className='text-md flex gap-2 text-gray-500'>
                    <FaMapMarkerAlt />
                    <span>kofo street</span>
                </h2>
                <h2 className='text-[10px] bg-blue-100 p-1 rounded-full px-2 text-primary'>Dentist</h2>

                <div className='flex gap-3'>
                    {socialMediaList.map((item,index)=>
                        {
                            return item?.icon
                        }
                    )}
                </div>
                    {/* <BookAppointment doctor={doctor} /> */}
            </div>
            {/* About Doctor  */}  
        </div>
        <div className='p-3 border-[1px] rounded-lg mt-5'>
            <h2 className='font-bold text-[20px]'>About Me</h2>
            <p className='text-gray-500 tracking-wide mt-2'>
                Lorem ipsum dolor sit amet consectetur adipisicing
                elit. Unde alias iusto quisquam, temporibus sequi 
                aperiam dicta, iure doloremque natus voluptas 
                aliquam ratione obcaecati, neque architecto quas
                illo dolore! Accusamus, a!
            </p>
        </div>
    </>
  )
}

export default DocDetail