import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from "../../../../../../common/Alert";
import { fetchHospitalList, fetchHospitalLocation, getHospitals } from "../../../../../../../store/hospitals";
import { getPlanSteps } from "../../../../../../../store/steps";
import CustomSelect from "../../../../../../common/Select";
import AuthButton from "../../../../../../Containers/AuthButton";
import PricingCard from "../../../../../../UI/PricingCard";
import Form from "../../../../../../Containers/Form";
import styles from "./index.module.css";
import usePaystack from "../../../../../../hooks/usePaystack";
import axios from "axios";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import { CombineDateAndTime, postBuyOther } from "../../../../../../../utils/helper";
import { addAmount, setPriceStat } from "../../../../../../../store/userData";
import { CustomFormControl } from "../../../../../../common";
import { TextField } from "@mui/material";

const BasicHealthCheckup = ({ firstName, setFirstName, lastName, setLastName, phone, setPhone, email, setEmail, promo, setPromo, buyOther, setBuyOther, healthData, cost }) => {
  const { values: stepsValues } = useSelector(getPlanSteps);
  const [sex, setSex] = useState("");
  const [hospitalLocation, setHospitalLocation] = useState("");
  const [hospital, setHospital] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [pickedHospital, setPickedHospital] = useState({});
  const [appointmentDate, setAppointmentDate] = useState('');
  const history = useHistory();
  const [time, setTime] = useState('');
  const [location, setLocation] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchHospitalLists = async (item) => {
    setLoading(true);
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive/${item}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setList(data.data.health_centres);
      console.log(data);
      setLoading(false);


    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-right"
      });
    }
  }
  const fetchHospitalLocation = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setLocation(data?.data?.locations);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchHospitalLocation();

  }, []);

  useEffect(() => {
    fetchHospitalLists(hospitalLocation);

  }, [hospitalLocation]);


  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const hanldeSetHospital = (value) => {
    console.log(value);
    const item = list.find((item) => item.name === value);
    const values = {};
    setPickedHospital(item);
    console.log(item);
  };

  const handlePayment = usePaystack();
  // const { onSuccess, onClose } = usePaystackMethods();

  const postEmploy = async (newData) => {
    try {
      const resp = axios.post(`${process.env.REACT_APP_API_URL}/v2/comprehensive/care/create`,
        newData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        }
      );
      console.log(resp.status);
      toast.success('Appointment created successfully', {
        position: "bottom-right"
      });
      dispatch(addAmount(0));
      history.push('/dashboard/medical-checkup');
    } catch (err) {
      console.log(err);
    }

  }

  const onSuccess = (reference) => {
    let newdate = CombineDateAndTime(appointmentDate, time);
    
    if(buyOther == 'myself') {
      const allData = {
        services: "bc",
        service_name: "bc",
        transaction_id: reference.trxref,
        amount_paid: cost,
        hospital_name: pickedHospital.name,
        hospital_location: pickedHospital.location,
        hospital_address: pickedHospital.address,
        appointment_date: newdate,
        doctor_name: "",
        comment: "",
        has_promo: false
      }
  
      postEmploy(allData);
    }else if (buyOther == 'other') {
      let obj = {
        request_type: "bc",
        data : {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: email,
        request_type: "bc",
        service_name: "bc",
        services: "bc",
        amount_paid: cost,
        hospital_name: pickedHospital?.name,
        hospital_location: pickedHospital?.location,
        hospital_address: pickedHospital?.address,
        doctor_name: "",
        comment: "",
        appointment_date: newdate,
        has_promo: promo ? true : false,
        transaction_id: reference.trxref
        }
    }
    postBuyOther(obj,toast,history);
    }

    

    window.location.href('/dashboard');
    Alert.notication("success", "Subscription", "subscription successful");
    // Implementation for whatever you want to do with reference and after success call.
    // dispatch(logout());
    window.location.href('/dashboard')
    // setTimeout(() => {
    //      window.location.href('/dashboard');
    // }, 2000);
    console.log(reference);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (time == '') {
      toast.error('Select time of appointment', {
        position: 'bottom-right'
      })
    } else {
      dispatch(setPriceStat(false));
      handlePayment(onSuccess, onClose);
    }
  };
  const handleHospitalLocationChange = ({ target }) => {
    setHospitalLocation(target.value);
    fetchHospitalLists(target.value);
  };


  const onChange = (time, timeString) => {
    setTime(timeString);
  };


  return (
    <Form onSubmit={handleSubmit} className="py-5 px-0">
      <div className="row">
        <p className={styles["header-top"]} htmlFor="text">
          These are the services included under the Basic Health checkup
        </p>
      </div>
      <div>
        {
          healthData.length > 0 && healthData.map((data, index) => {
            return (
              <>
                <p className="mb-2 mt-4  font-bold">{data?.name}</p>
                {data?.value?.map((d, i) => {
                  return (
                    <div className={styles["disabled-field"]}>{d}</div>
                  )
                })}

              </>
            )
          })
        }
      </div>
      <CustomSelect
        label={'Buy For'}
        value={buyOther}
        onChange={({ target }) => setBuyOther(target.value)}
        loading={false}
        required
        items={[
          { value: "myself", label: "Myself" },
          { value: "other", label: "Others" },
        ]}
      />
      {
        buyOther == 'other' &&
        <>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'FirstName'}
              label={'First Name'}
              variant="outlined"
              value={firstName}
              required
              onChange={({ target }) => setFirstName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'LastName'}
              label={'Last Name'}
              variant="outlined"
              value={lastName}
              required
              onChange={({ target }) => setLastName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'Phone'}
              label={'Phone Number'}
              variant="outlined"
              value={phone}
              required
              onChange={({ target }) => setPhone(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'email'}
              label={'Email Address'}
              variant="outlined"
              value={email}
              required
              onChange={({ target }) => setEmail(target.value)}
            />
          </CustomFormControl>
        </>
      }
      <div>
        <CustomSelect
          label="Hospital Location"
          value={hospitalLocation}
          onChange={handleHospitalLocationChange}
          loading={loading}
          items={location}
        />
        <CustomSelect
          label="Hospital"
          value={hospital}
          onChange={({ target }) => hanldeSetHospital(target.value)}
          loading={loading}
          items={list}
        />
      </div>
      <div>
        <div className="my-[10px]">Select Appointment Date</div>
        <input required type="date" onChange={(e) => setAppointmentDate(e.target.value)} placeholder="Select Appointment Date" className="border w-full h-[50px] p-[20px] rounded-[8px]" />
      </div>
      <div>
        <div className="my-[10px]">Select Appointment Time</div>
        <TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
      </div>
      <div className="pb-10">
        <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
      </div>
      <PricingCard disabled={true} />
      {promo &&
        <div className='flex'>
          <div>{promo}% discount</div>
          <div className='ml-[10px] line-through font-bold'>N{cost}</div>
        </div>}
    </Form>
  );
};

export default BasicHealthCheckup;
