
import { Link } from '@mui/material'
import React from 'react'
import Doctor from '../../../../assets/images/doc.jpg'


function DoctorList({doctorList,heading='Popular Doctors'}) {
  return (
    <div className='border-[1px] rounded-lg p-3 cursor-pointer hover:border-[#1B1266] hover:shadow-sm transition-all ease-in-out' key={1}>
        <img src={Doctor}
        alt='doctor'
        width={500}
        height={200}
        className='h-[200px] w-full object-cover rounded-lg'
        />
        <div className='mt-3 items-baseline flex flex-col gap-1'>
            <h2 className='text-[10px] bg-blue-100 p-1 rounded-full px-2 text-primary'>Dental</h2>
            <h2 className='font-bold'>Dr william</h2>
            <h2 className='text-primary text-sm'>12</h2>
            <h2 className='text-gray-500 text-sm'>14,folarin close </h2>
            <Link href={'/details/1'} className='w-full no-underline'>
                <h2 className='p-2 px-3  border-[1px] border-[#1B1266] text-primary rounded-[5px] w-[100px] text-center text-[11px] mt-2 cursor-pointer hover:bg-[#1B1266] hover:text-white'>
                    Book Now
                </h2>
            </Link>
        </div>
    </div>
           
        
  )
}
{/* // Skelton Effect 
        [1,2,3,4,5,6].map((item,index)=>(
            <div className='h-[220px] bg-slate-200 
            w-full rounded-lg animate-pulse'>

            </div>
        )) */}

export default DoctorList