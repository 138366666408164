import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowRight";
import styles from "./index.module.css";

const RightSideUI = ({ title, subtitle, url }) => {
  const history = useHistory();

  return (
    <div
      className={styles["center"]}
      onClick={() => {
        history.push(url);
      }}
    >
      <div className={styles["center-header"]}>
        <div className={styles["center-title"]}>{title}</div>
        <ArrowBackIcon color="disabled" onClick={() => {}} />
      </div>
      <div className={styles["center-subtitle"]}>{subtitle}</div>
    </div>
  );
};

export default RightSideUI;
