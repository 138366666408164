import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Loader from "../../common/Loader";
import styles from "./index.module.css";
import wellnessLogo from "../../../assets/images/wellness-logo.png";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import CustomFormControl from "../../common/FormControl";
import LeftSide from "../Auth/LeftSide";
import FormContainer from "../../Containers/FormContainer";
import AuthButton from "../../Containers/AuthButton";
import Form from "../../Containers/Form";
import { MdArrowBackIos } from "react-icons/md";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

function Enrollee() {
  const [enrolleeId, setEnrolleeId] = useState({ value: "", invalid: false });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history]);

  const handleRegister = async(e) => {
    e.preventDefault();
    setLoading(true);
    if(enrolleeId.value == "") {
      toast.error('Please fill the form')
    } else {
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v1/get_enrollee_details`,
        {
          params: {
            insurance_no: enrolleeId.value
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        },
        )
        if(data.status == 'success') {
          setLoading(false);
          localStorage.setItem('enrollee', JSON.stringify(data?.data?.enrollee))
          history.push('/register/enrollee-detail');
        }
      }catch(err) {
        console.log(err);
      }
    }
    
  };

  const handleSetValues = (prevValues, newValue, setFn) => {
    const newData = { ...prevValues };
    newData.value = newValue;
    newData.invalid = false;
    setFn(newData);
  };

  return (
    <div className={styles["login-screen"]}>
      <Loader visible={loading} />
      {/* left */}
      <LeftSide />
      {/* right */}
      <div className={styles["right-container"]}>
        <FormContainer className={styles["form-container"]}>
          <div className='w-full flex items-center relative'>
            <MdArrowBackIos className="cursor-pointer absolute top-4 left-4 text-[20px] font-bold" onClick={() => history.goBack() } />
          </div>
          <img className={styles["form-logo"]} src={wellnessLogo} alt="wellness" />
          <h1 className={styles["form-title"]}>
            Input your enrollee ID to continue
          </h1>
          <Form className={styles["form"]} onSubmit={handleRegister}>
            <div className={styles["form-control"]}>
              <CustomFormControl styles={{ mt: 0 }}>
                <TextField
                  id="first-name"
                  label="Enrollee Id"
                  variant="outlined"
                  error={enrolleeId.invalid}
                  value={enrolleeId.value}
                  onChange={({ target }) => handleSetValues(enrolleeId, target.value, setEnrolleeId)}
                />
              </CustomFormControl>
            </div>
            <div className="flex justify-between"></div>
            <div>
              <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
              <div className="flex items-center mt-3 justify-center">
                <p className="mr-3">Can't remember your Enrollee ID?</p>
                <Link to="/contact-us" variant="body2">
                  Chat With us
                </Link>
              </div>
            </div>
          </Form>
        </FormContainer>
      </div>
    </div>
  )
}

export default Enrollee