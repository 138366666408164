import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { fetchOpticalCare, getOpticalCare } from "../../../../../../store/opticalCare";
import { addField, setResetInfo } from "../../../../../../store/userData";
import CustomSelect from "../../../../../common/Select";
import AuthButton from "../../../../../Containers/AuthButton";
import PricingCard from "../../../../../UI/PricingCard";
import Form from "../../../../../Containers/Form";
import { BUY_PLAN_STEPS_TITLE, OPTICAL_CARE_URLS, STEPS } from "../../../../../../utils/constants";
import { setSteps } from "../../../../../../utils/helper";
import styles from "../index.module.css";

const CreatePlan = ({setBuyOther,buyOther}) => {
  const [values, setValues] = useState({});
  const [option, setOption] = useState("");
  const { opticalValue, loading } = useSelector(getOpticalCare);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOpticalCare());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setResetInfo());
  },[]);

  useEffect(() => {
    const initialValues = {};
    for (const opticalCareOption of opticalValue) {
      initialValues[opticalCareOption.name] = { field: "", value: "", type: "optical", ans: "" };
    }
    setValues(initialValues);
    dispatch(addField(initialValues));
  }, [opticalValue, dispatch]);

  const handleOptionChange = (value, name, price) => {
    const newValues = _.cloneDeep(values);
    setOption(value);
    newValues[name].field = name;
    newValues[name].value = value === "Yes" ? price[1] : price[0];
    newValues[name].ans = value;
    dispatch(addField(newValues));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      history.push(OPTICAL_CARE_URLS.OTHER_SERVICES_ONE);
    
  };

  return (
    <div className="py-5 w-full">
      <Form onSubmit={handleSubmit} className="py-5">
        {opticalValue.length > 0 && (
          <>
            {opticalValue.map((opticalCareOption, index) => (
              <div className="w-full" key={index}>
                {opticalCareOption.name === "Eye Check" ? (
                  <CustomSelect
                    key={opticalCareOption.name + index}
                    label={opticalCareOption.name}
                    items={opticalCareOption.value?.names}
                    value={option}
                    onChange={({ target }) =>
                      handleOptionChange(target.value, opticalCareOption.name, opticalCareOption.value?.prices)
                    }
                  />
                ) : null}
              </div>
            ))}
          </>
        ) }
        <CustomSelect
          label={'Buy For'}
          value={buyOther}
          required
          onChange={({ target }) => {
            setBuyOther(target.value);
          }}
          loading={false}
          items={[
            { value: "myself", label: "Myself" },
            { value: "other", label: "Others" },
          ]}
        />
        {option === "Yes" && (
          <>
            <p className="row mt-3">These are the services included under the eye checkup</p>
            <div className={styles["disabled-field"]}>Consultation & Examination</div>
            <div className={styles["disabled-field"]}>Visual Acuity</div>
            <div className={styles["disabled-field"]}>Opthalmoscopy</div>
            <div className={styles["disabled-field"]}>Refraction</div>
            <div className={styles["disabled-field"]}>Tonometry</div>
            <div className={styles["disabled-field"]}>Central Visual Field</div>
            <div className={styles["disabled-field"]}>Sit Lamp Examination</div>
            <div className={styles["disabled-field"]}>Pachymetry</div>
            <div className={styles["disabled-field"]}>Intraocular Pressure</div>
          </>
        )}
        <div className="pb-10">
          <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
        </div>
        <PricingCard disabled={true} />
      </Form>
    </div>
  );
};

export default CreatePlan;
