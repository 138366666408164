import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { fetchDentalCare, getDentCare } from "../../../../../../store/dentalCare";
import { addField } from "../../../../../../store/userData";
import CustomSelect from "../../../../../common/Select";
import AuthButton from "../../../../../Containers/AuthButton";
import PricingCard from "../../../../../UI/PricingCard";
import Form from "../../../../../Containers/Form";
import styles from "../index.module.css";

const CreatePlan = ({ setStep, firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone, buyOther, setBuyOther }) => {
  const [values, setValues] = useState({});
  const [option, setOption] = useState("");
  const { dentalCareValue, loading } = useSelector(getDentCare);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDentalCare());
  }, [dispatch]);

  useEffect(() => {
    const initialValues = {};
    for (const dentalCareOption of dentalCareValue) {
      initialValues[dentalCareOption.name] = { field: "", value: "", type: "dental", ans: "" };
    }
    setValues(initialValues);
    dispatch(addField(initialValues));
  }, [dentalCareValue, dispatch]);

  const handleOptionChange = (value, name, price) => {
    const newValues = _.cloneDeep(values);
    setOption(value);
    newValues[name].field = name;
    newValues[name].value = value === "Yes" ? price[1] : price[0];
    newValues[name].ans = value;
    dispatch(addField(newValues));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(2);


    // history.push(DENTAL_CARE_URLS.OTHER_SERVICES_ONE);
  };

  return (
    <div className="py-5 w-full">
      <Form onSubmit={handleSubmit} className="py-5">
        {dentalCareValue.length > 0 ? (
          <>
            {dentalCareValue.map((dentalCareOption, index) => (
              <div className="w-full" key={index}>
                {dentalCareOption.name === "Dental Check Up" ? (
                  <CustomSelect
                    key={dentalCareOption.name + index}
                    label={dentalCareOption.name}
                    items={dentalCareOption.value?.names}
                    value={option}
                    onChange={({ target }) =>
                      handleOptionChange(target.value, dentalCareOption.name, dentalCareOption.value?.prices)
                    }
                  />
                ) : null}
              </div>
            ))}
          </>
        ) : (
          <p></p>
        )}
        <CustomSelect
          label={'Buy For'}
          value={buyOther}
          required
          onChange={({ target }) => {
            setBuyOther(target.value);
          }}
          loading={false}
          items={[
            { value: "myself", label: "Myself" },
            { value: "other", label: "Others" },
          ]}
        />
        {option === "Yes" && (
          <>
            <p className="row mt-3">These are the services included in the dental checkup</p>
            <div className={styles["disabled-field"]}>Consultation & Examination</div>
            <div className={styles["disabled-field"]}>Scaling & Polishing</div>
            <div className={styles["disabled-field"]}>Occlusal Xray x2</div>
          </>
        )}
        <div className="pb-10">
          <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
        </div>
        <PricingCard disabled={true} />
      </Form>
    </div>
  );
};

export default CreatePlan;
