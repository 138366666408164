import React, { useEffect, useState } from "react";

import styles from "./index.module.css";

import { sidebarNavBarStyles } from "../../../../../styles/styles";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowRight";

const HealthCard = ({ title, subtitle, url }) => {
  const params = useParams();
  const history = useHistory();
  const classes = sidebarNavBarStyles();

  return (
    <div
      className={styles["center"]}
      onClick={() => {
        history.push(url);
      }}
    >
      <div className={styles["image-view"]}>
        <div className={styles["center-header"]}>
          <div className={styles["center-title"]}>{title}</div>
          <ArrowBackIcon color="disabled" onClick={() => {}} />
        </div>
        <div className={styles["center-subtitle"]}>{subtitle}</div>
      </div>
    </div>
  );
};

export default HealthCard;

