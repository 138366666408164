import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PreEmployment from "./PreEmployment";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getInsuranceCalculator } from "../../../../../../../store/insuranceCalculator";
import { addAmount, setPrice } from "../../../../../../../store/userData";
import axios from "axios";
import { preEmployAPI } from "../../../../../../../utils/apis";
import { ModalLoader } from "../../../../../../common";
import { FormContainer } from "../../../../../../Containers";
import { toast } from "react-toastify";
import { fetchPromo } from "../../../../../../../utils/helper";


const PreEmploymentCare = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(getInsuranceCalculator);
  const token = localStorage.getItem("token");
  const [employData, setEmployData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [valueData, setValueData] = useState([]);
  const [cost, setCost] = useState('');
  const [load, setLoad] = useState(false);
  const [serviceData, setServiceData] = useState(null);
  const [location, setLocation] = useState([]);
  const [list, setList] = useState([]);
  const [hospitalLocation, setHospitalLocation] = useState("");
  const [hospital, setHospital] = useState("");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [buyOther, setBuyOther] = useState(null);
  const [promo, setPromo] = useState('');

  useEffect(() => {
    fetchPromo(setPromo);
}, [promo]);
  const fetchHospitalLists = async (item) => {
    setLoad(true);
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive/${item}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setList(data.data.health_centres);
      console.log(data);
      setLoad(false);


    } catch (err) {
      setLoad(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-right"
      });
    }
  }
  const fetchHospitalLocation = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setLocation(data?.data?.locations);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchHospitalLocation();

  }, []);

  useEffect(() => {
    fetchHospitalLists(hospitalLocation);

  }, [hospitalLocation]);



  const fetchPreEmployment = async () => {
    setLoad(true);
    try {
      const { data } = await axios.get(preEmployAPI,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        });
      let newData = [];
      data?.data?.screenings?.values?.map((d, index) => {
        newData.push({
          name: data?.data?.screenings?.names[index],
          value: d
        })
      });
      let AllName = data?.data?.screenings?.names?.map((d, index) => {
        return d;

      });
      let newAllName = JSON.stringify(AllName);
      setServiceData(newAllName);
      setLoad(false);
      setEmployData(newData);
      setCost(data.data.cost);
      dispatch(addAmount(data.data.cost - (promo/100 * data.data.cost)));
      dispatch(setPrice(data.data.cost - (promo/100 * data.data.cost)));
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchPreEmployment();
  }, [promo]);

  console.log(employData);
  console.log(valueData);
  console.log(nameData);
  return (
    <div className={styles["plan-wrapper"]}>
      <div className="mb-10 flex items-center">
        <button className={styles["back-btn"]} onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Pre Employment Test</p>
      </div>
      <div className={styles["container"] + 'block lg:flex'}>
        <ModalLoader visible={loading} />
        <div className={styles["title-container"]}>
          <p className={styles["title"]}>Pre Employment Test</p>
          <p className={styles["sub-title"]}>
            These are the services offered for employees who are about to start a new work place.{" "}
          </p>
        </div>
        <FormContainer className={styles["form-container"]}>
          {load ? <div className="pl-[30px]">Loading....</div> : 
          <PreEmployment
          firstName={firstName} 
          setFirstName={setFirstName}
          lastName={lastName} 
          setLastName={setLastName}
          phone={phone} 
          setPhone={setPhone}
          email={email} 
          setEmail={setEmail}
          promo={promo} 
          setPromo={setPromo}  
          buyOther={buyOther}
          setBuyOther={setBuyOther}
          preData={employData} 
          cost={cost} 
          serviceData={serviceData} />}
        </FormContainer>
      </div>
    </div>
  );
};

export default PreEmploymentCare;
