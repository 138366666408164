import { createSlice } from "@reduxjs/toolkit";
import { BUY_PLAN_STEPS_TITLE } from "../../utils/constants";

let slice = createSlice({
    name: "steps",
    initialState: {
        values: [{
                title: BUY_PLAN_STEPS_TITLE.CREATE_YOUR_PLAN,
                description: "We need the details below to create the best plan for you",
                isActive: true,
                activeBorder: "border-inactive",
                show: true,
            },
            {
                title: BUY_PLAN_STEPS_TITLE.BIODATA,
                description: "We need the details to get to know you better",
                isActive: false,
                activeBorder: "border-inactive",
                show: true,
            },
            {
                title: BUY_PLAN_STEPS_TITLE.DEPENDENT,
                description: "We need a full list of your dependents to create a plan for them",
                isActive: false,
                activeBorder: "border-inactive",
                show: false,
            },
            {
                title: BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES,
                description: "Create the desired medical benefits based on your needs",
                isActive: false,
                activeBorder: "border-inactive",
                show: true,
            },
            {
                title: BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES_BENEFITS,
                description: "We need the details to know the type of medical services you would like to create",
                isActive: false,
                activeBorder: "border-inactive",
                show: true,
            },
        ],
    },
    reducers: {
        stepsUpdated: (data, { payload }) => {
            data.values = payload;
        },
        showUpdated: (data, { payload }) => {
            data.values = data.values.map((step) => {
                if (step.title === payload.title) {
                    step.show = payload.show;
                }
                return step;
            });
        },
    },
});

const { stepsUpdated, showUpdated } = slice.actions;

export default slice.reducer;

export const updateSteps = (steps) => (dispatch) => dispatch({ type: stepsUpdated.type, payload: steps });

export const updateShow = (title, show) => (dispatch) => dispatch({ type: showUpdated.type, payload: { title, show } });

export const getPlanSteps = (state) => state.entities.steps;