import React, { useEffect, useState } from "react";

import styles from "./index.module.css";
import HealthCard from "./ui";
import { sidebarNavBarStyles } from "../../../../styles/styles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import ArrowBackIcon from "@mui/icons-material/ArrowRight";
import { useDispatch } from "react-redux";
import { addAmount } from "../../../../../store/userData";

const HealthCheckup = () => {
  const params = useParams();
  const history = useHistory();
  const classes = sidebarNavBarStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addAmount(0));
  });

  return (
    <div className={styles["plan-wrapper"]}>
      <div className={styles["plan-container"]}>
        <div className="mb-10 flex items-center">
          <button className={styles["back-btn"]} onClick={() => history.goBack()}>
            <IoArrowBackOutline />
          </button>
          <p className="ml-1.5">Health Checkup</p>
        </div>
        <div className={styles["health-container"]}>
          <div className={styles["title"]}>What would you like to do ?</div>
          <p className={styles["subtitle"]}>Share your intent with us for a more customized experience</p>
          <HealthCard
            url={"/dashboard/medical-checkup/health-care/create-health-care/basic-health-checkup"}
            title={"Basic Health Checkup"}
            subtitle={<p>You can use our services to check your general body wellness and fitness </p>}
          />
          
          <HealthCard
            url={"/dashboard/medical-checkup/health-care/create-health-care/pre-employment"}
            title={"Pre Employment"}
            subtitle={<p>This is for employees looking to do a medical checkup for their new workplace </p>}
          />
          <HealthCard
            url={"/dashboard/medical-checkup/health-care/create-health-care/comprehensive-check"}
            title={"Comprehensive Check"}
            subtitle={<p>Get access to comprehensive health checkup here </p>}
          />
          <HealthCard
            url={"/dashboard/medical-checkup/health-care/create-health-care/cancer-screening"}
            title={"Cancer Screening"}
            subtitle={<p>Get access to tumor marker tests here </p>}
          />
        </div>
      </div>
    </div>
  );
};

export default HealthCheckup;
