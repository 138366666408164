import React, { useState } from 'react'
import { TiTimes } from 'react-icons/ti';
import { BsCheckCircle } from 'react-icons/bs';
import { AuthButton } from '../../Containers';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/userData';
import { FaMoneyCheck } from 'react-icons/fa';

function DiscountModal({ close, isSuccess, handlePayment,onSuccess,onClose,cost }) {
  const [openSubject, setOpenSubject] = useState(false);
  const [addMore, setAddMore] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [addId, setAddId] = useState(0);
  const { amount_paid,normalPrice,isNormalPrice } = useSelector(getUserInfo);
  // const { user } = useSelector(getAuth);
  const user = JSON.parse(localStorage.getItem("user"));
  let count;
  let newValue;
  count = 10 / 100 * normalPrice;
  newValue = normalPrice - count;
      

  const handleSubmit = (e) => {
    e.preventDefault();
    handlePayment(onSuccess, onClose);
    close();

    //history.push(BUY_PLAN_URLS.MEDICAL_SERVICES_BENEFITS);
  }

  return (
    <>
      {isSuccess ? (
        <>
          {
            (isSuccess) &&
            <div className='z-[999999] fixed top-[50%] right-[50%] translate-x-[50%] translate-y-[-50%]  max-w-[650px] h-[410px] flex flex-col  items-center border py-[32px] border-[#EDF2F7] rounded-[16px] bg-white'>
              <div className='w-full relative flex justify-center'>
                <div className='text-[#1A202C] font-bold text-[20px]'></div>
                <TiTimes className='cursor-pointer absolute top-[10%] right-[5%]' onClick={() => {
                  close();
                  
                }} color={"#17599A"} />
              </div>
              <div className='w-full mt-[48px] px-[32px] overflow-y-scroll'>
                <div className='w-full relative mb-[15px]'>
                  <div className="flex justify-between w-full h-full p-[20px] rounded-[12px] ">
                    <div className='w-full h-full flex flex-col justify-center items-center'>
                      <div>
                        <FaMoneyCheck color="#50C878" size={120} />
                      </div>
                      <div className='w-full text-center mt-[20px] font-semibold text-[18px] md:text-[28px]'>10% discount off <div className='line-through'>{normalPrice}</div> <br/>New price:{' '} {newValue}</div>
                    </div>
                    
                  </div>
                  <AuthButton onClick={handleSubmit} className="my-5 bg-[#E6072C]">Proceed to Payment</AuthButton>
                </div>
              </div>
            </div>
          }
          <div className={`fixed top-0 inset-0 z-[9999] bg-[rgba(26,32,44,0.3)] backdrop-blur-[2px]`}></div>
        </>
      ) : null}
    </>
  )
}

export default DiscountModal