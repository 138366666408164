import React, { useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { CustomFormControl, CustomSelect, ModalLoader } from '../../../../common';
import styles from "./index.module.css";
import { TextField } from '@mui/material';
import { AuthButton, Form } from '../../../../Containers';
import { addAmount } from '../../../../../store/userData';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import PricingCard from '../../../../UI/PricingCard';
import usePaystack from '../../../../hooks/usePaystack';
import DialDocModal from '../../../../common/Modal/DialDocModal';
function InstantCall() {
    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addAmount(3000));
    }, []);

    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log("closed");
    };
    const onSuccess = (reference) => {
        
       setOpenModal(true);
    };

    const handlePayment = usePaystack();
    // const { onSuccess, onClose } = usePaystackMethods();



    const handleSubmit = async (e) => {
        e.preventDefault();

        handlePayment(onSuccess,onClose)
    }
    return (
        <div className={styles["plan-wrapper"]}>
            {
                openModal  && 
                <DialDocModal open={openModal} close={() => setOpenModal(!openModal)} />
            }
            <div className="mb-10 flex items-center">
                <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                    <IoArrowBackOutline />
                </button>
                <p className="ml-1.5">Back</p>
            </div>
            <div className={styles["container"] + 'block lg:flex lg:justify-between'}>
                <ModalLoader visible={loading} />
                <div className={styles["title-container"]}>
                    <p className={styles["title"]}>Start Instant Call</p>
                    <p className={styles["sub-title"]}>
                        Enter your details to start an instant service.
                    </p>
                </div>
                <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2'>
                    <div>
                        <Form onSubmit={handleSubmit} className="py-5">
                            <CustomSelect
                                label="Select Health issue"
                                value={reason}
                                required
                                onChange={({ target }) => setReason(target.value)}
                                items={[
                                    { value: "headache", label: "headache" },
                                    { value: "other", label: "other" }
                                ]}
                            />
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <TextField
                                    id={`id-${userData.phone_number}`}
                                    label={'Phone Number'}
                                    variant="outlined"
                                    value={`${userData.phone_number}`}
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <textarea
                                    required
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    placeholder='state reason'
                                    className='border h-[200px] p-2 rounded border-[#eded] mt-[20px]'
                                />
                            </CustomFormControl>


                            <div className="pb-10">
                                <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
                            </div>
                            <PricingCard />
                        </Form>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstantCall