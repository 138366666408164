import React, { useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import styles from "./index.module.css";
import { useHistory } from 'react-router-dom';
import AuthButton from '../../../Containers/AuthButton';
import Form from '../../../Containers/Form';
import CustomFormControl from '../../../common/FormControl';
import { TextField } from '@mui/material';
import CustomSelect from '../../../common/Select';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ModalLoader } from '../../../common';
import { TiTimes } from 'react-icons/ti';
import avatar_bg from '../../../../assets/images/avatar.svg'
import { useRef } from 'react';



function Refill() {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('user'));
  const [medication, setMedication] = useState('');
  const [reason, setReason] = useState('');
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const uploadfile = useRef(null);
  const handleSubmit = async(e) => {
    setLoading(true);
    e.preventDefault();
    let obj = {
      reason: reason,
      drug_name: medication
    }
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/hmo/drug-refills`,obj,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      });
      toast.success(data?.message,{
        position:"bottom-right"
      });
      setLoading(false);
      history.push('/dashboard/wellnesshmo');

    }catch(err) {
      setLoading(false);
      toast.error(err?.response?.data?.message,{
        position:"bottom-right"
      });
    }
  };

  const handleImageChange = async(event) => {
    const file = event.target.files[0];
    if((file?.type == 'image/jpeg') || (file?.type == 'image/png') || (file?.type == 'image/svg') || (file?.type == 'image/jpg')) {
      const imageFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      // reader.onloadend = () => {
      // setImage(reader.result);
      // };

    }else {
      toast.error('Select an Image')
    }
    
  }

  const removeImage = () => {
    setImage('');
  }


  return (
  <div className={styles["plan-wrapper"]}>
    <div className="mb-10 flex items-center">
      <button className={styles["back-btn"]} onClick={() => history.goBack()}>
        <IoArrowBackOutline />
      </button>
      <p className="ml-1.5">Back</p>
    </div>
    <div className={styles["container"] + 'block lg:flex lg:justify-between'}>
      <ModalLoader visible={loading} />
      <div className={styles["title-container"]}>
        <p className={styles["title"]}>Request Monthy Drug Refil</p>
        <p className={styles["sub-title"]}>
          Please fill the form to proceed.
        </p>
      </div>
      <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2'>
        <div>
          <Form onSubmit={handleSubmit} className="py-5">
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <TextField
                  id={`id-${userData.first_name}`}
                  label={'Name of Enrollee'}
                  variant="outlined"
                  value={`${userData.first_name}${' '} ${userData.last_name}`}
                  
              />
            </CustomFormControl>
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  id={`id-${userData.phone_number}`}
                  label={'Phone Number'}
                  variant="outlined"
                  value={`${userData.phone_number}`}
                />
            </CustomFormControl>
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <TextField
                id={`id-${userData.first_name}`}
                label={'Name of Medication'}
                variant="outlined"
                required
                value={medication}
                onChange={(e) => setMedication(e.target.value)}
              />
            </CustomFormControl>
            <CustomSelect
                label="Reason for drug request"
                value={reason}
                required
                onChange={({ target }) => setReason(target.value)}
                items={[
                { value: "monthly refil", label: "Monthly Refil" },
                {value:"Drug not available in my hospital", label: "Drug not available in my hospital"}
                ]}
            />
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <div className='text-[14px] p-2 mb-2'>Upload Picture</div>
              <div className='relative'>
                {
                  image && 
                  <div className='w-[20px] h-[20px] flex justify-center items-center rounded-full bg-white absolute top-[10%] left-[10%] cursor-pointer'>
                    <TiTimes size={24} className='' color={"#F26363"} onClick={removeImage} /> 
                  </div>
                }
                {
                  image ? <img src={image} alt="uploaded image"  width={60} height={60}/>
                  :  <img  src={avatar_bg} width="60" height={"60"} />
                }
                <div className=''>
                  <label className='cursor-pointer' htmlFor='uploadFile'>
                      <input id="uploadFile" hidden type="file" ref={uploadfile} onChange={handleImageChange} />
                      <p className='flex justify-end relative '>
                        <div className='absolute bottom-5 right-[20%] border p-2 rounded text-[#534DE0]'>upload image</div>
                      </p>
                  </label>
                </div>
              </div>
            </CustomFormControl>
            <div className="pb-10">
              <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>

  )
}

export default Refill