import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
    name: "hospitals",
    initialState: {
        list: [],
        locations: [],
        loading: false,
    },
    reducers: {
        fetchedHospitalList: (data, { payload }) => {
            data.loading = false;
            data.list = payload.data.locations;
        },
        fetchedHospitalLocation: (data, { payload }) => {
            data.loading = false;
            data.locations = payload.data.locations;
        },
        fetchedDataStarted: (data) => {
            data.loading = true;
        },
        fetchedFailed: (data) => {
            data.loading = false;
        },
    },
});

const { fetchedDataStarted, fetchedFailed, fetchedHospitalLocation, fetchedHospitalList } = slice.actions;

export default slice.reducer;

const hospitalUrl = "/hospital/locations";

export const fetchHospitalLocation = () =>
    apiCallBegan({
        url: `${hospitalUrl}`,
        method: "GET",
        onSuccess: fetchedHospitalLocation.type,
        onStart: fetchedDataStarted.type,
        onError: fetchedFailed.type,
    });

export const fetchHospitalList = (location) =>
    apiCallBegan({
        url: `${hospitalUrl}/${location}`,
        method: "GET",
        onSuccess: fetchedHospitalList.type,
        onStart: fetchedDataStarted.type,
        onError: fetchedFailed.type,
    });

export const getHospitals = (state) => state.entities.hospitals;