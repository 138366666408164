import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getAuth, login } from "../../../store/auth";
import Loader from "../../common/Loader";
import styles from "./index.module.css";
import wellnessLogo from "../../../assets/images/wellness-logo.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { commonStyles } from "../../styles/global";
import PasswordField from "../../common/PasswordField";
import CustomFormControl from "../../common/FormControl";
import LeftSide from "../Auth/LeftSide";
import {AuthButton,FormContainer} from "../../Containers";
import Form from "../../Containers/Form";
import { validateEmail } from "../../../utils/helper";
import axios from 'axios';
import { toast } from 'react-toastify';

const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); 
  //const { loading } = useSelector(getAuth);
  const history = useHistory();
  const classes = commonStyles();
  const [password, setPassword] = useState({ password: "", showPassword: false, invalid: false });
  const [email, setEmail] = useState({ value: "", invalid: false });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/dashboard/wellnesshmo");
    }
  }, [history]);

  const doSubmit = async (values) => {
    // dispatch(login(values));
    // setTimeout(() => {
    //  window.location.reload();
    // }, 2000);
    setLoading(true);
    try {
      const resp = await axios.request({
          baseURL: `${process.env.REACT_APP_API_URL}/v1`,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers" : "Content-Type",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
          },
          url:'/login',
          method:'POST',
          data: values
        })
        if(resp?.data) {
          localStorage.setItem("enrollee",JSON.stringify({
            insurance_no: resp?.data?.enrollee_id
        }));
        localStorage.setItem("is_enrollee",JSON.stringify(resp?.data?.is_enrollee));
        localStorage.setItem("token", resp?.data.token);
        localStorage.setItem("email", resp?.data?.payload?.email);
        localStorage.setItem("user", JSON.stringify(resp?.data?.payload));
          history.push("/dashboard/wellnesshmo");
        }
          toast.success(resp?.data?.message,{
            position: "bottom-right"
          });
          setLoading(false);
      }catch(err) {
          setLoading(false);
          toast.error(err?.response?.data?.message,{
            position:"bottom-right"
          });
          console.log(err);
      }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!validateEmail(email.value)) {
      const newData = { ...email };
      newData.invalid = true;
      setEmail(newData);
      return;
    }

    if (!password.password.trim()) {
      const newData = { ...password };
      newData.invalid = true;
      setPassword(newData);
      return;
    }
    doSubmit({ email: email.value, password: password.password });
  };

  const handleChange =
    (prop) =>
    ({ target }) => {
      setPassword({ ...password, [prop]: target.value });
    };

  const handleSetEmail = ({ target }) => {
    const newEmail = { ...email };
    newEmail.value = target.value;
    newEmail.invalid = false;
    setEmail(newEmail);
  };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={styles["login-screen"]}>
      <Loader visible={loading} />
      {/* left */}
      <LeftSide />
      {/* right */}
      <div className={styles["right-container"]}>
        <FormContainer className={styles["form-container"]}>
          <img className={styles["form-logo"]} src={wellnessLogo} alt="wellness" />
          <h1 className={styles["form-title"]}>Welcome back, Log in to continue</h1>
          <Form className={styles["form"]} onSubmit={handleLogin}>
            <div className={styles["form-control"]}>
              <CustomFormControl styles={{ mt: 0 }}>
                <TextField
                  error={email.invalid}
                  id="email"
                  label="Email address"
                  variant="outlined"
                  value={email.value}
                  onChange={handleSetEmail}
                />
              </CustomFormControl>
              <CustomFormControl>
                <PasswordField
                  values={password}
                  onChange={handleChange}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                />
              </CustomFormControl>
            </div>
            <Grid container alignItems="center">
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox defaultChecked sx={{ borderRadius: "10px", "&.Mui-checked": { color: "#12D973" } }} />
                  }
                  label="Remember me"
                  classes={{ label: classes.label }}
                />
              </Grid>
              <Grid item>
                <Link to="/forget-password" variant="body2">
                  Forget password?
                </Link>
              </Grid>
            </Grid>
            <div className="flex justify-between"></div>
            <div>
              <AuthButton className="bg-[#EC4D67]">Login</AuthButton>
              <div className="flex items-center mt-3 justify-center">
                <p className="mr-3">Don't have an account?</p>
                <Link to="/sign-up" variant="body2">
                  Sign Up
                </Link>
              </div>
            </div>
          </Form>
        </FormContainer>
      </div>
    </div>
  );
};

export default Login;
