import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getAuth, register } from "../../../store/auth";
import Loader from "../../common/Loader";
import styles from "./index.module.css";

import wellnessLogo from "../../../assets/images/wellness-logo.png";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";
import CustomFormControl from "../../common/FormControl";
import LeftSide from "../Auth/LeftSide";
import FormContainer from "../../Containers/FormContainer";
import AuthButton from "../../Containers/AuthButton";
import Form from "../../Containers/Form";
import { MdOutlineCancel } from "react-icons/md";
import { validateEmail } from "../../../utils/helper";
import { useParams } from "react-router-dom";
import Registerenrollee from "./Enroll";
import Enrollee from "./Enrollee";

const Register = () => {
  const [email, setEmail] = useState({ value: "", invalid: false });
  const [firstName, setFirstName] = useState({ value: "", invalid: false });
  const [lastName, setLastName] = useState({ value: "", invalid: false });
  const [password, setPassword] = useState({ value: "", invalid: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", invalid: false });
  const [step, setStep] = useState(0);
  const params = useParams();

  const dispatch = useDispatch();
  const { loading } = useSelector(getAuth);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history]);

  const doSubmit = async (values) => {
    await dispatch(register(values));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const validInput = (value, prevData, setData) => {
    if (!value.trim()) {
      const newData = { ...prevData };
      newData.invalid = true;
      setData(newData);
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    e.preventDefault();

    // proper error handling here
    if (!validateEmail(email.value)) {
      const newData = { ...email };
      newData.invalid = true;
      setEmail(newData);
      return;
    }
    if (!validInput(firstName.value, firstName, setFirstName)) return;
    if (!validInput(lastName.value, lastName, setLastName)) return;
    if (!validInput(password.value, password, setPassword)) return;
    if (!validInput(phoneNumber.value, phoneNumber, setPhoneNumber)) return;

    const creationData = {
      email: email.value,
      password: password.value,
      first_name: firstName.value,
      last_name: lastName.value,
      phone_number: phoneNumber.value,
    };

    doSubmit(creationData);
  };

  const handleSetValues = (prevValues, newValue, setFn) => {
    const newData = { ...prevValues };
    newData.value = newValue;
    newData.invalid = false;
    setFn(newData);
  };

  const handleNextStep = () => {
    if(step == 1) {
      history.push('/register/enrollee');
    }
    if(step == 2) {
      history.push('/register/nonenrollee')
    }
  }

  return (
    <>
    {(step == 1 && params.step == 'enrollee') &&
      <Enrollee />
    }
    {(step == 2 && params.step == 'nonenrollee') &&
      <Registerenrollee />
    }
    {
      (!params.step)  && 
      <>
      <div className="bg-[#BC1A33] relative  w-full h-[100vh] flex flex-col justify-center items-center">
        <MdOutlineCancel onClick={() => history.push('/login')} color="#ffffff" size={36} className="cursor-pointer absolute top-5 left-3" />
        <div onClick={() => {setStep(1);}} className="bg-white rounded-[8px] w-[90%] md:w-[50%] h-[100px] flex  items-center px-[25px]">
          <label onClick={() => {
          setStep(1);
          }} className="mr-[20px]">
            <input type='radio' className="cursor-pointer" name="favourite_food" value="Pasta"></input>
          </label>
          <div>
            <div className="font-bold">Wellness Enrollee</div>
            <div className="text-[14px] text-[#918f9f]">
              I am a Wellness Enrollee and I have an existing account with Wellness HMO
            </div>
          </div>
        </div>
        <div onClick={() => {
          setStep(2);
          }} className="bg-white rounded-[8px] mt-[20px] w-[90%] md:w-[50%] h-[100px] flex  items-center px-[25px]">
          <label onClick={() => {
            setStep(2);
          }} className="mr-[20px]">
            <input type='radio' className="cursor-pointer" name="favourite_food" value="Pasta"></input>
          </label>
          <div>
            <div className="font-bold">Non Enrollee</div>
            <div className="text-[14px] text-[#918f9f]">
              This is my first time and I have no active plan with Wellness HMO
            </div>
          </div>
        </div>
        <div onClick={() => handleNextStep()} className="cursor-pointer mt-[20px] flex justify-center items-center font-[600] rounded-[5px] bg-white  w-[90%] md:w-[50%] h-[50px]">
          PROCEED
        </div>
      </div>
      </>
    }
    
    </>
    
  );
};

export default Register;
