import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getInsuranceCalculator } from "../../../../store/insuranceCalculator";
import { addField, getUserInfo } from "../../../../store/userData";
import CustomSelect from "../../../common/Select";
import AuthButton from "../../../Containers/AuthButton";
import Form from "../../../Containers/Form";
import _ from "lodash";
import PricingCard from "../../../UI/PricingCard";
import { BUY_PLAN_STEPS_TITLE, BUY_PLAN_URLS, VALID_SERVICES } from "../../../../utils/constants";
import { resetSteps, setSteps } from "../../../../utils/helper";
import { getPlanSteps } from "../../../../store/steps";

const MedicalService = () => {
  const { values: stepsValues } = useSelector(getPlanSteps);

  const { fields, sex, type } = useSelector(getUserInfo);
  const [values, setValues] = useState(fields);
  const [data, setData] = useState([]);
  const { calculatorData } = useSelector(getInsuranceCalculator);
  const dispatch = useDispatch();
  const history = useHistory();

  const benefits = calculatorData.benefits;

  useEffect(() => {
    setSteps(stepsValues, BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES, dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!sex || !type) {
      resetSteps(dispatch);
      return history.push(BUY_PLAN_URLS.CREATE_YOUR_PLAN);
    }

    const newData = benefits.filter((value) => VALID_SERVICES.includes(value.name.toLowerCase()));
    const initialValues = _.cloneDeep(fields);
    for (const data of newData) {
      initialValues[data.name] = { field: "", value: "", type: "benefits", ans: "" };
    }
    setData(newData);
    setValues(initialValues);
    dispatch(addField(initialValues));
  }, [history, benefits, dispatch]);

  const handleChange = (value, name, fields, points) => {
    const newValues = _.cloneDeep(values);

    newValues[name].field = name;
    newValues[name].value = value;
    newValues[name].ans = fields[points.lastIndexOf(value)];
    setValues(newValues);

    dispatch(addField(newValues));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(BUY_PLAN_URLS.MEDICAL_SERVICES_BENEFITS);
  };

  return (
    <div className="py-5 w-full">
      <Form onSubmit={handleSubmit}>
        {data.length > 0 ? (
          data.map((benefit, index) => (
            <CustomSelect
              key={benefit.name + index}
              label={benefit.name}
              value={values[benefit.name]?.field}
              onChange={({ target }) =>
                handleChange(target.value, benefit.name, benefit.value.names, benefit.value.points)
              }
              items={benefit.value.names}
              points={benefit.value.points}
            />
          ))
        ) : (
          <p>No Info</p>
        )}
        <AuthButton className="my-5 bg-[#E6072C]">Proceed</AuthButton>
        <PricingCard disabled={true} />
      </Form>
    </div>
  );
};

export default MedicalService;
