import React from "react";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../store/userData";
import { numberWithCommas } from "../../../utils/helper";
import styles from "../index.module.css";

import usePaystack from "../../hooks/usePaystack";
import usePaystackMethods from "../../hooks/usePaystackMethods";

const PricingCard = ({line=false, disabled }) => {
  const { amount_paid,normalPrice,isNormalPrice } = useSelector(getUserInfo);
  let count;
  let newValue;
     
 
  return (
    <div className={styles["plan-reading-container"]+'h-[200px]'}>
      <div className="w-full">
        <p>Starting from</p>
        {
          isNormalPrice ? 
          <strong className={styles["reading-price"] + `text-[14px] sm:text-[24px] ${line ? 'line-through':'none'}`}>N {numberWithCommas(normalPrice)}.00</strong> 
          :
          <strong className={styles["reading-price"] + `text-[14px] sm:text-[24px] ${line ? 'line-through':'none'}`}>N {numberWithCommas(amount_paid)}.00</strong> 
        }
        
      </div>
      {/* <div>
        <button
          onClick={() => handlePayment(onSuccess, onClose)}
          className={`${styles["buy-button"]} ${disabled ? "opacity-50" : ""}`}
          disabled={disabled}
        >
          
        </button>
      </div> */}
    </div>
  );
};

export default PricingCard;
