import React from 'react'
import avatar_bg from '../../../assets/images/avatar.svg'
import { useHistory } from 'react-router-dom';

function ProfileBg() {
    const userData = JSON.parse(localStorage.getItem('user'));
    const history = useHistory();
  return (
    <div className={`w-full h-[200px] relative bg-gradient-to-r from-red-500 via-red-500 to-pink-500 ...`}> 
        <div className='flex items-center pt-[50px]'>
            <div>
                <img src={avatar_bg} width="100" height={"100"} />
            </div>
            <div className='text-[14px] ml-[10px] flex flex-col '>
                <div className='text-[24px] '><span className='font-bold'>{' '}{userData.first_name} {' '}{userData.last_name}</span></div>
                <div className='text-[14px] text-white'><span className='font-bold'>Email</span>: {' '}{userData?.email} {' '}</div>
                <div className='text-[14px] text-white'><span className='font-bold'>Phone Number</span>: {' '}{userData?.phone_number}</div>
            </div>
        </div>
        <div className="flex justify-end mr-[20px]">
            <div className="p-[5px] font-bold text-[14px] cursor-pointer bg-white w-[150px] justify-center items-center rounded-[2px]" onClick={() => history.push('/dashboard/profile/password')}>Change Password</div>
        </div>
    </div>
  )
}

export default ProfileBg