import { createSlice } from "@reduxjs/toolkit";

function getTotalAmount(objs) {
  const array = Object.keys(objs);
  return array.reduce((acc, key) => {
    return objs[key].value > 100
      ? (acc += objs[key].value > 0 ? objs[key].value : 0)
      : (acc += objs[key].value > 0 ? objs[key].value * 1000 : 0);
  }, 0);
}

let newState = {
  has_promo: false,
  type: "",
  sex: "",
  demographics: {
    fields: [],
    values: [],
  },
  benefits: {
    fields: [],
    values: [],
  },
  transaction_id: "",
  hospital: "",
  amount_paid: 0,
  normalPrice:0,
  isNormalPrice:false,
  loading: false,
  fields: {},
  number_of_dependents: null,
  spouse_name: "",
  spouse_sex: "",
  dependentData: null,
};

let slice = createSlice({
  name: "userInfo",
  initialState: {
    has_promo: false,
    type: "",
    sex: "",
    demographics: {
      fields: [],
      values: [],
    },
    benefits: {
      fields: [],
      values: [],
    },
    transaction_id: "",
    hospital: "",
    amount_paid: 0,
    normalPrice:0,
    isNormalPrice:false,
    loading: false,
    fields: {},
    number_of_dependents: null,
    spouse_name: "",
    spouse_sex: "",
    dependentData: null,
  },
  reducers: {
    sexAdded: (data, { payload }) => {
      data.sex = payload;
    },
    hospitalAdded: (data, { payload }) => {
      data.hospital = payload;
    },
    dependentDataAdded: (data, { payload }) => {
      data.dependentData = payload;
    },

    fieldAdded: (data, { payload }) => {
      const newFields = { ...data.fields, ...payload };
      data.fields = newFields;
      data.amount_paid = getTotalAmount(newFields);
      data.normalPrice = getTotalAmount(newFields);
    },
    setAmountAdded: (data, {payload}) => {
      data.amount_paid = payload;
    },
    setNormalPrice: (data, {payload}) => {
      data.normalPrice = payload
    },
    setNormalPriceStat: (data, {payload}) => {
      data.isNormalPrice = payload
    },
    maritalStatusAdded: (data, { payload }) => {
      data.type = payload;
    },
    numberOfDependentAdded: (data, { payload }) => {
      data.number_of_dependents = payload;
    },

    spouseAdded: (data, { payload }) => {
      data.spouse_name = payload.spouse_name;
      data.spouse_sex = payload.spouse_sex;
    },

    resetUserInfo: (data, {payload}) => {
      data = payload;
    }
  },
});

const {
  sexAdded,
  setAmountAdded,
  setNormalPrice,
  setNormalPriceStat,
  maritalStatusAdded,
  hospitalAdded,
  fieldAdded,
  numberOfDependentAdded,
  spouseAdded,
  dependentDataAdded,
  resetUserInfo
} = slice.actions;

export default slice.reducer;

export const addGender = (gender) => (dispatch) => dispatch({ type: sexAdded.type, payload: gender });

export const addField = (objs) => (dispatch) => dispatch({ type: fieldAdded.type, payload: objs });
export const addAmount = (data) => (dispatch) => dispatch({type: setAmountAdded.type, payload: data}); 

export const setPrice = (data) => (dispatch) => dispatch({type:setNormalPrice.type, payload: data});
export const setPriceStat = (data) => (dispatch) => dispatch({type:setNormalPriceStat.type, payload: data});


export const addHospital = (hospital) => (dispatch) => dispatch({ type: hospitalAdded.type, payload: hospital });

export const addMaritalStatus = (status) => (dispatch) => dispatch({ type: maritalStatusAdded.type, payload: status });

export const addSpouse = (spouse_name, spouse_sex) => (dispatch) =>
  dispatch({ type: spouseAdded.type, payload: { spouse_name, spouse_sex } });

export const addDependentInfo = (dependents) => (dispatch) =>
  dispatch({ type: dependentDataAdded.type, payload: dependents });

export const addNumberOfDependent = (number) => (dispatch) =>
  dispatch({ type: numberOfDependentAdded.type, payload: number });

export const getUserInfo = (state) => state.entities.userInfo;

export const setResetInfo = (state) => (dispatch) =>
  dispatch({type: resetUserInfo.type,payload: newState })