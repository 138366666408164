import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Form, AuthButton } from '../../Containers';
import { CustomSelect, CustomFormControl } from '../../common';
import PricingCard from '../../UI/PricingCard';
import { fetchHospitalList, fetchHospitalLocation, getHospitals } from '../../../store/hospitals';
import { useDispatch, useSelector } from 'react-redux';
import { addAmount, addHospital, addMaritalStatus, setPrice } from '../../../store/userData';
import { TextField } from '@mui/material';
import { FaCheck } from 'react-icons/fa';
import axios from 'axios';
import usePaystack from '../../hooks/usePaystack';
import { toast } from 'react-toastify';
import { fetchPromo, postBuyOther } from '../../../utils/helper';

function StandardPlan() {
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [maritalStatus, setMaritalStatus] = useState("single");
    const [planType, setPlanType] = useState([]);
    const [hospitalLocation, setHospitalLocation] = useState("");
    const [hospital, setHospital] = useState("");
    const [sex, setSex] = useState('');
    const [pickedHospital, setPickedHospital] = useState({});
    const { list, locations, } = useSelector(getHospitals);
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem("token");
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState('');
    const [buyOther, setBuyOther] = useState(null);
    const [currentPlan, setCurrentPlan] = useState({
        name: "",
        price: ""
    });
    const  [promo, setPromo] = useState('');
    const routePath = useLocation();
    const [planLoad, setPlanLoad] = useState(false);
    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        dispatch(fetchHospitalLocation());
    }, [dispatch]);

    useEffect(() => {
        dispatch(addAmount(0));
        fetchPromo(setPromo);
    }, [promo]);

    useEffect(() => {
        onTop()
    }, [routePath]);
    const onClose = () => {
    };

    const handlePayment = usePaystack();

    const fetchPlanStatus = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/verify_plan`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            });
            setStatus(data?.status);
            setMessage(data?.message);
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "bottom-right"
            });
        }
    }

    useEffect(() => {
        fetchPlanStatus();
    }, []);


    useEffect(() => { 
        if ((currentPlan?.name != "")) {
            let count;
            if (promo) {
                count = promo/100 * currentPlan?.price[maritalStatus];;
            }else {
                count = 0;
            }
            let newValue = currentPlan?.price[maritalStatus] - count;
            console.log(newValue);
            console.log(promo/100 * currentPlan?.price[maritalStatus]);
            dispatch(addAmount(newValue));
            dispatch(setPrice(newValue))
        }
        if (currentPlan?.name == "BASIC") {
            let count;
            if (promo) {
                count = promo/100 * currentPlan?.price;
            }else {
                count = 0;
            }
            let newValue = currentPlan?.price - count;
            console.log(newValue);
            console.log(promo/100 * currentPlan?.price);
            dispatch(addAmount(newValue));
            dispatch(setPrice(newValue))
        }
    }, [currentPlan, maritalStatus]);

    const hanldeSetHospital = (value) => {
        console.log(value);
        const item = list.find((item) => item.name === value);
        const values = {};
        setPickedHospital(item);
    };

    const postStandard = async (obj) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/standard_plans`,
                obj, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            });

            history.push('/dashboard/wellnesshmo');
            toast.success(data?.message, {
                position: "bottom-right"
            });
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "bottom-right"
            });
            
        }
    }


    const onSuccess = (reference) => {

        let demograph = {
            marital_status: maritalStatus,
            hospital_name: pickedHospital?.name,
            hospital_address: pickedHospital?.address
        }

        if (buyOther == 'myself') {
            let obj = {
                user_id: userData?.id,
                health_plan_provider_id: pickedHospital?.id,
                health_plan_id: currentPlan?.id,
                health_plan_benefit_plan_id: currentPlan?.id,
                health_plan_price_type: "paystack",
                health_plan_demographics: JSON.stringify(demograph),
                status: "pending",
                sex: sex,
                transaction_id: reference.trxref
            }
            postStandard(obj);
        } else if (buyOther == 'other') {
            let obj = {
                request_type: "standard",
                data : {
                    first_name: firstName,
                    last_name: lastName,
                    phone: phone,
                    email: email,
                    request_type: "standard",
                    service_name: "standard",
                    services: JSON.stringify(demograph),
                    amount_paid: currentPlan?.name == "BASIC" ? currentPlan?.price : currentPlan?.price[maritalStatus],
                    hospital_name: pickedHospital?.name,
                    hospital_location: pickedHospital?.location,
                    hospital_address: pickedHospital?.address,
                    doctor_name: "",
                    comment: "",
                    appointment_date: "",
                    has_promo: promo ? true : false,
                    transaction_id: reference.trxref
                }
                
            }
            postBuyOther(obj,toast,history);
        }


    };



    const fetchType = async () => {
        setPlanLoad(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/standard_plans/plan_types`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            });
            setPlanType(data);
            setPlanLoad(false)
        } catch (err) {
            setPlanLoad(false);
        }
    }

    useEffect(() => {
        fetchType();
    }, []);

    dispatch(addMaritalStatus(maritalStatus));
    dispatch(addHospital(hospital));

    const handleSelectPlanType = (e) => {
        let plantype = e.target.value;
        planType.map((data, index) => {
            if (data.name == plantype) {
                console.log(data);
                setCurrentPlan(data);
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(pickedHospital);
        if (!status && buyOther == 'myself') {
            toast.success(message, {
                position: "bottom-right"
            });
        } else {
            handlePayment(onSuccess, onClose);
        }

    };
    const handleHospitalLocationChange = ({ target }) => {
        setHospitalLocation(target.value);
        dispatch(fetchHospitalList(target.value));
    };

    return (
        <div className='w-full min-h-[90vh] bg-white'>
            <div className='bg-[#f6c3c3] w-full h-[100px]'></div>
            <div className='p-[20px]'>
                <div className="flex items-center"></div>
                <div className='flex flex-col sm:flex-row sm:justify-between'>
                    <div className='w-full sm:w-[50%]'>
                        <div className="text-[24px] text-[#E6072C] font-bold">Buy Standard Plan</div>
                        <div className="text-[16px] text-[#5A5454]">Please fill the form to proceed</div>
                        <div>
                            <div className='font-bold '>Plan Details</div>
                            <div className='font-bold mb-2'>Benefits</div>
                            <div>
                                <div className='flex items-center mb-4'>
                                    <FaCheck color={"#DBD5FC"} />
                                    <div className='ml-[12px]'>Emergency Care & stabilization</div>
                                </div>
                                <div className='flex items-center mb-4'>
                                    <FaCheck color={"#DBD5FC"} />
                                    <div className='ml-[12px]'>Intensive Care Services</div>
                                </div>
                                <div className='flex items-center mb-4'>
                                    <FaCheck color={"#DBD5FC"} />
                                    <div className='ml-[12px]'>General Outpatient Consult</div>
                                </div>
                                <div className='flex items-center mb-4'>
                                    <FaCheck color={"#DBD5FC"} />
                                    <div className='ml-[12px]'>Specialist Consultation</div>
                                </div>
                                <div className='flex items-center mb-4'>
                                    <FaCheck color={"#DBD5FC"} />
                                    <div className='ml-[12px]'>Supply of Drugs</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit} className="py-5 px-[30px] w-full sm:w-[50%]">
                        <CustomSelect
                            label={'Buy For'}
                            value={buyOther}
                            onChange={({ target }) => {
                                dispatch(addAmount(0));
                                setBuyOther(target.value);
                                setCurrentPlan({
                                    name: "",
                                    price: ""
                                });
                            }}
                            loading={false}
                            items={[
                                { value: "myself", label: "Myself" },
                                { value: "other", label: "Others" },
                            ]}
                        />
                        {
                            buyOther == 'myself' &&
                            <>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={`id-${userData.first_name}`}
                                        label={'Name'}
                                        variant="outlined"
                                        value={`${userData.first_name}${' '} ${userData.last_name}`}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={`id-${userData.email}`}
                                        label={'Email'}
                                        variant="outlined"
                                        value={`${userData.email}`}

                                    />
                                </CustomFormControl>
                                <CustomSelect
                                    label="Sex"
                                    value={sex}
                                    onChange={({ target }) => setSex(target.value)}
                                    loading={false}
                                    items={[
                                        { value: "male", label: "Male" },
                                        { value: "female", label: "Female" },
                                    ]}
                                />
                                <CustomSelect
                                    label="Marital Status"
                                    value={maritalStatus}
                                    onChange={({ target }) => setMaritalStatus(target.value)}
                                    loading={loading}
                                    items={[
                                        { value: "single", label: "Single" },
                                        { value: "family", label: "Married" },
                                    ]}
                                />
                                <CustomSelect
                                    label="Plan Type"
                                    value={currentPlan?.name}
                                    onChange={handleSelectPlanType}
                                    loading={planLoad}
                                    items={planType}
                                />
                                <div>
                                    <CustomSelect
                                        label="Hospital Location"
                                        value={hospitalLocation}
                                        onChange={handleHospitalLocationChange}
                                        loading={loading}
                                        items={locations}
                                    />
                                    <CustomSelect
                                        label="Hospital"
                                        value={hospital}
                                        onChange={({ target }) => hanldeSetHospital(target.value)}
                                        loading={loading}
                                        items={list}
                                    />
                                </div>
                            </>
                        }

                        {
                            buyOther == 'other' &&
                            <>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'FirstName'}
                                        label={'First Name'}
                                        variant="outlined"
                                        value={firstName}
                                        required
                                        onChange={({ target }) => setFirstName(target.value)}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'LastName'}
                                        label={'Last Name'}
                                        variant="outlined"
                                        value={lastName}
                                        required
                                        onChange={({ target }) => setLastName(target.value)}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'Phone'}
                                        label={'Phone Number'}
                                        variant="outlined"
                                        value={phone}
                                        required
                                        onChange={({ target }) => setPhone(target.value)}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'email'}
                                        label={'Email Address'}
                                        variant="outlined"
                                        value={email}
                                        required
                                        onChange={({ target }) => setEmail(target.value)}
                                    />
                                </CustomFormControl>
                                <CustomSelect
                                    label="Marital Status"
                                    value={maritalStatus}
                                    onChange={({ target }) => setMaritalStatus(target.value)}
                                    loading={loading}
                                    items={[
                                        { value: "single", label: "Single" },
                                        { value: "family", label: "Married" },
                                    ]}
                                />
                                <CustomSelect
                                    label="Plan Type"
                                    value={currentPlan?.name}
                                    onChange={handleSelectPlanType}
                                    loading={planLoad}
                                    items={planType}
                                />
                                <div>
                                    <CustomSelect
                                        label="Hospital Location"
                                        value={hospitalLocation}
                                        onChange={handleHospitalLocationChange}
                                        loading={loading}
                                        items={locations}
                                    />
                                    <CustomSelect
                                        label="Hospital"
                                        value={hospital}
                                        onChange={({ target }) => hanldeSetHospital(target.value)}
                                        loading={loading}
                                        items={list}
                                    />
                                </div>

                            </>
                        }


                        <div className="pb-10">
                            <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
                        </div>
                        <PricingCard disabled={true} />
                        {(promo && currentPlan?.name) && 
                        <div className='flex'>
                            <div>{promo}% discount</div>
                            <div className='ml-[10px] line-through font-bold'>N{currentPlan?.name == "BASIC" ? currentPlan?.price : currentPlan?.price[maritalStatus]}</div>
                        </div>}
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default StandardPlan