import React, { useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { CustomFormControl, ModalLoader } from '../../../common'
import styles from "./index.module.css";
import { useHistory } from 'react-router-dom';
import Form from '../../../Containers/Form';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthButton } from '../../../Containers';



function VerifyId() {
    const [enrolleeId, setEnrolleeId] = useState({ value: "", invalid: false });
    const [loading, setLoading] = useState(false);
    const [enrolleeDetail, setEnrolleeDetail] = useState('');
    const [client, setClient] = useState({ value: "", invalid: false });
    const [email, setEmail] = useState({ value: "", invalid: false });
    const [firstName, setFirstName] = useState({ value: "", invalid: false });
    const [lastName, setLastName] = useState({ value: "", invalid: false });
    const [password, setPassword] = useState({ value: "", invalid: false });
    const [phoneNumber, setPhoneNumber] = useState({ value: "", invalid: false });
    const [planType, setPlanType] = useState({ value: "", invalid: false });
    const [isSuccess, setIsSuccess] = useState(false);
    const history = useHistory();
    const token = localStorage.getItem("token");
    const [step, setStep] = useState(1);

    const handleRegister = async(e) => {
        e.preventDefault();
        setLoading(true);
        if(enrolleeId.value == "") {
          toast.error('Please fill the form')
        } else {
          try {
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v1/get_enrollee_details`,
            {
              params: {
                insurance_no: enrolleeId.value
              }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              }
            },
            )
            if(data.status == 'success') {
              setLoading(false);
              setFirstName({ value: data?.data?.enrollee?.firstname, invalid: false })
              setLastName({ value: data?.data?.enrollee?.lastname, invalid: false })
              setPlanType({ value: data?.data?.enrollee?.plan?.name, invalid: false });
              setPhoneNumber({ value: data?.data?.enrollee?.phone, invalid: false });
              setEmail({ value:data?.data?.enrollee?.email, invalid: false });
              setClient({ value: data?.data?.enrollee?.company?.name, invalid: false })
              setEnrolleeDetail(data?.data?.enrollee);
              localStorage.setItem("otp",JSON.stringify(data?.data?.otp));
              console.log(data.data.enrollee)
              setStep(2);
            }
          }catch(err) {
            console.log(err);
          }
        }
        
      };

    const handleSetValues = (prevValues, newValue, setFn) => {
      const newData = { ...prevValues };
      newData.value = newValue;
      newData.invalid = false;
      setFn(newData);
    };

    const handleVerify = (e) => {
      e.preventDefault();
      localStorage.setItem("enrollee",JSON.stringify(enrolleeDetail));
      
      history.push('/dashboard/wellnesshmo/verifyId/otp');
    }

    return (
        <div className={styles["plan-wrapper"]}>
            <div className="mb-10 flex items-center">
              <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                <IoArrowBackOutline />
              </button>
              <p className="ml-1.5">Back</p>
            </div>
            {
              step == 1 &&
              <div className={styles["container"] + 'block lg:flex lg:justify-between'}>
                <ModalLoader visible={loading} />
                <div className={styles["title-container"]}>
                    <p className={styles["title"]}>Verify your ID</p>
                    <p className={styles["sub-title"]}>
                        Please fill the form to proceed.
                    </p>
                </div>
                <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2 p-4'>
                    <Form className={styles["form"]} onSubmit={handleRegister}>
                        <div className={styles["form-control"]}>
                            <CustomFormControl styles={{ mt: 0 }}>
                                <TextField
                                id="first-name"
                                label="Id"
                                variant="outlined"
                                error={enrolleeId.invalid}
                                value={enrolleeId.value}
                                onChange={({ target }) => handleSetValues(enrolleeId, target.value, setEnrolleeId)}
                                />
                            </CustomFormControl>
                        </div>
                        <div className="flex justify-between"></div>
                        <div>
                          <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
                          <div className="flex items-center mt-3 justify-center">
                            <p className="mr-3">Can't remember your Enrollee ID?</p>
                            <Link to="/dashboard/contact" variant="body2">
                              Chat With us
                            </Link>
                          </div>
                        </div>
                    </Form>
                </div>      
            </div>
            }

            {
              step == 2 &&
              <div className={styles["container"] + 'block lg:flex lg:justify-between'}>
                <ModalLoader visible={loading} />
                <div className={styles["title-container"]}>
                    <p className={styles["title"]}>Verify Detail</p>
                    <p className={styles["sub-title"]}>
                        Please would you like to proceed?
                    </p>
                </div>
                <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2 p-4'>
                    <Form className={styles["form"]} onSubmit={handleVerify}>
                        <div className={styles["form-control"]}>
                            <CustomFormControl styles={{ mt: 0 }}>
                                <TextField
                                    disabled
                                    id="first-name"
                                    label="Enrollee Id"
                                    variant="outlined"
                                    error={enrolleeId.invalid}
                                    value={enrolleeId.value}   
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="first-name"
                                label="Name"
                                variant="outlined"
                                error={firstName.invalid}
                                value={firstName.value+ ' ' + lastName.value}
                                
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="fit-name"
                                label="Email"
                                variant="outlined"
                                error={email.invalid}
                                value={email.value}
                                
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="client"
                                label="Client"
                                variant="outlined"
                                error={client.invalid}
                                value={client.value}
                                
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mt: 2 }}>
                                <TextField
                                disabled
                                id="first-name"
                                label="Plan Type"
                                variant="outlined"
                                error={planType.invalid}
                                value={planType.value}
                                />
                            </CustomFormControl>
                        </div>
                        <div className="flex justify-between"></div>
                        <div>
                          <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
                          <div className="flex items-center mt-3 justify-center">
                            <p className="mr-3">Can't remember your Enrollee ID?</p>
                            <Link to="/dashboard/contact" variant="body2">
                              Chat With us
                            </Link>
                          </div>
                        </div>
                    </Form>
                </div>      
            </div>

            }
            
        </div>
    )
}

export default VerifyId