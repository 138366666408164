import React, { useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5';
import { CustomFormControl, ModalLoader } from '../../../common';
import { useHistory } from 'react-router-dom';
import {Form,AuthButton} from '../../../Containers';
import { MenuItem, Select, TextField } from '@mui/material';
import { TiTimes } from 'react-icons/ti';
import styles from './index.module.css';


function MedicalHistory() {
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [giveName, setGiveName] = useState('');
    const [giveNumber, setGiveNumber] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
    }


  return (
    <div className={styles["plan-wrapper"]}>
        <ModalLoader visible={loading} />
        <div className="mb-10 flex items-center">
            <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                <IoArrowBackOutline />
            </button>
            <p className="ml-1.5">Back</p>
        </div>
        <div className={styles["container"] + 'block lg:flex lg:justify-between'}> 
            <div className={styles["title-container"]}>
                <p className={styles["title"]}>Setup Medical Record  (1/2 )</p>
                <p className={styles["sub-title"]}>
                    We need your medical history to enable the doctor treat you in the best way
                </p>
            </div>
            <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2'>
                <div>
                    <Form onSubmit={handleSubmit} className="py-5">
                        {
                            step == 1 &&
                            <>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Blood Type</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={"O"}>O</MenuItem>
                                    <MenuItem value={"AB"}>AB</MenuItem>
                                    <MenuItem value={"A"}>A</MenuItem>
                                </Select>
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Asthmatic</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Diabetic History</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Major Illness Treatment</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Are you allergic to any drug</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </CustomFormControl>
                            </>
                        }
                        {
                            step == 2 &&
                            <>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>List of current medication?</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={"O"}>O</MenuItem>
                                    <MenuItem value={"AB"}>AB</MenuItem>
                                    <MenuItem value={"A"}>A</MenuItem>
                                </Select>
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Caregiver Name (optional)</div>
                                <TextField
                                    id={`id-name`}
                                    label={''}
                                    variant="outlined"
                                    required
                                    value={giveName}
                                    onChange={(e) => setGiveName(e.target.value)}
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Caregiver Phone Number</div>
                                <TextField
                                    id={`id-name`}
                                    label={''}
                                    variant="outlined"
                                    required
                                    value={giveNumber}
                                    onChange={(e) => setGiveNumber(e.target.value)}
                                />
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Health Status</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={'healthy'}>Healthy</MenuItem>
                                    <MenuItem value={'unhealthy'}>Unhealthy</MenuItem>
                                </Select>
                            </CustomFormControl>
                            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                <div className='text-[14px]'>Comments</div>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </CustomFormControl>
                            </>
                        }
                        <div className="pb-10">
                        <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
)
}

export default MedicalHistory