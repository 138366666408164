import React from "react";
import styles from "../index.module.css";
import Avatar from "@mui/material/Avatar";
import fileOpenImg from "../../../../assets/images/file-open.png";
import { sidebarNavBarStyles } from "../../../styles/styles";
import { useHistory } from "react-router-dom";

const RightSide = ({ processes,appointmentDate }) => {
  const classes = sidebarNavBarStyles();
  const history = useHistory();
  return (
      <div className={styles["purple-container"]}>
        <img src={fileOpenImg} alt="SA" className={classes.file} />
        <div className={styles["purple-title"]}>Your next Appointment Date is</div>
        <div className={styles["purple-date"]}>{appointmentDate ? new Date(appointmentDate).toLocaleDateString() : '---'}</div>
        <div onClick={() => history.push('/dashboard/profile')} style={{cursor:'pointer'}} className={styles["purple-subtitle"] + 'cursor-pointer'}>View Appointment Details  </div>
      </div>
  );
};

export default RightSide;
