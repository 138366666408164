import React, { useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { Link, useHistory  } from 'react-router-dom';
import { FaTooth,FaHeartbeat } from "react-icons/fa";
import { GiDoctorFace } from "react-icons/gi";
import { GiBrain } from "react-icons/gi";
import { Card } from '../../../common';
import video from '../../../../assets/images/video-camera.svg';
import hospital from '../../../../assets/images/hospital-fill.svg';
import DoctorList from '../BookDoctor/DoctorList';


function CallAppointment() {
  const history = useHistory();
  const [specialization, setSpecialization] = useState('');
  const baseUrl = "/dashboard";
  
  return (
    <div className='bg-white min-h-[100vh] pb-[100px] px-[30px]'>
      <div className="mb-2 flex items-center">
        <button className="" onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Back</p>
      </div>
      <div className='flex justify-between items-center'>
        <div className='font-bold text-[16px]'>All categories</div>
        <div>See All</div>
      </div>
      <div>
        <div className='mb-10 items-center px-5 flex flex-col gap-2'>
          {/* Display List of Category  */}
          <div className='grid grid-cols-3 mt-5 md:grid-cols-4 lg:grid-cols-6 '>
            <Link href={'/search/'} className='flex flex-col text-center items-center p-5 bg-blue-50 m-2 rounded-lg cursor-pointer gap-2 hover:scale-110 transition-all ease-in-out'>
              <FaTooth />
              <label className='text-blue-600 text-sm'>Dental</label>
            </Link>
            <Link href={'/search/'} className='flex flex-col text-center items-center p-5 bg-blue-50 m-2 rounded-lg cursor-pointer gap-2 hover:scale-110 transition-all ease-in-out'>
              <FaHeartbeat />
              <label className='text-blue-600 text-sm'>Cardiologist</label>
            </Link>
            <Link href={'/search/'} className='flex flex-col text-center items-center p-5 bg-red-100 m-2 rounded-lg cursor-pointer gap-2 hover:scale-110 transition-all ease-in-out'>
              <GiDoctorFace color="red" />
              <label className='text-blue-600 text-sm'>General Doctor</label>
            </Link>
            <Link href={'/search/'} className='flex 
              flex-col text-center items-center
              p-5 bg-blue-50 m-2 rounded-lg cursor-pointer
              gap-2 hover:scale-110 transition-all ease-in-out'>
              <GiBrain />
              <label className='text-blue-600 text-sm'>Neurologist</label>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className="font-bold text-[16px]">Our Offer</div>
        <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <Card
            title={"Start Instant Call"}
            subtitle={"Start an instant call with any of our doctors"}
            image={video}
            link={`${baseUrl}`}
          />
          <Card
            title={"Book an apppointment"}
            subtitle={"Book a telemedicine appointment with a doctor "}
            image={hospital}
          />
          <Card
            title={"Home Visit"}
            subtitle={"Book a home visit appointment with a doctor"}
            image={hospital}
            link={`${baseUrl}/homevisit`}
          />
        </div>
      </div>
      <div>
        <div className='flex justify-between items-center'>
          <div className='font-bold text-[16px]'>Top Doctors</div>
          <div>See All</div>
        </div>
        <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-7 mt-4 lg:grid-cols-4'>
            <DoctorList />
            <DoctorList />
            <DoctorList />
            <DoctorList />
            <DoctorList />
            <DoctorList />
        </div>
      </div>
    </div>
  )
}

export default CallAppointment