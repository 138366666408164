import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { MdArrowBackIos } from 'react-icons/md';
import { RiWhatsappFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

function Telemedicine() {
    const history = useHistory();
  return (
    <div className={`w-[100%] min-h-[400px] px-[2rem]`}>
      <div className='w-full flex items-center'>
        <MdArrowBackIos className="cursor-pointer text-[20px] font-bold" onClick={() => history.goBack() } />
        <div className="text-[#E6072C] w-full flex justify-center font-bold text-[24px]">Telemedicine</div>
      </div>
      <div className="text-[#92929d] text-center">
        You can have a call with any of our very competent doctors and we would get back to you as soon as  we can.
      </div>
      <div className='mt-[20px]'>
        <div className='w-full min-h-[94px] rounded-[4px] cursor-pointer flex justify-between p-[20px]  bg-white shadow-[0px,4px,16px,0px,#37354514]'>
            <div>
                <div className='text-[14px]'>Talk to us</div>
                <div className='flex items-center mt-[3px]'>
                    <FaPhoneAlt /> <span className='font-bold ml-1'>0800TELEMED( 08008353633 )</span>
                </div>
            </div>
            <IoIosArrowForward />
        </div>
        <div className='w-full min-h-[94px] mt-[20px] rounded-[4px] cursor-pointer flex justify-between p-[20px]  bg-white shadow-[0px,4px,16px,0px,#37354514]'>
            <div>
                <div className='text-[14px]'>Chat with us</div>
                <div className='flex items-center mt-[3px]'>
                    <RiWhatsappFill /> <span className='font-bold ml-1'>09164069841</span>
                </div>
            </div>
            <IoIosArrowForward />
        </div>
      </div>
    </div>
  )
}

export default Telemedicine