import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
//import { persistStore, persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage";
import { api } from "./middleware/api.js";
import rootReducer from "./reducer";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    // reducer: persistedReducer,
    reducer: rootReducer,
    middleware: [thunk, api],
});

// export const persistor = persistStore(store);

export default store;