import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import styles from "./index.module.css";

const Process = ({ processes }) => {
  return (
    <ul className={styles["lists"] }>
      {processes.map((process, index, arr) => {
        const isLast = arr.length === index + 1;
        const { title, description, isActive, activeBorder, show, completed } = process;

        return show ? (
          <li key={title} className={`${styles["list-item"]} ${isActive ? styles["active"] : ""} `}>
            <div
              className={`${styles["list-content"]} ${!isLast ? styles["border-left"] : ""} ${styles[activeBorder]} ${
                completed && !isLast ? styles["completed-border"] : ""
              }`}
            >
              <div className={styles["check-container"]}>
                <span className={`${styles["check-mark"]} ${completed && styles["completed-mark"]}`}>
                  <BsFillCheckCircleFill size="1.7em" />
                </span>
              </div>
              <div className={styles["content-wrapper"]}>
                <h2 className={`${styles["list-title"]} ${completed && styles["completed-list-title"]}`}>{title}</h2>
                <p className={`${styles["list-description"]} ${completed && styles["completed-list-description"]}`}>
                  {description}
                </p>
              </div>
            </div>
          </li>
        ) : null;
      })}
    </ul>
  );
};

export default Process;
