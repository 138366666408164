import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import FormContainer from "../../../../Containers/FormContainer";
import CreatePlan from "./widgets/CreatePlan";
import StepTwo from "./widgets/StepTwo";
import StepThree from "./widgets/StepThree";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchPromo, getDentalCareSteps, setSteps } from "../../../../../utils/helper";
import { DENTAL_CARE_STEPS, DENTAL_CARE_URLS } from "../../../../../utils/constants";
import { fetchHospitalLocation } from "../../../../../store/hospitals";
import { getInsuranceCalculator, stopLoading } from "../../../../../store/insuranceCalculator";
import { getPlanSteps } from "../../../../../store/steps";
import ModalLoader from "../../../../common/Loader";
import Alert from "../../../../common/Alert";
import { addAmount } from "../../../../../store/userData";



const DentalCare = () => {
  const { values } = useSelector(getPlanSteps);
  const [allField, setAllField] = useState([]);
  const [allValue, setAllValue] = useState([]);
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [promo, setPromo] = useState('');
  const [buyOther,setBuyOther] = useState('');


  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentStep = getDentalCareSteps(params.step);
  const { loading, error } = useSelector(getInsuranceCalculator);


  useEffect(() => {
    dispatch(addAmount(0));
    fetchPromo(setPromo);
}, [promo]);


 

  useEffect(() => {
    if (error) Alert.notication("error", "Error", error);
    setSteps(values, currentStep, dispatch);
    dispatch(fetchHospitalLocation());
    dispatch(stopLoading());
  }, [dispatch, error]);

  useEffect(() => {
    if (!currentStep) return history.push(DENTAL_CARE_URLS.CREATE_DENTAL_CARE_PLAN);
  }, [currentStep, history, dispatch]);

  return (
    <div className={styles["plan-wrapper"]}>
      <div className="mb-2 lg:mb-10 flex items-center">
        <button className={styles["back-btn"]} onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Dental Care</p>
      </div>
      <div className={styles["plan-container"]}>
        <ModalLoader visible={loading} />
        <div className={styles["title-container"]}>
          <p className={styles["title"]}>Dental Care</p>
          <p className={styles["sub-title"]}>Get access to credible dental services here.</p>
        </div>
        <FormContainer className={styles["form-container"]}>
          {step == 1 && 
          <CreatePlan 
            setStep={setStep} 
            firstName={firstName} 
            setFirstName={setFirstName} 
            lastName={lastName} 
            setLastName={setLastName} 
            email={email} 
            setEmail={setEmail} 
            phone={phone} 
            setPhone={setPhone} 
            buyOther={buyOther}
            setBuyOther={setBuyOther}
            promo={promo}
            />
            

            }
          {step == 2 && 
          <StepTwo
            setStep={setStep} 
            allField={allField} 
            allValue={allValue} 
            setAllValue={setAllValue} 
            setAllField={setAllField} 
            
          />}
          {step == 3 && 
          <StepThree
            firstName={firstName} 
            setFirstName={setFirstName} 
            lastName={lastName} 
            setLastName={setLastName} 
            email={email} 
            setEmail={setEmail} 
            phone={phone} 
            setPhone={setPhone} 
            buyOther={buyOther}
            setBuyOther={setBuyOther}
            promo={promo}
            setPromo={setPromo}
            allField={allField} 
            allValue={allValue} 
            setAllValue={setAllValue} 
            setAllField={setAllField} 
            />}
        </FormContainer>
      </div>
    </div>
  );
};

export default DentalCare;
