import React from "react";
import { Portal } from "react-portal";
import Loader, { Oval } from "react-loader-spinner";
import styles from "./index.module.css";

const SmallLoader = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className="flex items-center justify-center py-3">
      <Oval type="Oval" color="#E6072C" height={30} width={30} />
    </div>
  );
};

export default SmallLoader;
