import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchHospitalList, getHospitals } from "../../../../store/hospitals";
import { addField, addGender, addHospital, addMaritalStatus, addSpouse } from "../../../../store/userData";
import CustomFormControl from "../../../common/FormControl";
import CustomSelect from "../../../common/Select";
import PricingCard from "../../../UI/PricingCard";
import { BUY_PLAN_STEPS_TITLE, BUY_PLAN_URLS, STEPS } from "../../../../utils/constants";
import { getPlanSteps, updateSteps } from "../../../../store/steps";
import { setSteps } from "../../../../utils/helper";
import { AuthButton, Form } from "../../../Containers";

const CreatePlan = ({ pickedHospital,setPickedHospital,setBuyOther, buyOther, firstName, setFirstName, setLastName, lastName, email, setEmail, phone, setPhone }) => {
  const { values: stepsValues } = useSelector(getPlanSteps);
  const [sex, setSex] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [hospitalLocation, setHospitalLocation] = useState("");
  const [hospital, setHospital] = useState("");
  const [spouse, setSpouse] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const { list, locations, loading } = useSelector(getHospitals);

  useEffect(() => {
    dispatch(updateSteps(STEPS));
    setSteps(stepsValues, BUY_PLAN_STEPS_TITLE.CREATE_YOUR_PLAN, dispatch);
  }, [dispatch]);

  const handleHospitalLocationChange = ({ target }) => {
    setHospitalLocation(target.value);
    dispatch(fetchHospitalList(target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!sex.trim() || !maritalStatus.trim() || !hospitalLocation.trim() || !hospital.trim()) {
      return alert("gender, marital status and hospital is required");
    }

    const spouseGender = sex === "male" ? "female" : "male";
    dispatch(addGender(sex));
    dispatch(addMaritalStatus(maritalStatus));
    dispatch(addHospital(hospital));
    dispatch(addSpouse(spouseName, spouseGender));
    history.push(BUY_PLAN_URLS.BIODATA);
  };

  const hanldeSetHospital = (value) => {
    const item = list.find((item) => item.name === value);
    const values = {};
    setPickedHospital(item);
    values["Hospital"] = { field: "Hospital", value: item.point, type: "demographics", ans: value };
    dispatch(addField(values));
    setHospital(value);
  };

  const handleSetHasSpouse = (value) => {
    setSpouseName(value);
  };

  return (
    <Form onSubmit={handleSubmit} className="py-5">
      <CustomSelect
        label={'Buy For'}
        value={buyOther}
        onChange={({ target }) => {
          setBuyOther(target.value);
        }}
        loading={false}
        required
        items={[
          { value: "myself", label: "Myself" },
          { value: "other", label: "Others" },
        ]}
      />
      {
        buyOther == 'other' &&
        <>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'FirstName'}
              label={'First Name'}
              variant="outlined"
              value={firstName}
              required
              onChange={({ target }) => setFirstName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'LastName'}
              label={'Last Name'}
              variant="outlined"
              value={lastName}
              required
              onChange={({ target }) => setLastName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'Phone'}
              label={'Phone Number'}
              variant="outlined"
              value={phone}
              required
              onChange={({ target }) => setPhone(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'email'}
              label={'Email Address'}
              variant="outlined"
              value={email}
              required
              onChange={({ target }) => setEmail(target.value)}
            />
          </CustomFormControl>
        </>
      }
      <CustomSelect
        label="Sex"
        value={sex}
        onChange={({ target }) => setSex(target.value)}
        loading={load}
        items={[
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
        ]}
      />
      <CustomSelect
        label="Marital Status"
        value={maritalStatus}
        required
        onChange={({ target }) => setMaritalStatus(target.value)}
        loading={load}
        items={[
          { value: "single", label: "Single" },
          { value: "married", label: "Married" },
          // { value: "divorced", label: "Divorced" },
        ]}
      />
      {maritalStatus === "married" && (
        <div className="pb-2">
          <CustomSelect
            label="Do you have a spouse?"
            value={spouse}
            onChange={({ target }) => setSpouse(target.value)}
            loading={load}
            items={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
              // { value: "divorced", label: "Divorced" },
            ]}
          />
        </div>
      )}

      {spouse === "yes" && (
        <CustomFormControl styles={{ mb: 0, mt: 2 }}>
          <TextField
            id="spouse-name"
            label="Spouse Name"
            variant="outlined"
            value={spouseName}
            onChange={({ target }) => handleSetHasSpouse(target.value)}
          />
        </CustomFormControl>
      )}

      <CustomSelect
        label="Hospital Location"
        value={hospitalLocation}
        onChange={handleHospitalLocationChange}
        loading={loading}
        items={locations}
      />

      <CustomSelect
        label="Hospital"
        value={hospital}
        onChange={({ target }) => hanldeSetHospital(target.value)}
        loading={loading}
        items={list}
      />
      <div className="pb-10">
        <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
      </div>
      <PricingCard disabled={true} />
    </Form>
  );
};

export default CreatePlan;
