import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
    name: "insuranceCalculator",
    initialState: {
        calculatorData: {
            demographics: [],
            benefits: [],
        },
        loading: false,
    },
    reducers: {
        insuranceApproved: (data, { payload }) => {
            data.loading = false;
        },
        fetchedCalculatorData: (data, { payload }) => {
            data.loading = false;

            if (payload.data) {
                data.calculatorData.demographics = payload.data.insurance_demographics;

                data.calculatorData.benefits = payload.data.insurance_benefits;
            }
        },
        fetchedDataStarted: (data) => {
            data.loading = true;
        },
        fetchedFailed: (data) => {
            data.loading = false;
        },
        loadingStop: (data) => {
            data.loading = false;
        },
    },
});

const { fetchedCalculatorData, loadingStop, fetchedDataStarted, fetchedFailed, insuranceApproved } = slice.actions;

export default slice.reducer;

const insuranceUrl = "/insurance-calculator";

export const stopLoading = () => (dispatch) => dispatch({ type: loadingStop.type, payload: null });

// married => /family/male
export const fetchInsuranceCalculator = (maritalStatus, gender, buyOther) => {
    if (buyOther == 'myself') {
       return apiCallBegan({
            url: `${insuranceUrl}/${maritalStatus !== "single" ? "family" : `${maritalStatus}`}/${gender}`,
            method: "GET",
            onSuccess: fetchedCalculatorData.type,
            onStart: fetchedDataStarted.type,
            onError: fetchedFailed.type,
        });
    } else if ( buyOther == 'other') {
      return  apiCallBegan({
            url: `insurance-calculator-buy-for-others/${maritalStatus !== "single" ? "family" : `${maritalStatus}`}/${gender}`,
                method: "GET",
                onSuccess: fetchedCalculatorData.type,
                onStart: fetchedDataStarted.type,
                onError: fetchedFailed.type,
            });
    }



}

export const fetchMarriedInsuranceCalculator = (gender, spouseGender,buyOther) => {
    
    if(buyOther == 'myself') {
        return apiCallBegan({
            url: `${insuranceUrl}/family/${gender}/${spouseGender}`,
            method: "GET",
            baseUrl: `${process.env.REACT_APP_API_URL}/v2`,
            onSuccess: fetchedCalculatorData.type,
            onStart: fetchedDataStarted.type,
            onError: fetchedFailed.type,
        });
    } else if (buyOther == 'other') {
        return  apiCallBegan({
            url: `insurance-calculator-buy-for-others/family/${gender}`,
                method: "GET",
                onSuccess: fetchedCalculatorData.type,
                onStart: fetchedDataStarted.type,
                onError: fetchedFailed.type,
            });
    }
    

}

export const createHealthInsurance = (data) =>
    apiCallBegan({
        url: `${insuranceUrl}`,
        method: "POST",
        data,
        onSuccess: insuranceApproved.type,
        onStart: fetchedDataStarted.type,
        onError: fetchedFailed.type,
    });

export const getInsuranceCalculator = (state) => state.entities.insuranceCalculator;