import { makeStyles } from "@mui/styles";

export const resetPasswordPageStyle = makeStyles(() => ({
  formContainer: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 50px 70px rgba(11, 16, 51, 0.06)",
    borderRadius: "16px",
    padding: "2rem 3rem",
    width: "70%",
    margin: "auto",
    marginTop: "8rem",
    color: "#505050",
    paddingTop: "6rem",
    paddingBottom: "3rem",
    "@media (max-width: 400px)": {
      width: "100%"
    }
    
  },
  resetP: {
    color: "#333333",
    fontWeight: "bold",
    fontSize: "1.25rem",
    marginBottom: "10px",
  },
  inputSection: {
    margin: "1.5rem 0rem",
  },
  formLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #E6072C",
    borderRadius: "4px",
  },
  formInput: {
    border: "1px solid #808080",
    borderRadius: "4px",
    padding: "0.5rem",
    width: "100%",
    backgroundColor: "transparent",
    outline: "none",
  },
  submitform: {
    backgroundColor: "#E6072C",
    borderRadius: "4px",
    textAlign: "center",
    color: "#FCF9FA",
    width: "100%",
    padding: "0.5rem 0rem",
  },
}));
