import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addDependentInfo, addField } from "../../../../store/userData";
import { getUserInfo } from "../../../../store/userData";
import CustomSelect from "../../../common/Select";
import AuthButton from "../../../Containers/AuthButton";
import PricingCard from "../../../UI/PricingCard";
import Form from "../../../Containers/Form";
import { BUY_PLAN_STEPS_TITLE, BUY_PLAN_URLS } from "../../../../utils/constants";
import { TextField } from "@mui/material";
import CustomFormControl from "../../../common/FormControl";
import { resetSteps, setSteps } from "../../../../utils/helper";
import { getPlanSteps } from "../../../../store/steps";

const DEPENDENT_AGE = [
  {
    id: "Dependent 1 Age",
    nameId: "Dependent 1",
    name: "Dependent 1 Age",
    title: "Age of first dependent",
    value: { names: ["0-5", "6-10", "11-18"], points: [6, 5, 3] },
  },

  {
    id: "Dependent 2 Age",
    nameId: "Dependent 2",
    name: "Dependent 2 Age",
    title: "Age of second dependent",
    value: { names: ["0-5", "6-10", "11-18"], points: [6, 5, 3] },
  },

  {
    id: "Dependent 3 Age",
    nameId: "Dependent 3",
    name: "Dependent 3 Age",
    title: "Age of third dependent",
    value: { names: ["0-5", "6-10", "11-18"], points: [6, 5, 3] },
  },

  {
    id: "Dependent 4 Age",
    nameId: "Dependent 4",
    name: "Dependent 4 Age",
    title: "Age of fourth dependent",
    value: { names: ["0-5", "6-10", "11-18"], points: [6, 5, 3] },
  },
];

const DEPENDENT_NAME = [
  { id: "Dependent 1", name: "Name of first dependent", value: "", disabled: true },
  { id: "Dependent 2", name: "Name of second dependent", value: "", disabled: true },
  { id: "Dependent 3", name: "Name of third dependent", value: "", disabled: true },
  { id: "Dependent 4", name: "Name of fourth dependent", value: "", disabled: true },
];

// ['dependent 1 name', 'dependent 1 age']
// ['john', '0-5']

const validateDependents = (dependentsObj, numbersOfDependents) => {
  const keys = Object.keys(dependentsObj);

  if (keys.length !== parseInt(numbersOfDependents)) return false;

  for (const key of keys) {
    const dependent = dependentsObj[key];
    if (!dependent.fields || dependent.fields.length !== dependent.values.length) return false;
  }
  return true;
};

const Dependents = () => {
  const { values: stepsValues } = useSelector(getPlanSteps);
  const [ageValues, setAgeValues] = useState({});
  const [nameValues, setNameValues] = useState({});
  const [dependentAge, setDependentAge] = useState([]);
  const [dependentName, setDependentName] = useState([]);
  const [benefitsData, setBenefitsData] = useState({});
  const { fields, sex, type } = useSelector(getUserInfo);

  const { number_of_dependents } = useSelector(getUserInfo);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!sex || !type) {
      resetSteps(dispatch);
      return history.push(BUY_PLAN_URLS.CREATE_YOUR_PLAN);
    }

    setSteps(stepsValues, BUY_PLAN_STEPS_TITLE.DEPENDENT, dispatch);
  }, [fields, history, dispatch]);

  useEffect(() => {
    if (!number_of_dependents) {
      return history.push(BUY_PLAN_URLS.CREATE_YOUR_PLAN);
    }

    const ageRange = DEPENDENT_AGE.slice(0, number_of_dependents);
    const nameRange = DEPENDENT_NAME.slice(0, number_of_dependents);

    setDependentAge(ageRange);
    setDependentName(nameRange);

    const initialAgeValues = {};
    for (const value of ageRange) {
      initialAgeValues[value.name] = {
        nameId: value.nameId,
        field: "",
        value: "",
        type: "benefits",
        ans: "",
        dependentId: "",
        dependentName: "",
      };
    }
    setAgeValues(initialAgeValues);

    const initialNameValues = {};
    for (const value of nameRange) {
      initialNameValues[value.name] = { field: "", value: "", type: "benefits", ans: "" };
    }
    setNameValues(initialNameValues);

    dispatch(addField(initialAgeValues));
    dispatch(addField(initialNameValues));
  }, [number_of_dependents, history, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateDependents(benefitsData, number_of_dependents);

    if (!isValid) {
      alert("please enter all dependents details");
      return;
    }

    const newAges = _.cloneDeep(ageValues);

    const keys = Object.keys(ageValues);

    for (const key of keys) {
      const nameId = newAges[key].nameId;
      const dependent = dependentName.find((name) => name.id === nameId);
      newAges[key].dependentId = dependent.id;
      newAges[key].dependentName = dependent.value;
    }
    dispatch(addField(newAges));
    dispatch(addDependentInfo(benefitsData));

    history.push(BUY_PLAN_URLS.MEDICAL_SERVICES);
  };

  const handleAgeChange = (value, name, fields, points, nameId) => {
    const nameD = dependentName.find((d) => d.id === nameId);

    if (!nameD.value) {
      return alert(`Please enter ${nameD.name}`);
    } else {
      const data = _.cloneDeep(benefitsData);

      data[nameId] = { fields: [nameId], values: [fields[points.lastIndexOf(value)]] };

      setBenefitsData(data);

      const newValues = _.cloneDeep(ageValues);

      newValues[name].field = name;
      newValues[name].value = value;
      newValues[name].ans = fields[points.lastIndexOf(value)];
      setAgeValues(newValues);

      dispatch(addField(newValues));
    }
  };

  const handleChangeName = (value, name, nameId) => {
    const newValues = _.cloneDeep(nameValues);
    const newDependNames = dependentName.map((dependent) => {
      if (dependent.id === nameId) dependent.value = value;
      return dependent;
    });

    newValues[name].field = nameId;
    newValues[name].value = value;
    newValues[name].ans = value;

    setDependentName(newDependNames);

    dispatch(addField(newValues));
  };

  return (
    <div className="py-5 w-full">
      <Form onSubmit={handleSubmit}>
        {dependentName.length > 0 && (
          <>
            {dependentName.map((data, index) => {
              const ageData = dependentAge[index];
              return (
                <div className="w-full" key={data.name + index}>
                  <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                    <TextField
                      id={`id-${data.name}`}
                      label={data.name}
                      variant="outlined"
                      value={data.value}
                      onChange={({ target }) => handleChangeName(target.value, data.name, data.id)}
                    />
                  </CustomFormControl>
                  {
                    <CustomSelect
                      key={ageData.name + index}
                      label={ageData.title}
                      value={ageValues[ageData.name]?.field}
                      onChange={({ target }) =>
                        handleAgeChange(target.value, ageData.name, ageData.value.names, ageData.value.points, data.id)
                      }
                      items={ageData.value.names}
                      points={ageData.value.points}
                    />
                  }
                </div>
              );
            })}
          </>
        ) }
        <AuthButton className="my-5 bg-[#E6072C]">Proceed</AuthButton>
        <PricingCard disabled={true} />
      </Form>
    </div>
  );
};

export default Dependents;
