import React from "react";
import PropTypes from "prop-types";
// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FaCheck, FaTimes } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40%",
    margin: "auto",
    zIndex:9999999,
    background:'#ffffff'
  },
  paper: {
    
    padding: theme.spacing(6),
    borderRadius: "8px",
  },
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'auto'
 
};

export default function PlanModal({ open, close, plandata, modalTitle }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FaTimes onClick={close} className="cursor-pointer absolute right-2" />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {plandata.map((data,index) => {
            return (
                <div className='flex items-center mb-4'>   
                    <FaCheck className="w-[40px]" size={24} color={"#DBD5FC"} />
                    <div className='ml-[12px] max-w-[300px]'>{data}</div>
                </div>
            )
        })} 
        </Typography>
      </Box>
    </Modal>
  );
}
