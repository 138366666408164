import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
// icons
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SearchIcon from "@mui/icons-material/Search";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { MdCancel } from "react-icons/md";
import { RiHospitalLine } from "react-icons/ri";
import topLogo from "../../assets/images/wellness-logo.png";
import {TiLocationArrowOutline } from "react-icons/ti";
import AppMenu from "../menu";
import { sidebarNavBarStyles } from "../styles/styles";
import { FiHome, FiUser } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth";
import { IconButton, ListItem } from "@mui/material";

const SideBarLinks = ({ url, name, icon, onClick }) => {
  const classes = sidebarNavBarStyles();
  const location = useLocation();
 
  const getActive = (currentLocation) => {
    const paths = location.pathname.split("/");
    const currentPath = currentLocation.split("/");
    if (
      location.pathname === currentLocation ||
      (paths[paths.length - 2] === currentPath[currentPath.length - 2] && paths[2] === "buy-your-plan")
    ) {
      return {
        backgroundColor: "#E6072C",
        color: "#fff",
      };
    }
  };

  return (
    <ul>
      <li className={classes.navList} onClick={onClick} style={getActive(url)}>
        <Link to={url}>
          <ListItem>
            <ListItemIcon style={getActive(url)}>{icon}</ListItemIcon>
            <ListItemText> {name}</ListItemText>
          </ListItem>
        </Link>
      </li>
    </ul>
  );
};

SideBarLinks.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
};

const SideBar = ({ iconButton, logoOnClose }) => {
  const classes = sidebarNavBarStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSide, setShowSide] = useState(true);
  let isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
  isEnrollee = isEnrollee?.insurance_no;
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
    setShowSide(true)
  };

  const handleDrawerClose = () => {
    // setOpen(false);
    setShowSide(false)
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={classes.root}>
      <div className="bg-white z-[999999] w-full h-[60px] fixed flex justify-between items-center px-[20px]">
        <div className="flex">
          {iconButton && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon color="disabled" />
            </IconButton>
          )}

          <Avatar
            src={topLogo}
            alt="WS"
            className={clsx(classes.small, {
              [classes.hide]: logoOnClose && open,
            })}
          />
          <div className="ml-[20px] flex items-center font-bold">Wellness plus</div>
        </div>
        <label className="w-[20%] hidden sm:flex">
          <SearchIcon color="disabled" />
          <input type="text" placeholder="Search"  />
        </label>
        <div className="flex">
          <NotificationsNoneIcon color="disabled" style={{ marginRight: "1rem" }} />
          <Avatar
            className={classes.small}
            src="https://res.cloudinary.com/dycyotaya/image/upload/v1629366830/IMG_20210705_144144_255_pbxn8l.jpg"
            alt="SA"
          />
          <AppMenu
            menu_name_or_icon={<ArrowDropDownIcon color="disabled" />}
            item_names={[
              {
                Profile: () => history.push('/dashboard/profile'),
              },
            ]}
          />
        </div>
      </div>
      <div className="hidden md:block bg-[#CC0E15]">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.drawerBar}>
            <Avatar
              onClick={() => history.push("/")}
              src="https://res.cloudinary.com/dycyotaya/image/upload/v1629978378/Wellness-Logo_jpdtgr.png"
              alt="WS"
              className={classes.drawerBarLogo}
            />
            <IconButton onClick={handleDrawerClose}>
              <MenuOpenIcon color="disabled" />
            </IconButton>
          </div>
          <div className={classes.drawerBarContainer}>
            <div className={classes.drawerBarLinks}>
              <SideBarLinks name="Home" url="/dashboard" icon={<FiHome size="1.5em" />} />
              {
                !isEnrollee &&
                <SideBarLinks
                  name="Wellness HMO"
                  url={"/dashboard/wellnesshmo/buy-your-plan"}
                  icon={<RiHospitalLine size="1.5em" />}
                />
              }
              <SideBarLinks
                name="Medical Checkup"
                url={"/dashboard/medical-checkup"}
                icon={<TiLocationArrowOutline size="1.5em" />}
              />
              <SideBarLinks
                name="Profile"
                url={"/dashboard/profile"}
                icon={<FiUser size="1.5em" />}
              />
            </div>
            <div>
              <SideBarLinks
                name="Logout"
                url="/"
                icon={<ExitToAppIcon className={classes.logoutIcon} />}
                onClick={() => handleLogout()}
              />
            </div>
          </div>
        </Drawer>
      </div>
      {/* sidebar for smaller screen */}
      {
        showSide && 
        <div className="absolute top-[0] z-[999999999] md:hidden">
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.drawerBar}>
              <Avatar
                onClick={() => history.push("/")}
                src="https://res.cloudinary.com/dycyotaya/image/upload/v1629978378/Wellness-Logo_jpdtgr.png"
                alt="WS"
                className={classes.drawerBarLogo}
              />
              <MdCancel onClick={handleDrawerClose} size={32} className="mr-[10px] cursor-pointer" />
            </div>
            <div className={classes.drawerBarContainer}>
              <div className={classes.drawerBarLinks}>
                <SideBarLinks name="Home" url="/dashboard" icon={<FiHome size="1.5em" />} />  
                {
                  !isEnrollee &&
                  <SideBarLinks
                  name="Wellness HMO"
                  url={"/dashboard/wellnesshmo/buy-your-plan"}
                  icon={<RiHospitalLine size="1.5em" />}
                />}
                <SideBarLinks
                  name="Medical Checkup"
                  url={"/dashboard/medical-checkup"}
                  icon={<TiLocationArrowOutline size="1.5em" />}
                />
                <SideBarLinks
                  name="Profile"
                  url={"/dashboard/profile"}
                  icon={<FiUser size="1.5em" />}
                />
              </div>
              <div>
                <SideBarLinks
                  name="Logout"
                  url="/"
                  icon={<ExitToAppIcon className={classes.logoutIcon} />}
                  onClick={() => handleLogout()}
                />
              </div>
            </div>
          </Drawer>
        </div>
      }  
    </div>
  );
};

export default SideBar;
