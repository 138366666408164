import React, { useState } from "react";
import { resetPasswordPageStyle } from "./resetStyle";
import Loader from "../../common/Loader";
import LeftSide from "../Auth/LeftSide";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import styles from './index.module.css';


const ResetCode = () => {
  const classes = resetPasswordPageStyle();
  const [passwordResetCode, setPasswordResetCode] = useState("");
  const [loading, setLoading] = useState(false);
  const email = localStorage.getItem('email');
  const history = useHistory();

  const checkResetPassCode = async (e) => {
    e.preventDefault();
    if(passwordResetCode == '') {
      toast.error("Fill in the reset code, sent to your email",{
        position:"bottom-right"
      });
    }else {
      setLoading(true);
      try {
        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/verify-reset-pwd-code`,
        {
          email,
          reset_code: passwordResetCode
        });
        history.push('/new-password');
        toast.success(data?.message,{
          position:"bottom-right"
        });
        
        setLoading(false);
      }catch(err) {
        //err
      }
    }
     
  };

  return (
    <div className="w-full flex justify-center">
      <LeftSide />
      <Loader visible={loading} />
      
      <div className="w-full ">
        <form className={`${classes.formContainer} ${styles['auth-form']}`} onSubmit={checkResetPassCode}>
          <p className={classes.resetP}>Verify Reset Code</p>
          <p className={classes.resetText}>Enter the six-digit code that was sent to your email address</p>
          <div className={classes.inputSection}>
            <label>
              <sup>*</sup>Verification Code
            </label>{" "}
            <br />
            <input
              type="text"
              id="code"
              name="code"
              value={passwordResetCode}
              onChange={(e) => setPasswordResetCode(e.target.value)}
              placeholder="333333"
              className={classes.formInput}
            />
          </div>
          <br />
          <button className={classes.submitform}>Confirm</button>
        </form>
      </div>
    </div>
  );
};

export default ResetCode;
