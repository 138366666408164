import React, { useState } from 'react'
import { MdOutlineCancel } from "react-icons/md";
import { useHistory } from "react-router-dom";
 
function User() {
    const [step, setStep] = useState(0);
    const history = useHistory();
  
    const handleNextStep = () => {
        if(step == 1) {

        }
        if(step == 2) {
            history.push('/dashboard');
        }
    }
  return (
        <div className="bg-[#BC1A33] relative  w-full h-[100vh] flex flex-col justify-center">
          
          <div className='w-full flex flex-col  items-center'>
            <div className='text-white w-[40%] mb-[20px] text-[30px]'>
                Hi<br />
                <strong>Let us get to know you</strong>
            </div>
            <div  className="bg-white rounded-[8px] w-[40%] min-h-[100px] flex  items-center px-[25px]">
                <label onClick={() => {
                setStep(1);
                }} className="mr-[20px]">
                <input type='radio' name="favourite_food" value="Pasta"></input>
                </label>
                <div>
                <div className="font-bold">Wellness Enrollee</div>
                <div className="text-[14px] text-[#918f9f]">
                    I am a Wellness Enrollee and I have an existing account with Wellness HMO
                </div>
                </div>
            </div>
            <div  className="bg-white rounded-[8px] mt-[20px] w-[40%] min-h-[100px] flex items-center px-[25px]">
                <label onClick={() => {
                setStep(2);
                }} className="mr-[20px]">
                <input type='radio' name="favourite_food" value="Pasta"></input>
                </label>
                <div>
                <div className="font-bold">Non Enrollee</div>
                <div className="text-[14px] text-[#918f9f]">
                    This is my first time and I have no active plan with Wellness HMO
                </div>
                </div>
            </div>
            <div onClick={() => handleNextStep()} className="cursor-pointer mt-[20px] flex justify-center items-center font-[600] rounded-[5px] bg-white w-[40%] h-[50px]">
                PROCEED
            </div>
          </div>
          
        </div>
  )
}

export default User