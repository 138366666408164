import React from 'react'
import { MdArrowBackIos } from 'react-icons/md'
import { useHistory } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";


function Contact({size}) {
    const history = useHistory();
  return (
    <div className={`${size ? 'w-[100%]':'w-[70%]'} min-h-[400px] px-[2rem]`}>
      <div className='w-full relative flex items-center'>
        <MdArrowBackIos className="cursor-pointer text-[20px] font-bold" onClick={() => history.goBack() } />
        <div className="text-[#E6072C] w-full flex justify-center font-bold text-[24px]">Contact Us</div>
      </div>
      <div className="text-[#92929d] text-center">We are here to help you all the way</div>
      <div className='mt-[20px]'>
        <div className='w-full min-h-[94px] rounded-[4px] cursor-pointer flex justify-between p-[20px]  bg-white shadow-[0px,4px,16px,0px,#37354514]'>
          <div>
            <div className='text-[14px]'>Talk to us</div>
            <div className='flex items-center mt-[3px]'>
              <FaPhoneAlt /> <span className='font-bold ml-1'>0700WELLNESS</span>
            </div>
          </div>
          <IoIosArrowForward />
        </div>
        <div className='w-full min-h-[94px] mt-[20px] rounded-[4px] cursor-pointer flex justify-between p-[20px]  bg-white shadow-[0px,4px,16px,0px,#37354514]'>
          <div>
            <div className='text-[14px]'>Chat with us</div>
            <div className='flex items-center mt-[3px]'>
            <RiWhatsappFill /> <span className='font-bold ml-1'>08143290066</span>
            </div>
          </div>
          <IoIosArrowForward />
        </div>
        <div className='w-full min-h-[94px] mt-[20px] rounded-[4px] cursor-pointer flex justify-between p-[20px]  bg-white shadow-[0px,4px,16px,0px,#37354514]'>
          <div>
            <div className='text-[14px]'>Send us an email</div>
            <div className='flex items-center mt-[3px]'>
            <MdOutlineMailOutline /> <span className='font-bold ml-1'>callcentre@wellnesshmo.com</span>
            </div>
          </div>
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  )
}

export default Contact