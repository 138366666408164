import { MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react'
import CustomFormControl from '../../../common/FormControl';
import AuthButton from '../../../Containers/AuthButton';
import { IoArrowBackOutline } from 'react-icons/io5';
import styles from "./index.module.css";
import { useHistory } from 'react-router-dom';
import Form from '../../../Containers/Form';
import axios from 'axios';
import ModalLoader from '../../../common/Loader';
import { toast } from 'react-toastify';


function AddDependant() {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('user'));
  const [depName, setDepName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [sex, setSex] = useState('');
  const [relation, setRelation] = useState('');
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async(e) => {
    e.preventDefault();
    setIsLoading(true);
    let obj = {
      first_name: depName,
      last_name: lastName,
      gender: sex,
      dob: dob
    }
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v2/users/dependents`,
      obj,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      });
      setIsLoading(false);
      toast.success(data?.message,{
        position:"bottom-right"
      });
      history.push('/dashboard/wellnesshmo');
      console.log(data);
    }catch(err) {
      console.log(err);
    }

  };
  return (
    <div className={styles["plan-wrapper"]}>
      <ModalLoader visible={isLoading} />
      <div className="mb-10 flex items-center">
        <button className={styles["back-btn"]} onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Back</p>
      </div>
      <div className={styles["container"] + 'block lg:flex lg:justify-between'}>
        <div className={styles["title-container"]}>
          <p className={styles["title"]}>Add New Dependant</p>
          <p className={styles["sub-title"]}>
            Add a new dependent to your dependent list.
          </p>
        </div>
        <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2'>
          <div>
            <Form onSubmit={handleSubmit} className="py-5">
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  id={`id-${1}`}
                  label={'First Name'}
                  required
                  variant="outlined"
                  value={depName}
                  onChange={(e) => setDepName(e.target.value)} 
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                    id={`id-${1}`}
                    label={'Last Name'}
                    required
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)} 
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <div>Date of Birth</div>
                <input type="date" required className='border p-2 rounded border-[#d1cfcf]' value={dob} onChange={(e) => setDob(e.target.value)} />
              </CustomFormControl>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <div className='text-[14px]'>Sex</div>
                <Select
                  value={sex}
                  required
                  onChange={(e) => setSex(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={'male'}>Male</MenuItem>
                  <MenuItem value={'female'}>Female</MenuItem>
                </Select>
              </CustomFormControl>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  id={`id-${2}`}
                  label={'Relationship'}
                  required
                  variant="outlined"
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}     
                />
              </CustomFormControl>
              <div className="pb-10">
                <AuthButton className="mt-5 bg-[#EC4D67]">Add</AuthButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default AddDependant;