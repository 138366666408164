import React, { useEffect } from "react";
import Routes from "./routes";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { addAmount } from "./store/userData";


const App = () => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(addAmount(0));
  // },[])
  return (
    <>
      <CssBaseline />
        <Routes />
      <ToastContainer />
    </>
  );
};

export default App;
