import React from "react";
import { useSelector } from "react-redux";

import { getUserInfo } from "../../store/userData";
import { usePaystackPayment } from "react-paystack";

const usePaystack = () => {
  const { amount_paid,normalPrice,isNormalPrice } = useSelector(getUserInfo);
  // const { user } = useSelector(getAuth);
  const user = JSON.parse(localStorage.getItem("user"));
  let count;
  let newValue;
      if (isNormalPrice) {
        count = 10 / 100 * normalPrice;
        newValue = normalPrice - count;
      } else {
        count = 0;
        newValue = amount_paid - count;
      }
  // you can call this function anything

  const config = {
    reference: new Date().getTime().toString(),
    email: user && user.email,
    amount: newValue * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  };
  const initializePayment = usePaystackPayment(config);
  return initializePayment;
};

export default usePaystack;
