import React, { useEffect, useState, useRef  } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import styles from "./index.module.css";
import { useHistory } from 'react-router-dom';
import {Form, AuthButton} from '../../../Containers';
import { MenuItem, Select, TextField } from '@mui/material';
import avatar_bg from '../../../../assets/images/avatar.svg'
import { toast } from 'react-toastify';
import { TiTimes } from 'react-icons/ti';
import axios from 'axios';
import usePaystack from '../../../hooks/usePaystack';
import { useDispatch } from 'react-redux';
import { addAmount } from '../../../../store/userData';
import { ModalLoader,CustomFormControl } from '../../../common';

function HmoCard() {
    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem('user'));
    const isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
    const [image, setImage] = useState('');
    const [cardCollect, setCardCollect] = useState(true);
    const [address, setAddress] = useState('');
    const [cost, setCost] = useState(1000);
    const uploadfile = useRef(null);
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(addAmount(1000));
    })

    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log("closed");
    };

    const handlePayment = usePaystack();
  // const { onSuccess, onClose } = usePaystackMethods();


  const postHmoCard = async(obj) => {
    setLoading(true);
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/users/enrollees/request-card`,
      obj,{    
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`${token}`
      }
    });
    toast.success(data?.message,{
      position:"bottom-right"
    });
    setLoading(false);
    history.push('/dashboard/wellnesshmo');
    }catch(err) {
      setLoading(false);
      toast.error(err?.response?.data?.message,{
        position:"bottom-right"
    });
    }
  }


  const onSuccess = (reference) => {
    
    let obj = {
      card_collected: true,
      passport_url: image,
      payment_name: userData?.first_name,
      payment_amount: cost,
      transaction_id: reference.trxref,
      address: address,
      payment_type: "paystack"
    }
     postHmoCard(obj);
     
     window.location.href('/dashboard');
     //Alert.notication("success", "Subscription", "subscription successful");
     // Implementation for whatever you want to do with reference and after success call.
     // dispatch(logout());
     window.location.href('/dashboard')
     // setTimeout(() => {
     //      window.location.href('/dashboard');
     // }, 2000);
     console.log(reference);
 };
 


    const handleSubmit = async(e) => {
      e.preventDefault();
      if(cardCollect) {
        if(image) {
          handlePayment(onSuccess, onClose);
        }else {
          toast.error('Select an image',{
            position:"bottom-right"
          });
        }
        
      }else {
        let obj = {
          card_collected: false,
          passport_url: image,
          address: address
        }
        postHmoCard(obj);
      }
      
      
      
    };
    const handleImageChange = async(event) => {
      const file = event.target.files[0];
      if((file?.type == 'image/jpeg') || (file?.type == 'image/png') || (file?.type == 'image/svg') || (file?.type == 'image/jpg')) {
        const imageFile = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        // reader.onloadend = () => {
        // setImage(reader.result);
        // };

        let obj = {
          file: file,
          api_key: `${process.env.REACT_APP_CLOUD_KEY}`,
          upload_preset: "wellness"
        }


        try {
          const resp = await axios.post("https://api.cloudinary.com/v1_1/hazon-technologies/image/upload",obj,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          console.log(resp);
          setImage(resp?.data?.secure_url);
    
        }catch(err) {
          console.log(err);
        }

      }else {
        toast.error('Select an Image')
      }
      
    }

    const removeImage = () => {
      setImage('');
    }

  return (
  <div className={styles["plan-wrapper"]}>
    <ModalLoader visible={loading} />
    <div className="mb-10 flex items-center">
      <button className={styles["back-btn"]} onClick={() => history.goBack()}>
        <IoArrowBackOutline />
      </button>
      <p className="ml-1.5">Back</p>
    </div>
    <div className={styles["container"] + 'block lg:flex lg:justify-between'}> 
      <div className={styles["title-container"]}>
        <p className={styles["title"]}>Request HMO Card</p>
        <p className={styles["sub-title"]}>
          Make a request for your HMO card here
        </p>
      </div>
      <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2'>
        <div>
          <Form onSubmit={handleSubmit} className="py-5">
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <TextField
                  disabled
                  id={`id-${userData.first_name}`}
                  label={'Enrollee ID'}
                  variant="outlined"
                  value={`${isEnrollee?.insurance_no}`}
                  
              />
            </CustomFormControl>

            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  disabled
                  id={`id-${userData.first_name}`}
                  label={'Name'}
                  variant="outlined"
                  value={`${userData.first_name}${' '}${userData.last_name}`}     
                />
            </CustomFormControl>
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <TextField
                  id={`address`}
                  label={'Address'}
                  variant="outlined"
                  value={address}
                  required
                  onChange={({ target }) => setAddress(target.value)}
                  
              />
            </CustomFormControl>
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <div className='text-[14px]'>Have you collected a card before?</div>
              <Select
                value={cardCollect}
                onChange={(e) => setCardCollect(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </CustomFormControl>
            {
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <div className='text-[14px] p-2 mb-2'>Upload Picture</div>
              <div className='relative'>
                {
                  image && 
                  <div className='w-[20px] h-[20px] flex justify-center items-center rounded-full bg-white absolute top-[10%] left-[10%] cursor-pointer'>
                    <TiTimes size={24} className='' color={"#F26363"} onClick={removeImage} /> 
                  </div>
                }
                {
                  image ? <img src={image} alt="uploaded image"  width={60} height={60}/>
                  :  <img  src={avatar_bg} width="60" height={"60"} />
                }
                <div className=''>
                  <label className='cursor-pointer' htmlFor='uploadFile'>
                      <input id="uploadFile" hidden type="file" ref={uploadfile} onChange={handleImageChange} />
                      <p className='flex justify-end relative '>
                        <div className='absolute bottom-5 right-[20%] border p-2 rounded text-[#534DE0]'>upload image</div>
                      </p>
                  </label>
                </div>
              </div>
            </CustomFormControl>
            }
            <div className="pb-10">
              <AuthButton className="mt-5 bg-[#EC4D67]">Request HMO Card</AuthButton>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HmoCard