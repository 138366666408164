import { Box, Modal, Typography } from '@mui/material'
import React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import checkdone from '../../../assets/images/checkdone.svg'
function DialDocModal({open, close}) {
  return (
   <></>
  )
}

export default DialDocModal