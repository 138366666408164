import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomFormControl from "../FormControl";
import SmallLoader from "../Loader/Small";
const CustomSelect = ({ loading = false, label, initialValue, onChange = () => {}, items = [], points }) => {
  return (
    <CustomFormControl  fullWidth styles={{ mt: 2 }}>
      <InputLabel id={`select-${label}`}>{label}</InputLabel>
      <Select required labelId={`select-${label}`} id={`select-${label}`} value={initialValue} label={label} onChange={onChange}>
        {loading ? (
          <SmallLoader visible={loading} />
        ) : (
          items?.map((item, index, arr) => {
            const id = points
              ? label + Date.now() + index + item
              : label + (item.label || item.name || item) + Date.now();
            return (
              // <MenuItem key={id} value={item.name || item}>
              <MenuItem key={id} value={points ? points[index] : item.value || item.name || item}>
                {item.label || item.name || item}<br />{' '}{(item?.address)}
              </MenuItem>
            );
          })
        )}
      </Select>
    </CustomFormControl>
  );
};

export default CustomSelect;
