import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sidebarNavBarStyles } from "../../styles/styles";
import styles from "./index.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowRight";

const Card = ({ title, subtitle, image,link }) => {
  const classes = sidebarNavBarStyles();
  const history = useHistory();

  return (
    <div
      className={styles["rightSide"]}
      onClick={() => {
        history.push(link)
      }}
    >
      <div className={styles["combined"]}>
        <div className={styles["image-view"]}>
          <img src={image} alt="SA" className={classes.rightImage} />
          <div className={styles["rightSide-header"]}>
            <div className={styles["rightSide-title"]}>{title}</div>
            <div className={styles["rightSide-subtitle"]}>{subtitle}</div>
          </div>
        </div>
        <ArrowBackIcon color="disabled" onClick={() => {}} className={classes.closeIcon} />
      </div>
    </div>
  );
};

export default Card;
