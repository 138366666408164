import { makeStyles } from "@mui/styles";
const drawerWidth = 240;

export const sidebarNavBarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: `'Mulish', sans-serif`,

  },

  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#FFFFFF!important",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)!important",
    color: "#333333!important",
    zIndex: "10000!important",
  },

  toolbar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  appbarLeft: {
    display: "flex",
    alignItems: "center",
  },

  menuButton: {
    marginLeft: 1,
  },

  hide: {
    display: "none",
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  file: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  rightImage: {
    display: "flex",
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  appBarRight: {
    display: "flex",
    alignItems: "center",
  },

  searchBar: {
    display: "flex",
    alignItems: "center",
    marginLeft: 150,
    width: "100%",
  },

  searchBarInput: {
    outline: "none",
    marginLeft: "10px",
    background: "transparent",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background:'#CC0E15'
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },

  drawerBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 0rem",
  },

  drawerBarContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },

  drawerBarLogo: {
    marginLeft: 10,
    cursor: "pointer",
  },

  drawerBarLinks: {
    marginTop: 50,
  },

  navList: {
    marginBottom: 20,
  },

  logoutIcon: {
    color: "#E6072C",
  },
}));

export const containerStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: `'Nunito Sans', sans-serif`,
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  content: {
    flexGrow: 1,
  },

  main: {
    padding: theme.spacing(3),
    backgroundColor: "#FCF9FA",
  },
}));

export const TableStyles = makeStyles((theme) => ({
  tableRoot: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
  },

  filterContainer: {
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(3),
    color: "#666666",
  },
  searchandfilter: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2.5rem",
  },
  tableBody: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
    padding: "50px",
  },
  tableHead: {
    backgroundColor: "#F5E9EB",
    color: "#E6072C",
    width: "100%",
  },
  tablePaginate: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  searchBar: {
    backgroundColor: "transparent",
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    marginRight: 5,
    padding: "5px 14px",
    outline: "none",
    color: "#999999",
  },
  searchInput: {
    backgroundColor: "transparent",
    marginLeft: 10,
    outline: "none",
  },
  filter: {
    backgroundColor: "#fff",
    border: "1px solid #E5E5E5",
    borderRadius: "0px 4px 4px 0px",
    padding: "5px 14px",
    display: "flex",
    alignItems: "center",
    outline: "none",
    color: "#4D4D4D",
  },
  statusIconActive: {
    backgroundColor: "#25B273",
    height: 8,
    width: 8,
    borderRadius: "50%",
    marginRight: "8px",
  },
  statusIconInactive: {
    backgroundColor: "#C4C4C4",
    height: 8,
    width: 8,
    borderRadius: "50%",
    marginRight: "8px",
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#251A6B",
          padding: 0,
        }
      : {
          color: theme.palette.text.primary,
        },

  title: {
    color: theme.palette.text.primary,
  },
  toolLabel: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
  },
  check: {
    padding: 0,
  },
  spanBorder: {
    border: "1px solid #E5E5E5",
    padding: "5px 10px",
    borderRadius: "4px 0px 0px 4px",
    display: "flex",
    alignItems: "center",
    margin: 0,
  },
}));
