import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getAuth, register } from "../../../store/auth";
import Loader from "../../common/Loader";
import styles from "./index.module.css";
import wellnessLogo from "../../../assets/images/wellness-logo.png";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import CustomFormControl from "../../common/FormControl";
import LeftSide from "../Auth/LeftSide";
import FormContainer from "../../Containers/FormContainer";
import AuthButton from "../../Containers/AuthButton";
import Form from "../../Containers/Form";
import { MdArrowBackIos, MdOutlineCancel } from "react-icons/md";
import { validateEmail } from "../../../utils/helper";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

let currentOTPIndex = 0;


function Otp() {
  const [email, setEmail] = useState({ value: "", invalid: false });
  const [firstName, setFirstName] = useState({ value: "", invalid: false });
  const [lastName, setLastName] = useState({ value: "", invalid: false });
  const [password, setPassword] = useState({ value: "", invalid: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", invalid: false });
  const [step, setStep] = useState(1);
  const params = useParams();
  const inputRef = useRef(null);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const user = localStorage.getItem('user');
  const myOtp = JSON.parse(localStorage.getItem('otp'));
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     history.push("/");
  //   }
  // }, [history]);

  const doSubmit = async (values) => {
    // await dispatch(register(values));
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);

    
  };

  const validInput = (value, prevData, setData) => {
    if (!value.trim()) {
      const newData = { ...prevData };
      newData.invalid = true;
      setData(newData);
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newOtp = [];
    otp.map((d,index) => {
      newOtp.push(Number(d));
    });
    

    if(myOtp == Number(newOtp.join(''))) {

      toast.success('verified successfully',{
        position:"bottom-right"
      });
      setTimeout(() => {
        localStorage.removeItem("otp");
        setIsLoading(false);
        history.push("/");
      }, 2000);
    } else {
      toast.error('verification failed',{
        position:"bottom-right"
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }

    
  };


  const handleOnChange = ({target},index) => {
    const {value} = target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);  
    
    if(!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);     
    setOtp(newOTP);
  }

useEffect(() => {
    inputRef.current?.focus();
    console.log(otp.join(""));
},[activeOTPIndex]);


  const handleSetValues = (prevValues, newValue, setFn) => {
    const newData = { ...prevValues };
    newData.value = newValue;
    newData.invalid = false;
    setFn(newData);
  };
  const handleOnKeyDown = ({key},index) => {
    currentOTPIndex = index;
    if(key == 'Backspace') setActiveOTPIndex(currentOTPIndex - 1);
}
  return (
    <div className="bg-white w-full">
      <div className={styles["login-screen"]}>
        <Loader visible={loading} />
        {/* left */}
        <LeftSide />
        {/* right */}
        <div className={styles["right-container"]}>
          <FormContainer className={styles["form-container"]}>
            <div className="flex w-full items-center px-[3rem]">
              <MdArrowBackIos className="cursor-pointer text-[20px] font-bold" onClick={() => history.goBack() } />
              <div className="w-full flex justify-center">
                <img className={styles["form-logo"]} src={wellnessLogo} alt="wellness" />
              </div>
            </div>
            <h1 className={styles["form-title"]}>
              Input your OTP sent to your email
            </h1>
            <Form className={styles["form"]} onSubmit={handleRegister}>
              <div className={styles["form-control"]}>
                <div className="flex items-center justify-center space-x-2 mt-[9px]">
                  {otp.map((_, index) => {
                      return (
                      <React.Fragment key={index}>
                        <input
                          ref={index == activeOTPIndex ? inputRef : null}
                          type="number"
                          className="w-[43px] h-[42px] border rounded-[16px] bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-[#D0D5DD] focus:border-[#F04438] focus:text-gray-700 text-gray-400 transition"
                          onChange={handleOnChange}
                          onKeyDown = {(e) => handleOnKeyDown(e,index)}
                          value={otp[index]}
                        />
                      </React.Fragment>
                      );
                  })}
                </div>
              </div>
              <div className="flex justify-between"></div>
              <div>
                <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
                <div className="flex items-center mt-3 justify-center"></div>
              </div>
            </Form>
          </FormContainer>
        </div>
      </div>
    </div>
    
  )
}

export default Otp