import moment from "moment";
const colors = ["#8a3ea7", "#2b84d2", "#b02621", "#de352f"];

export const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

export const getIntialsFromString = (str) => {
  if (!str || typeof str !== "string") return str;
  const names = str.split(" ");
  return names[0][0] + names[1][0];
};

export const getFormatedDate = (date, format = "MMM DD, YYYY") => moment(date).format(format);

export const BUY_PLAN_STEPS_TITLE = {
  CREATE_YOUR_PLAN: "Create your Plan",
  BIODATA: "Biodata",
  DEPENDENT: "Dependents",
  MEDICAL_SERVICES: "Medical Services",
  MEDICAL_SERVICES_BENEFITS: "Medical Services ( Benefits )",
};

export const DENTAL_CARE_STEPS = {
  STEP_ONE: "Create Dental Care",
  STEP_TWO: "Other One",
  STEP_THREE: "Other Two",
};

export const OPTICAL_CARE_STEPS = {
  STEP_ONE: "Create Optical Care",
  STEP_TWO: "Other One",
};

export const BUY_PLAN_URLS = {
  CREATE_YOUR_PLAN: "/dashboard/wellnesshmo/buy-your-plan/create-your-plan",

  BIODATA: "/dashboard/wellnesshmo/buy-your-plan/biodata",

  DEPENDENT: "/dashboard/wellnesshmo/buy-your-plan/dependents",

  MEDICAL_SERVICES: "/dashboard/wellnesshmo/buy-your-plan/medical-services",

  MEDICAL_SERVICES_BENEFITS: "/dashboard/wellnesshmo/buy-your-plan/medical-services-benefits",
};

export const DENTAL_CARE_URLS = {
  CREATE_DENTAL_CARE_PLAN: "/dashboard/medical-checkup/dental-care/create-dental-care",

  OTHER_SERVICES_ONE: "/dashboard/medical-checkup/dental-care/other-services-one",

  OTHER_SERVICES_TWO: "/dashboard/medical-checkup/dental-care/other-services-two",
};

export const OPTICAL_CARE_URLS = {
  CREATE_DENTAL_CARE_PLAN: "/dashboard/medical-checkup/optical-care/create-optical-care",

  OTHER_SERVICES_ONE: "/dashboard/medical-checkup/optical-care/other-services-one",
};

export const VALID_SERVICES = [
  "ambulance service",
  "type of care",
  "specialist conduct?",
  "diagnostics",
  "immunization status",
  "dermatologist",
  "orthopedic-surgeon",
];

export const OPTICAL_SERVICES = [
  "dilation",
  "slit lamp examination (gonioscopy/tonometry)",
  "refraction",
  "central visual field (cvf)",
  "indirect ophthalmoscopy",
  "pachymetry",
  "tonometry",
  "intraoccular pressure (i.o.p)",
];

export const DENTAL_SERVICES = [
  "peri-apical",
  "occlusal",
  "biteview",
  "temporary fillings",
  "composite fillings",
  "scaling and polishing",
  "desensitization",
];
export const DENTAL_SERVICES2 = [
  "Quadrant/Subgingival, Scaling/Curettage",
  "Root canal therapy (pulpectomy)",
  "Gingivectomy",
  "Operculectomy",
  "Minor surgical extraction (non-impacted)",
  "Impacted third molar surgery",
  "Repositioning & Splinting",
];
export const STEPS = [
  {
    title: BUY_PLAN_STEPS_TITLE.CREATE_YOUR_PLAN,
    description: "We need the details below to create the best plan for you",
    isActive: true,
    activeBorder: "border-inactive",
    show: true,
  },
  {
    title: BUY_PLAN_STEPS_TITLE.BIODATA,
    description: "We need the details to get to know you better",
    isActive: false,
    activeBorder: "border-inactive",
    show: true,
  },
  {
    title: BUY_PLAN_STEPS_TITLE.DEPENDENT,
    description: "We need a full list of your dependents to create a plan for them",
    isActive: false,
    activeBorder: "border-inactive",
    show: false,
  },
  {
    title: BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES,
    description: "Create the desired medical benefits based on your needs",
    isActive: false,
    activeBorder: "border-inactive",
    show: true,
  },
  {
    title: BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES_BENEFITS,
    description: "We need the details to know the type of medical services you would like to create",
    isActive: false,
    activeBorder: "border-inactive",
    show: true,
  },
];

export const EXCLUDE_DEPENDENT_FIELD_NAME = [
  "Name of first dependent",
  "Name of second dependent",
  "Name of third dependent",
  "Name of fourth dependent",
];
