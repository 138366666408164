import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useHistory } from "react-router";
import { getAuth, register } from "../../../store/auth";
import Loader from "../../common/Loader";
import styles from "./index.module.css";
import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";
import CustomFormControl from "../../common/FormControl";
import LeftSide from "../Auth/LeftSide";
import FormContainer from "../../Containers/FormContainer";
import AuthButton from "../../Containers/AuthButton";
import Form from "../../Containers/Form";
import { useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";



function EnrollDetail() {
  const enrolleeDetail = JSON.parse(localStorage.getItem('enrollee'));
  const [enrolleeId, setEnrolleeId] = useState({ value: enrolleeDetail?.insurance_no, invalid: false });
  const [client, setClient] = useState({ value: enrolleeDetail?.company?.name, invalid: false });
  const [email, setEmail] = useState({ value:enrolleeDetail?.email, invalid: false });
  const [firstName, setFirstName] = useState({ value: enrolleeDetail?.firstname, invalid: false });
  const [lastName, setLastName] = useState({ value: enrolleeDetail?.lastname, invalid: false });
  const [password, setPassword] = useState({ value: "", invalid: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: enrolleeDetail?.phone, invalid: false });
  const [planType, setPlanType] = useState({ value: enrolleeDetail?.plan?.name, invalid: false });
  const [date, setDate] = useState({ value: "", invalid: false });
  const [step, setStep] = useState(1);
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }else if(!localStorage.getItem("enrollee")) {
      history.push("/");
    }
  }, [history]);

  // const doSubmit = async (values) => {
  //   await dispatch(register(values));
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 2000);
  // };

  const validInput = (value, prevData, setData) => {
    if (!value.trim()) {
      const newData = { ...prevData };
      newData.invalid = true;
      setData(newData);
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    setLoading(true);
    history.push('/register/nonenrollee');    
  };


  return (
    <div className={styles["login-screen"]}>
      <Loader visible={loading} />
      {/* left */}
      <LeftSide />
      {/* right */}
      <div className={styles["right-container"]}>
        <FormContainer className={styles["form-container"]}>
          <div className="flex w-full items-center px-[3rem]">
            <MdArrowBackIos className="cursor-pointer text-[20px] font-bold" onClick={() => history.push('/register/enrollee') } />
            <h2 className="text-[#E6072C] w-full flex justify-center font-bold text-[20px] md:text-[24px]">Verify Enrollee Details</h2>
          </div>
          <h1 className={styles["form-title"]}>
            Verify your enrollee ID Details
          </h1>
          <Form className={styles["form"]} onSubmit={handleRegister}>
            <div className={styles["form-control"]}>
              <CustomFormControl styles={{ mt: 0 }}>
                <TextField
                  disabled
                  id="first-name"
                  label="Enrollee Id"
                  variant="outlined"
                  error={enrolleeId.invalid}
                  value={enrolleeId.value}
                  
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                  disabled
                  id="first-name"
                  label="Name"
                  variant="outlined"
                  error={firstName.invalid}
                  value={firstName.value+ ' ' + lastName.value}
                  
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                  disabled
                  id="fit-name"
                  label="Email"
                  variant="outlined"
                  error={email.invalid}
                  value={email.value}
                  
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                  disabled
                  id="client"
                  label="Client"
                  variant="outlined"
                  error={client.invalid}
                  value={client.value}
                  
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                disabled
                  id="first-name"
                  label="Plan Type"
                  variant="outlined"
                  error={planType.invalid}
                  value={planType.value}
                />
              </CustomFormControl>
            </div>
            <div className="flex justify-between"></div>
            <div>
              <AuthButton className="bg-[#EC4D67]">Verify Details</AuthButton>
              <div className="flex items-center mt-3 justify-center">
                <p className="mr-3">Not your information?</p>
                <Link to="/contact-us" variant="body2">
                  Chat With us
                </Link>
              </div>
            </div>
          </Form>
        </FormContainer>
      </div>
    </div>
  )
}

export default EnrollDetail