import React, { useEffect, useState } from "react";

import styles from "./index.module.css";
import FormContainer from "../../../../../../Containers/FormContainer";
import CancerScreeningCheckUp from "./CancerScreeningCheckUp";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchHospitalLocation } from "../../../../../../../store/hospitals";
import { getInsuranceCalculator, stopLoading } from "../../../../../../../store/insuranceCalculator";
import { getPlanSteps, updateSteps } from "../../../../../../../store/steps";
import { addAmount, getUserInfo, setPriceStat } from "../../../../../../../store/userData";

import ModalLoader from "../../../../../../common/Loader";
import Alert from "../../../../../../common/Alert";
import axios from "axios";
import HospitalLocation from "../HospitalLocation";
import usePaystack from "../../../../../../hooks/usePaystack";
import { toast } from "react-toastify";
import { CombineDateAndTime, fetchPromo, postBuyOther } from "../../../../../../../utils/helper";

const CancerScreening = () => {
  const { values } = useSelector(getPlanSteps);
  const token = localStorage.getItem("token");
  const [cLoad, setCload] = useState(false);
  const [allField, setAllField] = useState([]);
  const [allValue, setAllValue] = useState([]);
  const [step, setStep] = useState(1);
  const [gender, setGender] = useState('');
  const [cancerData, setCancerData] = useState({
      cost:'',
      screenings: []
  });

  const [sex, setSex] = useState(null);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const { loading, error } = useSelector(getInsuranceCalculator);
  const [screeningData, setScreeningData] = useState([]);
  const [service, setService] = useState([]);
  const [amount, setAmount] = useState(null);
  const [pickedHospital, setPickedHospital] = useState({});
  const [appointmentDate, setAppointmentDate] = useState('');
  const [time, setTime] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [buyOther, setBuyOther] = useState(null);
  const  [promo, setPromo] = useState('');
  const { amount_paid } = useSelector(getUserInfo);

  useEffect(() => {
    fetchPromo(setPromo);
}, [promo]);

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const handlePayment = usePaystack();
  const postCancer = async (newData) => {
    try {
      const {data} = axios.post(`${process.env.REACT_APP_API_URL}/v2/cancer/create`,
      newData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      }
      );
    toast.success('Appointment created successfully',{
      position:"bottom-right"
    });
    dispatch(addAmount(0));
    history.push('/dashboard/medical-checkup');
    }catch(err) {
      console.log(err);
    }
    
  }

  const onSuccess = (reference) => {
    let newdate = CombineDateAndTime(appointmentDate,time);
    let currentField = [];
    let currentValue = [];

    service.map((d,index) => {
        currentField.push(d.field);
        currentValue.push(d.ans);
    })
    if(buyOther == 'myself'){
      const allData =  {
        services:gender,
        service_name: "cancer",
        transaction_id: reference.trxref,
        amount_paid: amount_paid,
        hospital_name: pickedHospital.name,
        hospital_location: pickedHospital.location,
        hospital_address:pickedHospital.address,
        appointment_date: newdate,
        doctor_name: "",
        comment: "",
        has_promo: false
        }
        postCancer(allData);
    } else if (buyOther == 'other') {
      let obj = {
        request_type: gender,
        data: {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: email,
        request_type: gender,
        service_name: gender,
        services: gender,
        amount_paid: amount_paid,
        hospital_name: pickedHospital?.name,
        hospital_location: pickedHospital?.location,
        hospital_address: pickedHospital?.address,
        doctor_name: "",
        comment: "",
        appointment_date: newdate,
        has_promo: promo ? true : false,
        transaction_id: reference.trxref
        }
    }
    postBuyOther(obj,toast,history);
    }
   
    window.location.href('/dashboard');
    Alert.notication("success", "Subscription", "subscription successful");
    // Implementation for whatever you want to do with reference and after success call.
    // dispatch(logout());
    window.location.href('/dashboard')
    // setTimeout(() => {
    //      window.location.href('/dashboard');
    // }, 2000);
    console.log(reference);
};

  const handlePatientSubmit = (e) => {
    e.preventDefault();
    service.map((d,index) => {
      setAllField([...allField, d.field]);
      setAllValue([...allValue, d.ans]);
    })
    if(time == '') {
      toast.error('Select time of appointment',{
        position:'bottom-right'
      })
     }else {
      dispatch(setPriceStat(false));
      handlePayment(onSuccess, onClose);
    }
  };
  const onChange = (time, timeString) => {
    setTime(timeString);
  };
  return (
  <>
    {step == 1 && 
      <div className={styles["plan-wrapper"]}>
        <div className="mb-10 flex items-center">
          <button className={styles["back-btn"]} onClick={() => history.goBack()}>
            <IoArrowBackOutline />
          </button>
          <p className="ml-1.5">Cancer Screening</p>
        </div>
        <div className={styles["container"] + 'block lg:flex justify-between'}>
          <ModalLoader visible={loading} />
          <div className={styles["title-container"]}>
            <p className={styles["title"]}>Cancer Screening</p>
            <p className={styles["sub-title"]}>These are services offered for cancer treatment </p>
          </div>
          <FormContainer className={styles["form-container"]}>
            <CancerScreeningCheckUp 
              firstName={firstName} 
              setFirstName={setFirstName}
              lastName={lastName} 
              setLastName={setLastName}
              phone={phone} 
              setPhone={setPhone}
              email={email} 
              setEmail={setEmail}
              promo={promo} 
              setPromo={setPromo}  
              buyOther={buyOther}
              amount={amount}
              setBuyOther={setBuyOther}
              setGender={setGender} setService={setService} setAmount={setAmount} setStep={setStep} allField={allField} allValue={allValue} setAllValue={setAllValue} setAllField={setAllField} />
          </FormContainer>
        </div>
      </div>
      }
      {
        step == 2 && 
        <HospitalLocation  handlePatientSubmit={handlePatientSubmit}  pickedHospital={pickedHospital} setPickedHospital={setPickedHospital} setAppointmentDate={setAppointmentDate} onChange={onChange} amount={amount} promo={promo} />
      }
  </>  
  );
};

export default CancerScreening;
