import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "opticalCare",
  initialState: {
    opticalValue: [],
    loading: false,
  },
  reducers: {
    fetchedOpticalCare: (data, { payload }) => {
      data.loading = false;
      data.opticalValue = payload.data.dental_optical_primary_care;
    },
    fetchedDataStarted: (data) => {
      data.loading = true;
    },
    fetchedFailed: (data) => {
      data.loading = false;
    },
    resetOptical: (data) => {
      data = {
        opticalValue:[],
        loading:false
      }
    }
  },
});

const { fetchedDataStarted, fetchedFailed, fetchedOpticalCare ,resetOptical} = slice.actions;

export default slice.reducer;

const opticalUrl = "dental-optical/care/optical";

export const fetchOpticalCare = () =>
  apiCallBegan({
    url: `${opticalUrl}`,
    method: "GET",
    baseUrl: `${process.env.REACT_APP_API_URL}/v2/`,
    onSuccess: fetchedOpticalCare.type,
    onStart: fetchedDataStarted.type,
    onError: fetchedFailed.type,
  });

export const getOpticalCare = (state) => state.entities.opticalCare;

export const resetOpticalCare = (state) => (dispatch) =>
dispatch({type: resetOptical.type })