import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        loading: false,
        isAuthenticated: !!localStorage.token,
    },
    reducers: {
        userLogin: (data, { payload }) => {
            data.loading = false;
            data.user = payload.payload;
            data.isAuthenticated = true;
            // set local storage her
            localStorage.setItem("enrollee",JSON.stringify({
                insurance_no: payload.enrollee_id
            }));
            localStorage.setItem("is_enrollee",JSON.stringify(payload.is_enrollee));
            localStorage.setItem("token", payload.token);
            localStorage.setItem("email", payload.email);
            localStorage.setItem("user", JSON.stringify(payload.payload));
        },

        userRegistered: (data, { payload }) => {
            data.loading = false;
            data.user = payload.data.user;
            data.isAuthenticated = true;
            // set local storage here
            console.log(payload);
            //localStorage.setItem("user", JSON.stringify(payload.data.user));
            //localStorage.setItem("token", payload.data.token);
            localStorage.setItem("otp", JSON.stringify(payload.data.otp));
            localStorage.setItem("reg",true);
        },

        authStarted: (data) => {
            data.loading = true;
        },
        authFailed: (data) => {
            data.loading = false;
        },

        userLogout: (data) => {
            data.admin = null;
            data.isAuthenticated = false;
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("is_enrollee");
            localStorage.removeItem("enrollee");
            localStorage.removeItem("benefits");
            localStorage.removeItem("enrolleeotp");
            localStorage.removeItem("otp");
            localStorage.removeItem("email");
        },

        forgotPassword: (data, action) => {
            data.loading = false;
        },

        resetCodeSuccess: (data, action) => {
            data.loading = false;
        },

        updatePasswordSuccess: (data, action) => {
            data.loading = false;
        },

        resetPasswordStart: (data, action) => {
            data.loading = true;
        },

        resetPasswordFailed: (data, action) => {
            data.loading = false;
        },
    },
});

const {
    userLogin,
    userRegistered,
    authStarted,
    authFailed,
    userLogout,
    forgotPassword,
    resetCodeSuccess,
    updatePasswordSuccess,
    resetPasswordStart,
    resetPasswordFailed,
} = slice.actions;

export default slice.reducer;

export const logout = () => (dispatch) => dispatch({ type: userLogout.type });

export const login = (data) =>
    apiCallBegan({
        url: "/login",
        method: "POST",
        data,
        onSuccess: userLogin.type,
        onStart: authStarted.type,
        onError: authFailed.type,
    });

export const register = (data) =>
    apiCallBegan({
        url: "/register",
        method: "POST",
        data,
        onSuccess: userRegistered.type,
        onStart: authStarted.type,
        onError: authFailed.type,
    });

export const resetPasswordAdmin = (email) =>
    apiCallBegan({
            url: `/forgot-pwd?email=${email}`,
            method: "POST",
            onSuccess: forgotPassword.type,
            onStart: resetPasswordStart.type,
            onError: resetPasswordFailed.type,
        },
        localStorage.setItem("admin_email", email)
    );

export const resetPasswordCode = (reset_code) =>
    apiCallBegan({
        url: `/verify-reset-pwd-code`,
        method: "POST",
        params: {
            reset_code,
            email: localStorage.getItem("admin_email"),
        },
        onSuccess: resetCodeSuccess.type,
        onStart: resetPasswordStart.type,
        onError: resetPasswordFailed.type,
    });

export const updatePassword = (password) =>
    apiCallBegan({
        url: `/update-pwd`,
        method: "POST",
        params: {
            password,
            email: localStorage.getItem("admin_email"),
        },
        onSuccess: updatePasswordSuccess.type,
        onStart: resetPasswordStart.type,
        onError: resetPasswordFailed.type,
    });

export const getUser = (state) => state.auth.user;

export const getAuth = (state) => state.auth;