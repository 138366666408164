import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import file from "../../../../assets/images/file.svg";
import dependents from '../../../../assets/images/dependents.svg';
import hospital1 from '../../../../assets/images/hospital-fill.svg';
import video from '../../../../assets/images/video-camera.svg';
import barcode from '../../../../assets/images/barcode-box.svg';
import profile from '../../../../assets/images/profile-fill.svg';
import discuss from '../../../../assets/images/discuss-fill.svg';
import Card from "../../../common/Card";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Dependents from "../widgets/Dependents";
import { IoArrowBackOutline } from "react-icons/io5";
import AllPlans from "../widgets/AllPlans";
import { useEffect } from "react";
import medicine from '../../../../assets/images/medicine.svg';
import { useLocation } from "react-router-dom";
import { enrolleeDetailAPI } from "../../../../utils/apis";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addAmount } from "../../../../store/userData";

const WellnessHmo = () => {
  const baseUrl = "/dashboard";
  const params = useParams();
  const history = useHistory();
  let isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
  isEnrollee = isEnrollee?.insurance_no;
  const routePath = useLocation();
  const [status , setStatus] = useState(null);
  const token = localStorage.getItem("token");
  const [eStatus, setEStatus] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addAmount(0));
  },[])

  const fetchEnroll = async() => {
    
      try {
        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/users/enrollees/retrieve`,
        {
          insurance_no: isEnrollee
          
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':`${token}`
          }
        },
        )
          setEStatus(data?.data?.enrollee?.status);
        
      }catch(err) {
        console.log(err);
      }
    
    
  };

  useEffect(() => {
    fetchEnroll();
  },[eStatus]);
  

  const onTop = () => {
      window.scrollTo(0, 0);
  }
  useEffect(() => {
      onTop()
  }, [routePath]);

  return (
    <div className="px-[36px] bg-[#ffffff]">
      <div className="mb-[10px] lg:mb-10 flex items-center py-[20px]">
        {params.step && (
          <>
            <button className="bg-[#4b3bae] text-white p-[0.2em] rounded-[50%] cursor-pointer" onClick={() => history.goBack()}>
              <IoArrowBackOutline />
            </button>
            <p className="ml-1.5">Back</p>
          </>
        )}
      </div>

      {
        params.step == 'dashboard/dependents' ?
        <Dependents /> :
        params.step == 'dashboard/plans' ?
        <AllPlans /> : 
        <div className="h-[243px] flex justify-around items-center py-[40px] px-[20px] w-full md:w-[60%] bg-[#DBD5FC] rounded-[4px] mt-[20px] mb-[50px]">
          {
            isEnrollee ?
            <div>
              <div className="text-[18px] font-bold">You’re covered</div>
              <div>Subscription status</div>
              <div>{eStatus}</div>
              <div className="mt-[53px]">
                <div>Enrollee ID</div>
                <div>{isEnrollee}</div>
              </div>
              <div className="flex items-center cursor-pointer" onClick={() => {
                if(isEnrollee) {
                return history.push(`${baseUrl}/allbenefits`);
                }else {
                  return history.push(`${baseUrl}/wellnesshmo/buy-your-plan`);
                }
              }}>
                <span>View my plan and benefits</span>
                <FaAngleRight />
              </div>
          </div>:
          <div>
            <div className="text-[18px] font-bold">You’re not covered</div>
            <div>Kindly Subscribe to access care</div>
            
            <div className="flex items-center cursor-pointer" onClick={() => {
              if(isEnrollee) {
              return history.push(`${baseUrl}/allbenefits`);
              }else {
                return history.push(`${baseUrl}/wellnesshmo/verifyId`);
              }
            }}>
              <span>Verify Enrollee ID</span>
              <FaAngleRight />
            </div>
          </div>
          }
          <div>
            <img src={file} width="100px" height="100px" />
          </div>
        </div>
      }
      <div>
        <div className="text-[#0F0B26] font-bold">Our Offers</div>
        <div className="text-[#918F9F]">Get an overview of what you can do with Wellness</div>
        <div className="grid gap-2 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3">
          <Card
            title={"See our partner"}
            subtitle={"View our partner"}
            image={hospital1}
            link={`${baseUrl}/see-partner`}
          />
          {
            isEnrollee ? 
            <Card
              title={"Telemedicine"}
              subtitle={"speak to a doctor from the comfort of your home"}
              image={video}
              link={`${baseUrl}/telemedicine`}
            />
            : 
            <Card
              title={"Let's talk"}
              subtitle={"We are here should you need help"}
              image={video}
              link={`${baseUrl}/contact`}
            />
          }
          
          {
            isEnrollee && 
            <Card
              title={"Request for monthly drug refill"}
              subtitle={"Out of drugs? You can easily request for a refill here"}
              image={medicine}
              link={`${baseUrl}/requestrefill`}
            />
          }
          {
            isEnrollee &&
            <Card
              title={"Request for Secondary Care"}
              subtitle={"Want a benefit that you aren't covered for? Don't worry we got you"}
              image={barcode}
              link={`${baseUrl}/medical-checkup`}
            />
          }
          {
            isEnrollee &&
            <Card
              title={"Dependents"}
              subtitle={"View a list of all your dependents"}
              image={dependents}
              link={`${baseUrl}/dependents`}
            />
          }
        </div>
      </div>
      <div>
        <div className="text-[#0F0B26] font-bold">Self-service</div>
        <div className="text-[#918F9F]">Make your request, we are here to grant them</div>
        <div className="grid gap-2 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3">
          <Card
            title={"Book Hospital Visit"}
            subtitle={"Access our health coverage at a  hospital near to you."}
            image={hospital1}
            link={isEnrollee ? `${baseUrl}/hospitalvisit`: `${baseUrl}/wellnesshmo/buy-your-plan`}
          />
          <Card
            title={"Request Authorization code"}
            subtitle={"Get a code and present it to the hospital."}
            image={barcode}
            link={isEnrollee ? `${baseUrl}/authcode`: `${baseUrl}/wellnesshmo/buy-your-plan`}
          />
          <Card
            title={"Request HMO Card"}
            subtitle={"Request to get a new card the easy way."}
            image={profile}
            link={isEnrollee ? `${baseUrl}/hmocard`: `${baseUrl}/wellnesshmo/buy-your-plan`}
          />
          <Card
            title={"Contact Us"}
            subtitle={"Access custom health plans we have available"}
            image={discuss}
            link={`${baseUrl}/contact`}
          />
          <Card
            title={"Add Dependents"}
            subtitle={"Add a dependant"}
            image={dependents}
            link={isEnrollee ?`${baseUrl}/adddependant`: `${baseUrl}/wellnesshmo/buy-your-plan`}
          /> 
        </div>
      </div>
    </div>
  )
};

export default WellnessHmo;
