import { combineReducers } from "redux";
import insuranceCalulatorReducer from "../insuranceCalculator";
import hospitalReducer from "../hospitals";
import dentalCareReducer from "../dentalCare";
import opticalCareReducer from "../opticalCare";
import userInfoReducer from "../userData";
import stepsReducer from "../steps";

// entites contains all the reducers for the application entities such as hmo, enrollee, etc.
export default combineReducers({
  insuranceCalculator: insuranceCalulatorReducer,
  hospitals: hospitalReducer,
  dentalCare: dentalCareReducer,
  opticalCare: opticalCareReducer,
  userInfo: userInfoReducer,
  steps: stepsReducer,
});
