import React, { useEffect} from "react";
import { useHistory } from "react-router";
import styles from "./index.module.css";
import LeftSide from "../Auth/LeftSide";
import Contact from "../Contact";


function ContactUs() {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history]);



  return (
    <div className={styles["login-screen"]}>
      {/* left */}
      <LeftSide />
      {/* right */}
      <div className={styles["right-container"]}>
        <Contact size={true} />
      </div>
    </div>
  )
}

export default ContactUs