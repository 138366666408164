import React from "react";
import styles from "./index.module.css";

const Form = ({ className = "", children, onSubmit = () => {} }) => {
  return (
    <form className={`${className} ${styles["form"]}`} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
