import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IoArrowBackOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaTooth } from "react-icons/fa";
import { FaHeartbeat } from "react-icons/fa";
import { GiDoctorFace } from "react-icons/gi";
import { GiBrain } from "react-icons/gi";
import DoctorList from './DoctorList';
import axios from 'axios';



function BookDoctor() {
  const history = useHistory();
  const [specialization, setSpecialization] = useState('');

  const fetchSpecialization = async() => {
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v1/specialization`);
      setSpecialization(data?.banks);
    }catch(err) {
      //err
    }
  }

  useEffect(() => {
    fetchSpecialization();
  },[specialization])

  
  return (
    <div className='bg-white min-h-[100vh] pb-[100px]'>
      <div className="mb-2 flex items-center">
        <button className="" onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Back</p>
      </div>
      <div>
        <div className='mb-10 items-center px-5 flex flex-col gap-2'>
          <h2 className='font-bold text-2xl tracking-wide'>Search <span className='text-primary'>Doctors</span></h2>
          <h2 className='text-gray-500 text-[18px]'>Search Your Doctor and Book Appointment in one click</h2>
          <div className="flex w-full mt-3 max-w-sm items-center space-x-2">
            <input type="text" placeholder="Search..." />
            <Button type="submit">
              Search
            </Button>
          </div>
          {/* Display List of Category  */}
          <div className='grid grid-cols-3 mt-5 md:grid-cols-4 lg:grid-cols-6 '>
            <Link href={'/search/'} className='flex flex-col text-center items-center p-5 bg-blue-50 m-2 rounded-lg cursor-pointer gap-2 hover:scale-110 transition-all ease-in-out'>
              <FaTooth />
              <label className='text-blue-600 text-sm'>Dental</label>
            </Link>
            <Link href={'/search/'} className='flex flex-col text-center items-center p-5 bg-blue-50 m-2 rounded-lg cursor-pointer gap-2 hover:scale-110 transition-all ease-in-out'>
              <FaHeartbeat />
              <label className='text-blue-600 text-sm'>Cardiologist</label>
            </Link>
            <Link href={'/search/'} className='flex flex-col text-center items-center p-5 bg-red-100 m-2 rounded-lg cursor-pointer gap-2 hover:scale-110 transition-all ease-in-out'>
              <GiDoctorFace color="red" />
              <label className='text-blue-600 text-sm'>General Doctor</label>
            </Link>
            <Link href={'/search/'} className='flex 
              flex-col text-center items-center
              p-5 bg-blue-50 m-2 rounded-lg cursor-pointer
              gap-2 hover:scale-110 transition-all ease-in-out'>
              <GiBrain />
              <label className='text-blue-600 text-sm'>Neurologist</label>
            </Link>
          </div>
        </div>
        <div className='mb-10 px-8'>
          <h2 className='font-bold text-xl'>Popular Doctor</h2>
          <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-7 mt-4 lg:grid-cols-4'>
            <DoctorList />
            <DoctorList />
            <DoctorList />
            <DoctorList />
            <DoctorList />
            <DoctorList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookDoctor