import React, { useEffect, useState } from "react";

import styles from "./index.module.css";
import FormContainer from "../../../../../../Containers/FormContainer";

import BasicHealthCheckup from "./BasicHealthCheckup";

import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { getInsuranceCalculator } from "../../../../../../../store/insuranceCalculator";
import { getPlanSteps } from "../../../../../../../store/steps";
import { addAmount, getUserInfo, setPrice } from "../../../../../../../store/userData";

import ModalLoader from "../../../../../../common/Loader";
import axios from "axios";
import { fetchPromo } from "../../../../../../../utils/helper";

const DentalCare = () => {
  const { values } = useSelector(getPlanSteps);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading} = useSelector(getInsuranceCalculator);
  const [load, setLoad] = useState(false);
  const [basicData, setBasicData] = useState([]);
  const [cost, setCost] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [buyOther, setBuyOther] = useState(null);
  const  [promo, setPromo] = useState('');
  const { amount_paid } = useSelector(getUserInfo);

  useEffect(() => {
    dispatch(addAmount(0));
    fetchPromo(setPromo);
}, [promo]);
  
  const fetchBasicCheck = async() => {
    setLoad(true);
    try {
      const {data} = await axios.get(`https://api.wellnessplus.io/api/v2/comprehensive/care/bc`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      });

     console.log(data);
     let newData = [];

      data?.data?.screenings?.values?.map((d,index) => {
        newData.push({ 
            name: data?.data?.screenings?.names[index],
            value: d
          })
        
      });
     let AllName =  data?.data?.screenings?.names?.map((d,index) => {
          return d;
        
      });
      let newAllName = JSON.stringify(AllName);
      //setServiceData(newAllName);
      setLoad(false);
      setBasicData(newData);
      console.log(promo);
      setCost(data.data.cost );
      let charge = (data.data.cost - (promo/100 * data.data.cost));
     console.log(charge);
     console.log(promo/100 * data.data.cost);
      dispatch(addAmount(charge));
      
      dispatch(setPrice(charge));
      
    }catch(err) {
      console.log(err);
    }
  }
  useEffect(() => {
    
    fetchBasicCheck();
  },[promo]);
  return (
    <div className={styles["plan-wrapper"]}>
      <div className="mb-10 flex items-center">
        <button className={styles["back-btn"]} onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Basic Health Checkup</p>
      </div>
      <div className={styles["container"] + 'block lg:flex'}>
        <ModalLoader visible={loading} />
        <div className={styles["title-container"]}>
          <p className={styles["title"]}>Basic Health Checkup</p>
          <p className={styles["sub-title"]}>
            These are services offered to people who would like to know a status of their health
          </p>
        </div>
        <FormContainer className={styles["form-container"]}>
          {load ? <div className="p-[20px]">Loading....</div> : 
          <BasicHealthCheckup 
            firstName={firstName} 
            setFirstName={setFirstName}
            lastName={lastName} 
            setLastName={setLastName}
            phone={phone} 
            setPhone={setPhone}
            email={email} 
            setEmail={setEmail}
            promo={promo} 
            setPromo={setPromo}  
            buyOther={buyOther}
            setBuyOther={setBuyOther}
            healthData={basicData} 
            cost={cost} />}
        </FormContainer>
      </div>
    </div>
  );
};

export default DentalCare;
