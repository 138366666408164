import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from "./index.module.css";
import { IoArrowBackOutline } from 'react-icons/io5';
import { ModalLoader } from '../../../common';
import { Link } from 'react-router-dom';
import { AuthButton, Form } from '../../../Containers';



let currentOTPIndex = 0;

function VerifyOtp() {
  const [email, setEmail] = useState({ value: "", invalid: false });
  const [firstName, setFirstName] = useState({ value: "", invalid: false });
  const [lastName, setLastName] = useState({ value: "", invalid: false });
  const [password, setPassword] = useState({ value: "", invalid: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", invalid: false });
  const [step, setStep] = useState(1);
  const params = useParams();
  const inputRef = useRef(null);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const user = localStorage.getItem('user');
  const myOtp = JSON.parse(localStorage.getItem('otp'));
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRegister = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newOtp = [];
    otp.map((d,index) => {
      newOtp.push(Number(d));
    });
    

    if(myOtp == Number(newOtp.join(''))) {
      toast.success('verified successfully',
      {
        position:"bottom-right"
      }
    );
      setTimeout(() => {
        setIsLoading(false);
        history.push("/");
      }, 2000);
    } else {
      toast.error('verification failed',{
        position:"bottom-right"
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }

    
  };

  const handleOnChange = ({target},index) => {
    const {value} = target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);  
    
    if(!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);     
    setOtp(newOTP);
  }

useEffect(() => {
    inputRef.current?.focus();
    console.log(otp.join(""));
},[activeOTPIndex]);


  const handleSetValues = (prevValues, newValue, setFn) => {
    const newData = { ...prevValues };
    newData.value = newValue;
    newData.invalid = false;
    setFn(newData);
  };

  const handleOnKeyDown = ({key},index) => {
    currentOTPIndex = index;
    if(key == 'Backspace') setActiveOTPIndex(currentOTPIndex - 1);
  }
  return (
    <div className={styles["plan-wrapper"]}>
            <div className="mb-10 flex items-center">
              <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                <IoArrowBackOutline />
              </button>
              <p className="ml-1.5">Back</p>
            </div>
              <div className={styles["container"] + 'block lg:flex lg:justify-between'}>
                <ModalLoader visible={loading} />
                <div className={styles["title-container"]}>
                    <p className={styles["title"]}>Verify OTP</p>
                    <p className={styles["sub-title"]}>
                        Please fill the form to proceed.
                    </p>
                </div>
                <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2 p-4'>
                    <Form className={styles["form"]} onSubmit={handleRegister}>
                        <div className={styles["form-control"]}>
                          <div className="flex items-center justify-center space-x-2 mt-[9px]">
                            {otp.map((_, index) => {
                                return (
                                <React.Fragment key={index}>
                                  <input
                                    ref={index == activeOTPIndex ? inputRef : null}
                                    type="number"
                                    className="w-[43px] h-[42px] border rounded-[16px] bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-[#D0D5DD] focus:border-[#F04438] focus:text-gray-700 text-gray-400 transition"
                                    onChange={handleOnChange}
                                    onKeyDown = {(e) => handleOnKeyDown(e,index)}
                                    value={otp[index]}
                                  />
                                </React.Fragment>
                                );
                            })}
                          </div>
                        </div>
                        <div className="flex justify-between"></div>
                        <div>
                          <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
                          <div className="flex items-center mt-3 justify-center">
                            <p className="mr-3">Can't remember your Enrollee ID?</p>
                            <Link to="/dashboard/contact" variant="body2">
                              Chat With us
                            </Link>
                          </div>
                        </div>
                    </Form>
                </div>      
            </div>
        </div>
  )
}

export default VerifyOtp