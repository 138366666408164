import React, { useState , useEffect } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import styles from "./index.module.css";
import { useHistory } from 'react-router-dom';
import {AuthButton,Form} from '../../../Containers';
import {CustomFormControl, CustomSelect, ModalLoader } from '../../../common';
import { TextField } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalList, fetchHospitalLocation, getHospitals } from '../../../../store/hospitals';

function AuthCode() {
    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem('user'));
    const [depName, setDepName] = useState({ value: "", invalid: false });
    const [reqMess, setReqMess] = useState('');
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [hospitalLocation, setHospitalLocation] = useState("");
    const [hospital, setHospital] = useState("");
    const { list, locations } = useSelector(getHospitals);
    const [pickedHospital, setPickedHospital] = useState({});
    const [isLoading,setIsLoading] = useState(false);
    useEffect(() => {
      dispatch(fetchHospitalLocation())
    },[]);

    const hanldeSetHospital = (value) => {
      const item = list.find((item) => item.name === value);
      setPickedHospital(item);
    };


    const handleSubmit = async(e) => {
      e.preventDefault();
      setIsLoading(true);
      let obj = {};
      if(depName.value == '') {
        obj = {
          hospital_name: pickedHospital.name,
          request_message: reqMess,
          booking_for_dependent: false
        }
      } else {
        obj = {
          hospital_name: pickedHospital.name,
          request_message: reqMess,
          booking_for_dependent: true,
          dependent_name: depName.value
        }
      }
      try {
        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/users/enrollees/request-code`,obj,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`${token}`
          }
        });
        toast.success(data?.message,{
          position:"bottom-right"
        });
        setIsLoading(false);
        history.push('/dashboard/wellnesshmo');

      }catch(err) {
        toast.error('Error while requesting code',{
          position:"bottom-right"
        });
      }
    };


    const handleSetValues = (prevValues, newValue, setFn) => {
      const newData = { ...prevValues };
      newData.value = newValue;
      newData.invalid = false;
      setFn(newData);
    };

    const handleHospitalLocationChange = ({ target }) => {
      setHospitalLocation(target.value);
      dispatch(fetchHospitalList(target.value));
    };
    
  
return (
  <div className={styles["plan-wrapper"]}>
    <ModalLoader visible={isLoading} />
    <div className="mb-10 flex items-center">
      <button className={styles["back-btn"]} onClick={() => history.goBack()}>
        <IoArrowBackOutline />
      </button>
      <p className="ml-1.5">Back</p>
    </div>
    <div className={styles["container"] + 'block lg:flex'}>
      <div className={styles["title-container"]}>
        <p className={styles["title"]}>Request Code</p>
        <p className={styles["sub-title"]}>
          Fill the form and  we would send you an authorization code.
        </p>
      </div>
      <div className='bg-white w-full lg:w-[50%] rounded lg:ml-2'>
        <div>
          <Form onSubmit={handleSubmit} className="py-5">
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <TextField
                disabled
                id={`id-${userData.first_name}`}
                label={'Name'}
                variant="outlined"
                value={`${userData.first_name}${' '} ${userData.last_name}`}
                  
              />
            </CustomFormControl>
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <div>
              <CustomSelect
                label="Hospital Location"
                value={hospitalLocation}
                onChange={handleHospitalLocationChange}
                loading={loading}
                items={locations}
              />
              <CustomSelect
                label="Hospital"
                value={hospital}
                onChange={({ target }) => hanldeSetHospital(target.value)}
                loading={loading}
                items={list}
              />
            </div>
            </CustomFormControl>
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <label className='text-[#A49B9B] text-[14px] mb-2'>Your Request</label>
              <textarea 
                value={reqMess}
                className='border border-[#A49B9B] p-[2]' 
                aria-label="empty textarea" 
                placeholder="send a request" 
                onChange={(e) => setReqMess(e.target.value)}
              />
            </CustomFormControl>
            <CustomFormControl styles={{ mb: 0, mt: 2 }}>
              <div className='text-[14px] mb-2'>If you are booking for a dependant, include a name</div>
              <TextField
                id={`id-${userData.first_name}`}
                label={'Dependant Name (optional)'}
                variant="outlined"
                onChange={({ target }) => handleSetValues(depName, target.value, setDepName)}
                value={depName.value}    
                />
            </CustomFormControl>
            <div className="pb-10">
              <AuthButton className="mt-5 bg-[#EC4D67]">Request Auhorization Code </AuthButton>
            </div>
          </Form>
        </div>
      </div>    
    </div>
  </div>
  )
}

export default AuthCode