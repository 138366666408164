import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { getUser } from "../../store/auth";
import { useHistory } from "react-router-dom";
import { addAmount } from "../../store/userData";
import { useEffect } from "react";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const userData = useSelector(getUser);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addAmount(0));
  },[history]);

  const isAuthRoute = (props) => {
    const token = localStorage.getItem("token");
    if (token) {
      return <Component {...props} />;
    }

    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={(props) => isAuthRoute(props)} />;
};

// AuthenticatedRoute.propTypes = {
//   component: PropTypes.node.isRequired,
// };

export default AuthenticatedRoute;
