import { apiCallBegan, apiCallFailed, apiCallSuccess } from "../actions/api";
import axios from "axios";
import Alert from "../../components/common/Alert";
import { toast } from "react-toastify";
export const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegan.type) return next(action);
   
    next(action);
    const { url, baseUrl, method, data, onStart, onError, onSuccess, params } = action.payload;

    try {
      if (onStart) dispatch({ type: onStart });
      const response = await axios.request({
        baseURL: baseUrl ||  `${process.env.REACT_APP_API_URL}/v1`,
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers" : "Content-Type",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
        },
        url,
        method,
        data,
        params,
      });

      if (!response.data.status && !response.data.data) {
        Alert.notication("error", "Error", response.data.message, { timer: 2000 });
      }

      if (onSuccess) return dispatch({ type: onSuccess, payload: response.data });
      dispatch({ type: apiCallSuccess.type, payload: response.data });
    } catch (error) {
      console.log("logging errors..");
      console.log(error.response);
      Alert.notication("error", error.response.data.message);
      if (error.response && error.response.data && error.response.data.error) {
        Alert.notication("error", error.response.data.error);
      }
      if (error.response.data.error == "validation_error") {
        Alert.notication("error", error.response.data.message);
      }
      if (onError) return dispatch({ type: onError, payload: error.response });

      dispatch({ type: apiCallFailed.type, payload: error.response });
    }
  };
