import React from 'react'
import DocDetail from '../../../DocDetail'

function DoctorDetail() {
  return (
    <div className='p-5 md:px-10 bg-white'>
      <h2 className='font-bold text-[24px]'>Doctor Profile</h2>
      <div className='grid grid-cols-1 lg:grid-cols-4 '>
        {/* Doctor Detail  */}
        <div className=' col-span-3'>
          {<DocDetail />}
        </div>
        {/* Doctor Suggestion  */}
        <div>
          {/* <DoctorSuggestionList/> */}
        </div>
      </div>
    </div>
  )
}

export default DoctorDetail