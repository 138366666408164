import React from 'react'
import styles from "./index.module.css";
import { useHistory } from 'react-router-dom';
import HospitalLocation from '../../MedicalCheckUp/widgets/HealthCheckup/widgets/HospitalLocation';
import { useState } from 'react';
import ModalLoader from '../../../common/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CombineDateAndTime } from '../../../../utils/helper';


function BookVisit() {
    const history = useHistory();
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const [pickedHospital, setPickedHospital] = useState({});
    const [appointmentDate, setAppointmentDate] = useState('');
    const [reason, setReason] = useState('');
    const [time ,setTime] = useState('');

    const handleSubmit = async(e) => {
        e.preventDefault();
        if(time == '') {
          toast.error('select time',{
            position:"bottom-right"
          });
        }else {
          setLoading(true);

        let newdate = CombineDateAndTime(appointmentDate,time);
        let obj = {
            hospital_name: pickedHospital.name,
            appointment_date: newdate,
            comment: reason
        }
        try {
          const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/hmo/appointments`,
          obj,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`${token}`
          }
      });
      setLoading(false);
      toast.success(data?.message,{
        position:"bottom-right"
      });
      history.push('/dashboard/wellnesshmo');
      console.log(data);
        }catch(err) {
            setLoading(true)
            toast.error(err?.response?.data?.message,{
              position:"bottom-right"
          });
        }
        }
        
    }
    const onChange = (time, timeString) => {
      setTime(timeString);
    };
  return (
    <div className={styles["plan-wrapper"]}>
      <ModalLoader visible={loading} />
      <HospitalLocation reason={reason} setReason={setReason} visit={true} handlePatientSubmit={handleSubmit} pickedHospital={pickedHospital} setPickedHospital={setPickedHospital} setAppointmentDate={setAppointmentDate} onChange={onChange} />
    </div>
  )
}

export default BookVisit