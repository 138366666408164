import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchInsuranceCalculator,
  fetchMarriedInsuranceCalculator,
  getInsuranceCalculator,
} from "../../../../store/insuranceCalculator";
import { addField, addNumberOfDependent, getUserInfo } from "../../../../store/userData";
import {CustomSelect, SingleSelect} from "../../../common";
import _ from "lodash";
import PricingCard from "../../../UI/PricingCard";
import { updateShow } from "../../../../store/steps";
import { BUY_PLAN_STEPS_TITLE, BUY_PLAN_URLS } from "../../../../utils/constants";
import { resetSteps, setSteps } from "../../../../utils/helper";
import { getPlanSteps } from "../../../../store/steps";
import { AuthButton, Form } from "../../../Containers";

const validateBiodata = (obj) => {
  const keys = Object.keys(obj);
  const isError = keys.every((key) => !obj[key].value);
  return isError;
};

const Biodata = ({buyOther}) => {
  const [values, setValues] = useState({});
  const [dependent, setDependent] = useState("");
  const { values: stepsValues } = useSelector(getPlanSteps);
  const dispatch = useDispatch();
  const history = useHistory();
  const { sex, type, spouse_sex, spouse_name } = useSelector(getUserInfo);
  const { calculatorData } = useSelector(getInsuranceCalculator);

  const demographics = calculatorData.demographics;

  useEffect(() => {
    if (!sex || !type) {
      resetSteps(dispatch);
      return history.push(BUY_PLAN_URLS.CREATE_YOUR_PLAN);
    }

    setSteps(stepsValues, BUY_PLAN_STEPS_TITLE.BIODATA, dispatch);
    if (spouse_sex && spouse_name) {
      
      dispatch(fetchMarriedInsuranceCalculator(sex, spouse_sex,buyOther));
    } else {
      dispatch(fetchInsuranceCalculator(type, sex,buyOther));
    }
  }, [sex, type, history, dispatch]);

  useEffect(() => {
    const initialValues = {};
    for (const demographic of demographics) {
      initialValues[demographic.name] = { field: "", value: "", type: "demographics", ans: "" };
    }
    setValues(initialValues);
    dispatch(addField(initialValues));
  }, [demographics, dispatch]);

  const handleChange = (value, name, fields, points) => {
    const newValues = _.cloneDeep(values);
    newValues[name].field = name;
    newValues[name].value = value;
    newValues[name].ans = fields[points.lastIndexOf(value)];
    console.log(value);
    console.log(fields)
    setValues(newValues);
    dispatch(addField(newValues));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateBiodata(values)) {
      return alert("Please fill all the fields");
    }

    const newValues = _.cloneDeep(values);

    const numberOfDependent = parseInt(dependent);
    dispatch(addField(newValues));
    dispatch(addNumberOfDependent(numberOfDependent));
    if (numberOfDependent > 0) history.push(BUY_PLAN_URLS.DEPENDENT);
    else history.push(BUY_PLAN_URLS.MEDICAL_SERVICES);
  };

  const handleDependentChange = (value) => {
    setDependent(value);
    const numberOfDependent = parseInt(value);
    if (numberOfDependent > 0) {
      dispatch(updateShow(BUY_PLAN_STEPS_TITLE.DEPENDENT, true));
    }
  };

  return (
    <div className="py-5 w-full">
      <Form onSubmit={handleSubmit}>
        {demographics.length > 0 && (
          <>
            {demographics.map((demographic, index) => (
              <div className="w-full" key={demographic.name + index}>
                {demographic.name === "How many dependant?" ? (
                  <SingleSelect
                    label={demographic.name}
                    items={demographic.value?.names}
                    value={dependent}
                    onChange={handleDependentChange}
                    required
                  />
                ) : (
                <CustomSelect
                    key={demographic.name + index}
                    label={demographic.name}
                    value={values[demographic.name]?.field}
                    onChange={({ target }) =>
                      handleChange(target.value, demographic.name, demographic.value.names, demographic.value.points)
                    }
                    items={demographic.value.names}
                    points={demographic.value.points}
                    required
                />
                )}
              </div>
            ))}
          </>
        ) }
        <AuthButton className="my-5 bg-[#E6072C]">Proceed</AuthButton>
        <PricingCard disabled={true} />
      </Form>
    </div>
  );
};

export default Biodata;