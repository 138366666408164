import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Menu, MenuItem, ListItemText } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  Btn1: {
    background: "transparent",
    borderRadius: "5px",
    color: "#525252",
  },
  Btn2: {
    background: "#FBE6E4",
    borderRadius: "5px",
    color: "#525252",
    padding: "4px 8px",
  },
}));

export default function AppMenu({ menu_name_or_icon, item_names, menuBtnStyle1, modal }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItem = (item) => {
    item[0]();
    handleClose();
  };
  const classes = useStyles();

  return (
    <div className="mt-[10px]">
      <div onClick={handleClick} aria-haspopup="true" className={menuBtnStyle1 ? classes.Btn2 : classes.Btn1}>
        {menu_name_or_icon && menu_name_or_icon}
      </div>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {item_names.map((item, i) => (
          <MenuItem key={i} onClick={() => handleMenuItem(Object.values(item))}>
            <ListItemText primary={Object.keys(item)} />
            {modal && modal}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

AppMenu.propTypes = {
  menu_name_or_icon: PropTypes.any,
  item_names: PropTypes.any,
};
