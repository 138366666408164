import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _, { forEach } from "lodash";

import { getOpticalCare } from "../../../../../../store/opticalCare";
import { addAmount, addField, getUserInfo, setPrice, setPriceStat, setResetInfo } from "../../../../../../store/userData";

import CustomSelect from "../../../../../common/Select";
import ProceedToPayment from "../../../../../Containers/ProceedToPayment";
import Form from "../../../../../Containers/Form";
import PricingCard from "../../../../../UI/PricingCard";
import SingleSelect from "../../../../../common/Select/SingleSelect";
import { BUY_PLAN_STEPS_TITLE, OPTICAL_CARE_URLS, OPTICAL_SERVICES } from "../../../../../../utils/constants";
import usePaystack from "../../../../../hooks/usePaystack";
import usePaystackMethods from "../../../../../hooks/usePaystackMethods";
import { CombineDateAndTime, postBuyOther, resetSteps, setSteps } from "../../../../../../utils/helper";

import styles from "../index.module.css";
import { fetchHospitalList, getHospitals } from "../../../../../../store/hospitals";
import axios from "axios";
import Alert from '../../../../../common/Alert'
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import { TextField } from "@mui/material";
import { CustomFormControl } from "../../../../../common";
import DiscountModal from "../../../../../common/Modal/discountModal";


const StepTwo = ({ firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone, buyOther, setBuyOther, promo, setPromo, allValue, allField, setAllValue, setAllField }) => {
  const { fields, amount_paid,isNormalPrice,normalPrice } = useSelector(getUserInfo);
  const [values, setValues] = useState(fields);
  const [data, setData] = useState([]);
  const { opticalValue, loading } = useSelector(getOpticalCare);
  const dispatch = useDispatch();
  const history = useHistory();
  const [appointmentDate, setAppointmentDate] = useState('');
  const { locations, } = useSelector(getHospitals);
  const [option, setOption] = useState("");
  const [hospitalLocation, setHospitalLocation] = useState("");
  const [hospital, setHospital] = useState("");
  const [pickedHospital, setPickedHospital] = useState({});
  const [time, setTime] = useState('');
  const token = localStorage.getItem("token");
  const [location, setLocation] = useState([]);
  const [list, setList] = useState([]);
  const [load, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cost, setCost] = useState('');
  const handlePayment = usePaystack();
  const [removePromo, setRemovePromo] = useState(false);


  let newArr;
  // const { onSuccess,} = usePaystackMethods();


  const fetchHospitalLists = async (item) => {
    setLoading(true);
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive/${item}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setList(data.data.health_centres);
      console.log(data);
      setLoading(false);


    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-right"
      });
    }
  }
  const fetchHospitalLocation = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setLocation(data?.data?.locations);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchHospitalLocation();

  }, []);

  useEffect(() => {
    fetchHospitalLists(hospitalLocation);

  }, [hospitalLocation]);



  const hanldeSetHospital = (value) => {
    console.log(value);
    const item = list.find((item) => item.name === value);
    const values = {};
    setPickedHospital(item);
  };

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };


  useEffect(() => {
    const newData = opticalValue.filter((value) => OPTICAL_SERVICES.includes(value.name.toLowerCase()));
    const initialValues = _.cloneDeep(fields);
    for (const data of newData) {
      initialValues[data.name] = { field: "", value: "", type: "dental", ans: "" };
    }
    setData(newData);
    setValues(initialValues);
    dispatch(addField(initialValues));
  }, [opticalValue, history, dispatch]);

  const handleChange = (value, name, price) => {
    const newValues = _.cloneDeep(values);
    setOption(value);
    newValues[name].field = name;
    newValues[name].value = value === "Yes" ? price[1] : price[0];
    newValues[name].ans = value;
    setValues(newValues);
    let newArrField;
    let newArrValue;
    if (allField.includes(name)) {
      newArrField = allField?.filter((d, index) => {
        if (d !== name) {
          return d;
        }
      });

      setAllField([...newArrField, name])

    } else {
      setAllField([...allField, name])
    }


    if (allValue.includes(name)) {
      newArrValue = allValue?.filter((d, index) => {
        if (d !== value) {
          return d;
        }
      });
      setAllValue([...newArrValue, value])
    } else {
      setAllValue([...allValue, value])
    }
    dispatch(addField(newValues));
    if(promo != 0) {
      dispatch(setPriceStat(true));
    }
  };

  const postOptical = async (opticalData) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/dental-optical/care/create/optical`,
        opticalData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        },
      );
      toast.success(data?.message, {
        position: "bottom-right"
      });
      dispatch(addAmount(0));
      history.push('/dashboard/medical-checkup');
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-right"
      });
      history.push('/dashboard/medical-checkup');
    }

  }

  const onSuccess = (reference) => {
    let newdate = CombineDateAndTime(appointmentDate, time);
    
    let count;
    if (isNormalPrice) {
      
      count = 10 / 100 * normalPrice;
    } else {
      count = 0;
    }

    let newValue = normalPrice - count;
    
    if (buyOther == 'myself') {

      const allData = {
        services: {
          fields: allField,
          values: newArr
        },
        service_name: "optical",
        transaction_id: reference.trxref,
        amount_paid: newValue,
        hospital_name: pickedHospital.name,
        hospital_location: pickedHospital.location,
        hospital_address: pickedHospital.address,
        appointment_date: newdate,
        doctor_name: "",
        comment: "",
        has_promo: false
      }
      postOptical(allData);

    } else if (buyOther == 'other') {
      let obj = {
        request_type: "optical",
        data: {
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          email: email,
          request_type: "optical",
          service_name: "optical",
          services: JSON.stringify({
            fields: allField,
            values: newArr
          }),
          amount_paid: newValue,
          hospital_name: pickedHospital?.name,
          hospital_location: pickedHospital?.location,
          hospital_address: pickedHospital?.address,
          doctor_name: "",
          comment: "",
          appointment_date: newdate,
          has_promo: promo ? true : false,
          transaction_id: reference.trxref
        }
      }
      postBuyOther(obj, toast, history);
    }

    window.location.href('/dashboard');
    Alert.notication("success", "Subscription", "subscription successful");
    // Implementation for whatever you want to do with reference and after success call.
    // dispatch(logout());
    window.location.href('/dashboard')
    // setTimeout(() => {
    //      window.location.href('/dashboard');
    // }, 2000);
    console.log(reference);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    newArr = allField.map((data, index) => {
      return fields[data].ans;
    });
    if (time == '') {
      toast.error('Select time of appointment', {
        position: 'bottom-right'
      })
    } else {
      let count;
      if (promo) {
        dispatch(setPrice(amount_paid));
        dispatch(setPriceStat(true));
      } else {
        count = 0;
      }
      setCost(amount_paid);
      dispatch(addAmount(amount_paid));

      if (promo) {
        setRemovePromo(true);
        setOpenModal(true);
      } else {
        handlePayment(onSuccess, onClose);

      }
    }

  };

  const handleHospitalLocationChange = ({ target }) => {
    setHospitalLocation(target.value);
    fetchHospitalLists(target.value);
  };

  const onExit = () => {
    setOpenModal(false);
    
  }


  const onChange = (time, timeString) => {
    setTime(timeString);
  };
  return (
    <>
      {
        (openModal && promo) &&
        <DiscountModal close={onExit} isSuccess={openModal} handlePayment={handlePayment} onSuccess={onSuccess} onClose={onClose} cost={cost} />
      }
      <div className="py-5 w-full">
        <div className={styles["others-service"]}>Other Services</div>
        <Form onSubmit={handleSubmit}>
          {data.length > 0 && (
            data.map((dentalCareOption, index) => (
              <CustomSelect
                key={dentalCareOption.name + index}
                label={dentalCareOption.name}
                value={option}
                items={dentalCareOption.value?.names}
                onChange={({ target }) =>
                  handleChange(target.value, dentalCareOption.name, dentalCareOption.value.prices)
                }
              />
            ))
          )}
          {
            buyOther == 'other' &&
            <>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  id={'FirstName'}
                  label={'First Name'}
                  variant="outlined"
                  value={firstName}
                  required
                  onChange={({ target }) => setFirstName(target.value)}
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  id={'LastName'}
                  label={'Last Name'}
                  variant="outlined"
                  value={lastName}
                  required
                  onChange={({ target }) => setLastName(target.value)}
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  id={'Phone'}
                  label={'Phone Number'}
                  variant="outlined"
                  value={phone}
                  required
                  onChange={({ target }) => setPhone(target.value)}
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                <TextField
                  id={'email'}
                  label={'Email Address'}
                  variant="outlined"
                  value={email}
                  required
                  onChange={({ target }) => setEmail(target.value)}
                />
              </CustomFormControl>
            </>

          }
          <div>
            <CustomSelect
              label="Hospital Location"
              value={hospitalLocation}
              onChange={handleHospitalLocationChange}
              loading={loading}
              items={location}
            />
            <CustomSelect
              label="Hospital"
              value={hospital}
              onChange={({ target }) => hanldeSetHospital(target.value)}
              loading={loading}
              items={list}
            />
          </div>
          <div>
            <div className="my-[10px]">Select Appointment Date</div>
            <input required type="date" onChange={(e) => setAppointmentDate(e.target.value)} placeholder="Select Appointment Date" className="border w-full h-[50px] p-[20px] rounded-[8px]" />
          </div>
          <div>
            <div className="my-[10px]">Select Appointment Time</div>
            <TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
          </div>
          <div>
            <button type="button" className={styles["back-button"]} onClick={() => history.goBack()}>
              Back
            </button>
            <ProceedToPayment className="my-5 bg-[#EC4D67]">Proceed to Payment</ProceedToPayment>
          </div>
          <PricingCard disabled={true} />
          {
            (promo != 0 && !removePromo) && <div className="font-bold">10% discount available ,kindly proceed to payment</div>
          }
        </Form>
      </div>
    </>
  );
};

export default StepTwo;
