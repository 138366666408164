import React from 'react'
import Contact from '../../Contact'

function ContactAll() {
  return (
    <div className='py-[30px]'>
      <Contact size={true} />
    </div>
  )
}

export default ContactAll