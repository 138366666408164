import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Input from "@mui/material/Input";
import Alert from "../../../../../../../common/Alert";
import { fetchHospitalList, fetchHospitalLocation, getHospitals } from "../../../../../../../../store/hospitals";
import { addAmount, addField, addGender, addHospital, addMaritalStatus, addSpouse, setPriceStat } from "../../../../../../../../store/userData";
import { getPlanSteps, updateSteps } from "../../../../../../../../store/steps";
import CustomFormControl from "../../../../../../../common/FormControl";
import CustomSelect from "../../../../../../../common/Select";
import AuthButton from "../../../../../../../Containers/AuthButton";
import PricingCard from "../../../../../../../UI/PricingCard";
import Form from "../../../../../../../Containers/Form";

import { BUY_PLAN_STEPS_TITLE, OPTICAL_CARE_URLS, STEPS } from "../../../../../../../../utils/constants";
import { CombineDateAndTime, postBuyOther, setSteps } from "../../../../../../../../utils/helper";

import styles from "../index.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import usePaystack from "../../../../../../../hooks/usePaystack";
import { TimePicker } from "antd";
import dayjs from "dayjs";

const MenBelow40 = ({belowData,cost,type,amount,promo,setPromo}) => {
  const token = localStorage.getItem("token");
  const { values: stepsValues } = useSelector(getPlanSteps);
  const [sex, setSex] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [hospitalLocation, setHospitalLocation] = useState("");
  const [hospital, setHospital] = useState("");
  const [spouse, setSpouse] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const dispatch = useDispatch();
  const [pickedHospital, setPickedHospital] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState('');
  const [time, setTime] = useState('');
  const history = useHistory();
  // const { loading } = useSelector(getHospitals);
  const [location, setLocation] = useState([]); 
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [buyOther, setBuyOther] = useState(null);




  const fetchHospitalLists = async (item) => {
    setLoading(true);
    try {
    
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive/${item}`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      });
      setList(data.data.health_centres);
      setLoading(false);
      

    }catch(err) {
      setLoading(false);
      toast.error(err?.response?.data?.message,{
        position:"bottom-right"
    });
    }
  }
  const fetchHospitalLocation  = async () => {
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      });
      setLocation(data?.data?.locations);
    }catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchHospitalLocation();

  },[]);

  useEffect(() => {
    fetchHospitalLists(hospitalLocation);

  },[hospitalLocation]);


  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
   
};

const hanldeSetHospital = (value) => {
  console.log(value);
  const item = list.find((item) => item.name === value);
  const values = {};
  setPickedHospital(item);
  console.log(item);
};

  const handlePayment = usePaystack();
  // const { onSuccess, onClose } = usePaystackMethods();

  const postEmploy = async (newData) => {
    try {
      const resp = axios.post(`${process.env.REACT_APP_API_URL}/v2/comprehensive/care/create`,
      newData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      }
      );
    console.log(resp.status);
    toast.success('Appointment created successfully',{
      position:"bottom-right"
    });
    dispatch(addAmount(0));
    history.push('/dashboard/medical-checkup');
    }catch(err) {
      toast.error(err?.response?.data?.message,{
        position:"bottom-right"
    });
    }
    
  }

  const onSuccess = (reference) => {
    let newdate = CombineDateAndTime(appointmentDate,time);
   
    if(buyOther == 'myself') {
      const allData =  {
        services:type,
        service_name: type,
        transaction_id: reference.trxref,
        amount_paid: cost,
        hospital_name: pickedHospital.name,
        hospital_location: pickedHospital.location,
        hospital_address:pickedHospital.address,
        appointment_date: newdate,
        doctor_name: "",
        comment: "",
        has_promo: false
        }
        postEmploy(allData);
    } else if(buyOther == 'other') {
      let obj = {
        request_type: type,
        data: {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        email: email,
        request_type: type,
        service_name: type,
        services: type,
        amount_paid: cost,
        hospital_name: pickedHospital?.name,
        hospital_location: pickedHospital?.location,
        hospital_address: pickedHospital?.address,
        doctor_name: "",
        comment: "",
        appointment_date: newdate,
        has_promo: promo ? true : false,
        transaction_id: reference.trxref
        }
    }
    postBuyOther(obj,toast,history);
    }
   
    
    
    window.location.href('/dashboard');
    Alert.notication("success", "Subscription", "subscription successful");
    // Implementation for whatever you want to do with reference and after success call.
    // dispatch(logout());
    window.location.href('/dashboard')
    // setTimeout(() => {
    //      window.location.href('/dashboard');
    // }, 2000);
    console.log(reference);
};

  const handleSubmit = (e) => {
    e.preventDefault();
    if(time == '') {
      toast.error('Select time of appointment',{
        position:'bottom-right'
      })
     }else {
      dispatch(setPriceStat(false));
    handlePayment(onSuccess, onClose);
     }
  };
  const handleHospitalLocationChange = ({ target }) => {
    setHospitalLocation(target.value);
    fetchHospitalLists(target.value);
  };
  const onChange = (time, timeString) => {
    setTime(timeString);
  };

  return (
    <Form onSubmit={handleSubmit} className="py-5 px-0">
      <div className="row">
        <p className={styles["header-top"]} htmlFor="text">
          These are the services included under the Basic Health checkup
        </p>
      </div>
      <div>
        {
          belowData.length > 0 && belowData.map((data,index) => {
            return (
              <>
                <p className="mb-2 mt-4  font-bold">{data?.name}</p>
                {data?.value?.map((d,i) => {
                  return (
                    <div className={styles["disabled-field"]}>{d}</div>
                  )
                })}
                
              </>
            )
          })
        }
      </div>
      <CustomSelect
        label={'Buy For'}
        value={buyOther}
        onChange={({ target }) => setBuyOther(target.value)}
        loading={false}
        required
        items={[
          { value: "myself", label: "Myself" },
          { value: "other", label: "Others" },
        ]}
      />
      {
        buyOther == 'other' &&
        <>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'FirstName'}
              label={'First Name'}
              variant="outlined"
              value={firstName}
              required
              onChange={({ target }) => setFirstName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'LastName'}
              label={'Last Name'}
              variant="outlined"
              value={lastName}
              required
              onChange={({ target }) => setLastName(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'Phone'}
              label={'Phone Number'}
              variant="outlined"
              value={phone}
              required
              onChange={({ target }) => setPhone(target.value)}
            />
          </CustomFormControl>
          <CustomFormControl styles={{ mb: 0, mt: 2 }}>
            <TextField
              id={'email'}
              label={'Email Address'}
              variant="outlined"
              value={email}
              required
              onChange={({ target }) => setEmail(target.value)}
            />
          </CustomFormControl>
        </>
      }
      <div>
        <CustomSelect
          label="Hospital Location"
          value={hospitalLocation}
          onChange={handleHospitalLocationChange}
          loading={loading}
          items={location}
        />
        <CustomSelect
          label="Hospital"
          value={hospital}
          onChange={({ target }) => hanldeSetHospital(target.value)}
          loading={loading}
          items={list}
        />
        </div>
        <div>
          <div className="my-[10px]">Select Appointment Date</div>
          <input type="date" required onChange={(e) => setAppointmentDate(e.target.value)} placeholder="Select Appointment Date" className="border w-full h-[50px] p-[20px] rounded-[8px]" />
        </div>
        <div>
          <div className="my-[10px]">Select Appointment Time</div>
          <TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
        </div>
      <div className="pb-10">
        <AuthButton className="mt-5 bg-[#EC4D67]">Proceed to Payment</AuthButton>
      </div>
      <PricingCard disabled={true} />
      {promo &&
        <div className='flex'>
          <div>{promo}% discount</div>
          <div className='ml-[10px] line-through font-bold'>N{cost}</div>
        </div>}
    </Form>
  );
};

export default MenBelow40;
