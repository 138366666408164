import React, { useEffect, useState } from "react";

import styles from "./index.module.css";
import Process from "./Process";
import FormContainer from "../../../../Containers/FormContainer";

import CreatePlan from "./widgets/CreatePlan";
import StepTwo from "./widgets/StepTwo";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { fetchPromo, getOpticalCareSteps, setSteps } from "../../../../../utils/helper";
import { OPTICAL_CARE_STEPS, OPTICAL_CARE_URLS } from "../../../../../utils/constants";

import { fetchHospitalLocation } from "../../../../../store/hospitals";
import { getInsuranceCalculator, stopLoading } from "../../../../../store/insuranceCalculator";
import { getPlanSteps, updateSteps } from "../../../../../store/steps";
import { addAmount, getUserInfo } from "../../../../../store/userData";

import ModalLoader from "../../../../common/Loader";
import Alert from "../../../../common/Alert";

const DentalCare = () => {
  const { values } = useSelector(getPlanSteps);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [allField, setAllField] = useState([]);
  const [allValue, setAllValue] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [promo, setPromo] = useState('');
  const [buyOther,setBuyOther] = useState('');
  const currentStep = getOpticalCareSteps(params.step);
  const { loading, error } = useSelector(getInsuranceCalculator);

  useEffect(() => {
    dispatch(addAmount(0));
    fetchPromo(setPromo);
}, [promo]);

  useEffect(() => {
    if (error) Alert.notication("error", "Error", error);
    setSteps(values, currentStep, dispatch);
    dispatch(fetchHospitalLocation());
    dispatch(stopLoading());
  }, [dispatch, error]);

  useEffect(() => {
    if (!currentStep) return history.push(OPTICAL_CARE_URLS.CREATE_DENTAL_CARE_PLAN);
  }, [currentStep, history, dispatch]);

  return (
    <div className={styles["plan-wrapper"]}>
      <div className="mb-2  lg:mb-10 flex items-center">
        <button className={styles["back-btn"]} onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Optical Care</p>
      </div>
      <div className={styles["plan-container"]}>
        <ModalLoader visible={loading} />
        <div className={styles["title-container"]}>
          <p className={styles["title"]}>Optical Care</p>
          <p className={styles["sub-title"]}>Get access to credible dental services here.</p>
        </div>
        <FormContainer className={styles["form-container"]}>
          <div className={styles["form-step"]}>
            <p>
              Step{" "}
              <span style={{ color: "red" }}>
                <b>1</b>
              </span>{" "}
              of{" "}
              <span style={{ color: "red" }}>
                <b>2</b>
              </span>
            </p>
            <div className={styles["divider-container"]}>
              <div className={styles["divider"]} />
              <div className={styles["divider"]} />
            </div>
          </div>
          {currentStep === OPTICAL_CARE_STEPS.STEP_ONE && 
          <CreatePlan 
            allField={allField} 
            allValue={allValue} 
            setAllValue={setAllValue} 
            setAllField={setAllField} 
            firstName={firstName} 
            setFirstName={setFirstName} 
            lastName={lastName} 
            setLastName={setLastName} 
            email={email} 
            setEmail={setEmail} 
            phone={phone} 
            setPhone={setPhone} 
            buyOther={buyOther}
            setBuyOther={setBuyOther}
            />}
          {currentStep === OPTICAL_CARE_STEPS.STEP_TWO && 
          <StepTwo 
            allField={allField} 
            allValue={allValue} 
            setAllValue={setAllValue} 
            setAllField={setAllField} 
            firstName={firstName} 
            setFirstName={setFirstName} 
            lastName={lastName} 
            setLastName={setLastName} 
            email={email} 
            setEmail={setEmail} 
            phone={phone} 
            setPhone={setPhone} 
            buyOther={buyOther}
            setBuyOther={setBuyOther}
            promo={promo}
            setPromo={setPromo}
            />}
        </FormContainer>
      </div>
    </div>
  );
};

export default DentalCare;
