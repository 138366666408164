import React, { useEffect, useState } from 'react'
import { sidebarNavBarStyles } from '../../styles/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { FaCircle } from "react-icons/fa";
import axios from 'axios';
import ModalLoader from '../../common/Loader';
import ProfileBg from '../../common/ProfileBg';

function Profile() {
  const classes = sidebarNavBarStyles();
  const baseUrl = "/dashboard";
  const [smallSize, setSmallSize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('user'));
  const [appointmentData, setAppointmentData] = useState([]);
  const [step, setStep] = useState(1);
  const [tab, setTab] = useState(1);
  const token = localStorage.getItem("token");
  const [appointmentOther, setAppointmentOther] = useState([]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const fetchOtherAppointment = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/plans/purchase-for-others`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        }
      )
      let newdata = data?.data?.slice(0, 5);
      console.log(newdata);
      setAppointmentOther(newdata);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchOtherAppointment();
  }, [tab])

  const fetchAppointment = async () => {
    setIsLoading(true);
    let type = 'upcoming';
    if (step == 2) {
      type = 'past';
    } else {
      type = 'upcoming';
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/appointments/history/${type}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })

      let newdata = data?.data?.data.slice(0, 5);
      setAppointmentData(newdata)
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAppointment();
  }, [step]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      if (windowSize[0] >= 765) {
        setSmallSize(false);
      } else {
        setSmallSize(true);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize[0]]);


  return (
    <div className=' w-full h-[90vh] bg-white'>
      <ModalLoader visible={isLoading} />
      <ProfileBg />
      <div className='p-[20px]'>
        <div className='w-full h-[50px] border-b flex'>
          <div className={`min-w-[100px] font-medium h-[50px] flex items-center text-[#324e6f] border-b-2 ${tab == 1 ? 'border-[#324e6f]' : 'border-none'} cursor-pointer mr-3`} onClick={() => setTab(1)}>My Appointment</div>
          <div className={`min-w-[100px] font-medium h-[50px] flex items-center text-[#324e6f] border-b-2 ${tab == 2 ? 'border-[#324e6f]' : 'border-none'} cursor-pointer`} onClick={() => setTab(2)}>Appointment For Others</div>
        </div>
        <div className='flex flex-col md:flex-row'>
          {
            tab == 1 &&
            <div className='mt-[20px] md:w-[100px] flex md:flex-col justify-between h-[100px] '>
              <div onClick={() => { setStep(1) }} className={`cursor-pointer w-full h-[50px] flex mr-[20px] md:mr-0 items-center ${step == 1 ? 'border-b-2 md:border-b-0 md:border-r-2 border-[#F7C657]' : 'border-none'}`}>Upcoming</div>
              <div onClick={() => { setStep(2) }} className={`cursor-pointer w-full h-[50px] flex items-center ${step == 2 ? 'border-b-2 md:border-b-0 md:border-r-2 border-[#F7C657]' : 'border-none'}`}>Past</div>
            </div>
          }
          <div className='bg-white drop-shadow-xl w-full md:ml-[20px] mt-[20px]'>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: `${windowSize[0] >= 765 ? 650 : 200}`, border: 'none' }} aria-label="simple table">
                {
                  tab == 1 &&
                  <TableHead>
                    <TableRow>
                      <TableCell className='text-[#718096] hidden md:block font-bold' align="left">
                        <div className='font-bold'>Hospital</div>
                      </TableCell>
                      {windowSize[0] >= 765 &&
                        <TableCell className='text-[#718096] hidden md:block  font-bold' align="left">
                          <div className='font-bold'>Date</div>
                        </TableCell>}
                      {windowSize[0] >= 765 &&
                        <TableCell className='text-[#718096] font-bold' align="left">
                          <div className='font-bold'>
                            Time
                          </div>
                        </TableCell>
                      }
                      <TableCell className='text-[#718096] font-bold' align="left">
                        <div className='font-bold'>Status</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>}
                {
                  tab == 2 &&
                  <TableHead>
                    <TableRow>
                      <TableCell className='text-[#718096] hidden md:block font-bold' align="left">
                        <div className='font-bold'>Hospital</div>
                      </TableCell>
                      {windowSize[0] >= 765 &&
                        <TableCell className='text-[#718096] hidden md:block  font-bold' align="left">
                          <div className='font-bold'>Date</div>
                        </TableCell>}
                      {windowSize[0] >= 765 &&
                        <TableCell className='text-[#718096] font-bold' align="left">
                          <div className='font-bold'>
                            Time
                          </div>
                        </TableCell>
                      }
                      <TableCell className='text-[#718096] font-bold' align="left">
                        <div className='font-bold'>Name</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>}
                {
                  tab == 1 &&
                  <TableBody>
                    {
                      appointmentData?.map((d, index) => {
                        return (
                          <TableRow
                            key={1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell className='hidden md:block' component="th" scope="row">
                              {d?.hospital_name}
                            </TableCell>
                            {windowSize[0] >= 765 && <TableCell className='hidden md:block' align="left">{new Date(d?.appointment_date).toLocaleDateString()}</TableCell>}
                            {windowSize[0] >= 765 && <TableCell className="hidden md:block" align="left">{new Date(d?.appointment_date).toLocaleTimeString()}</TableCell>}
                            {
                              <TableCell className="hidden md:block" align="left">
                                <div className='flex items-center'>
                                  <FaCircle color="#EFDB6F" /> <span className='ml-[10px]'>{d?.status}</span>
                                </div>
                              </TableCell>}

                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                }
                {
                  tab == 2 &&
                  <TableBody>
                    {
                      appointmentOther?.map((d, index) => {
                        return (
                          <TableRow
                            key={1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >

                            <TableCell className='hidden md:block' component="th" scope="row">
                              {d?.data?.hospital_name}
                            </TableCell>
                            {windowSize[0] >= 765 && <TableCell className='hidden md:block' align="left">{new Date(d?.data?.appointment_date).toLocaleDateString()}</TableCell>}
                            {windowSize[0] >= 765 && <TableCell className="hidden md:block" align="left">{new Date(d?.data?.appointment_date).toLocaleTimeString()}</TableCell>}
                            {
                              <TableCell className="hidden md:block" align="left">
                                <div className='flex items-center'>
                                  <span className='ml-[10px] font-semibold'>{d?.data?.first_name}{' '}{d?.data?.last_name}</span>
                                </div>
                              </TableCell>}
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile