import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Login from "../components/pages/Login";
import Dashboard from "./dashboard";
import NotFound from "../components/NotFound";
import ResetPassword from "../components/pages/resetPassword";
import ResetCode from "../components/pages/resetPassword/getResetCode";
import NewPassword from "../components/pages/resetPassword/newPassword";
import PrivateRoute from "../components/PrivateRoute";
import Register from "../components/pages/Register";
import Registerenrollee from "../components/pages/Register/Enroll";
import Otp from "../components/pages/Register/Otp";
import User from "../components/pages/User";
// import Profile from "../components/pages/Profile";
import Enrollee from "../components/pages/Register/Enrollee";
import EnrollDetail from "../components/pages/Register/EnrollDetail";
import ContactUs from "../components/pages/Register/ContactUs";
import EnrolleeOtp from "../components/pages/Register/EnrolleeOtp";

const Routes = () => {
  return (
      <Router>
        <Switch>
          <PrivateRoute exact path="/dashboard*" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/user" component={User} />
          <Route exact path="/sign-up/:step" component={Register} />
          <Route exact path="/register/nonenrollee" component={Registerenrollee} />
          <Route exact path="/register/enrollee" component={Enrollee} />
          <Route exact path="/register/enrollee-detail/otp" component={EnrolleeOtp} />
          <Route exact path="/register/enrollee-detail" component={EnrollDetail} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/otp" component={Otp} />
          <Route exact path="/sign-up" component={Register} />
          <Route exact path="/forget-password" component={ResetPassword} />
          <Route exact path="/reset-code" component={ResetCode} />
          <Route exact path="/new-password" component={NewPassword} />
          <Redirect exact from="/" to="/login" />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
  );
};

export default Routes;
