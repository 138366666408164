import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import Dashboard from "../components/pages/dashboard";
import NotFound from "../components/NotFound";
import BuyPlan from "../components/pages/BuyPlan";
import MedicalCheckUp from "../components/pages/MedicalCheckUp";
import HMO from "../components/pages/HMO";
import DentalCare from "../components/pages/MedicalCheckUp/widgets/DentalCare";
import OpticalCare from "../components/pages/MedicalCheckUp/widgets/OpticalCare";
import HealthCheckup from "../components/pages/MedicalCheckUp/widgets/HealthCheckup";
import BasicHealthCheckup from "../components/pages/MedicalCheckUp/widgets/HealthCheckup/widgets/BasicHealthCheckup";
import CancerScreening from "../components/pages/MedicalCheckUp/widgets/HealthCheckup/widgets/CancerScreening";
import ComprehensiveCheck from "../components/pages/MedicalCheckUp/widgets/HealthCheckup/widgets/Comprehensive";
import ComprehensiveCheckStep from "../components/pages/MedicalCheckUp/widgets/HealthCheckup/widgets/Comprehensive/widgets";
import PreEmployment from "../components/pages/MedicalCheckUp/widgets/HealthCheckup/widgets/PreEmployment";
import Dependents from "../components/pages/dashboard/widgets/Dependents";
import WellnessHmo from "../components/pages/dashboard/wellnesshmo";
import Profile from "../components/pages/Profile";
import ChangePassword from "../components/pages/Profile/Password";
import StandardPlan from "../components/pages/BuyPlan/StandardPlan";
import SeePartner from "../components/pages/dashboard/SeePartner";
import ContactAll from "../components/pages/dashboard/Contact";
import HospitalLocation from "../components/pages/MedicalCheckUp/widgets/HealthCheckup/widgets/HospitalLocation";
import Refill from "../components/pages/dashboard/Refil";
import AuthCode from "../components/pages/dashboard/AuthCode";
import HmoCard from "../components/pages/dashboard/HmoCard";
import AllBenefit from "../components/pages/dashboard/AllBenefit";
import AddDependant from "../components/pages/dashboard/AddDep";
import BookVisit from "../components/pages/dashboard/BookVisit";
import BasicPlan from "../components/pages/BuyPlan/BasicPlan";
import BookDoctor from "../components/pages/dashboard/BookDoctor";
import SearchDoc from "../components/pages/dashboard/BookDoctor/SearchDoc";
import DoctorDetail from "../components/pages/dashboard/BookDoctor/DoctorDetail";
import Telemedicine from "../components/pages/dashboard/Telemedicine";
import VerifyId from "../components/pages/dashboard/VerifyId";
import VerifyDetail from "../components/pages/dashboard/VerifyId/VerifyDetail";
import VerifyOtp from "../components/pages/dashboard/VerifyId/VerifyOtp";
import MedicalHistory from "../components/pages/dashboard/MedicalHistory";
import CallAppointment from "../components/pages/dashboard/CallAppoint";
import HomeVisit from "../components/pages/HomeVisit";
import InstantCall from "../components/pages/dashboard/CallAppoint/InstantCall";

const DashboardRoutes = () => {
  const baseUrl = "/dashboard";
 //const dispatch = useDispatch();

  
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${baseUrl}/home/:step`}
        component={Dashboard}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/wellnesshmo`}
        component={WellnessHmo}
      />
      <PrivateRoute 
        exact
        path={`${baseUrl}/wellnesshmo/verifyId`}
        component={VerifyId}
      />
      <PrivateRoute 
        exact
        path={`${baseUrl}/wellnesshmo/verifyId/otp`}
        component={VerifyOtp}
      />
       <PrivateRoute
        exact
        path={`${baseUrl}/see-partner`}
        component={SeePartner}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/contact`}
        component={ContactAll}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/profile`}
        component={Profile}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/profile/password`}
        component={ChangePassword}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/requestrefill`}
        component={Refill}
      />
      
      <PrivateRoute
        exact
        path={`${baseUrl}/authcode`}
        component={AuthCode}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/hmocard`}
        component={HmoCard}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/dependents`}
        component={Dependents}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/adddependant`}
        component={AddDependant}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/telemedicine`}
        component={Telemedicine}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/hospitalvisit`}
        component={BookVisit}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/medicalhistory`}
        component={MedicalHistory}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/homevisit`}
        component={HomeVisit}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/bookappointment`}
        component={CallAppointment}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/bookappointment/instantcall`}
        component={InstantCall}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/searchdoc/:name`}
        component={SearchDoc}
      />
      <PrivateRoute exact path={`${baseUrl}/doctordetail`} component={DoctorDetail} />
      <PrivateRoute exact path={`${baseUrl}/calldoctor`} component={BookDoctor} />
      <PrivateRoute exact path={`${baseUrl}/allbenefits`} component={AllBenefit} />
      <PrivateRoute exact path={`${baseUrl}/wellnesshmo/buy-your-plan/:step`} component={BuyPlan} />
      <PrivateRoute exact path={`${baseUrl}/medical-checkup`} component={MedicalCheckUp} />
      <PrivateRoute exact path={`${baseUrl}/medical-checkup/dental-care/:step`} component={DentalCare} />
      <PrivateRoute exact path={`${baseUrl}/medical-checkup/optical-care/:step`} component={OpticalCare} />
      <PrivateRoute
        exact
        path={`${baseUrl}/medical-checkup/health-care/hospital/`}
        component={HospitalLocation}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/medical-checkup/health-care/create-health-care/`}
        component={HealthCheckup}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/medical-checkup/health-care/create-health-care/basic-health-checkup`}
        component={BasicHealthCheckup}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/medical-checkup/health-care/create-health-care/pre-employment`}
        component={PreEmployment}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/medical-checkup/health-care/create-health-care/comprehensive-check`}
        component={ComprehensiveCheck}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/medical-checkup/health-care/create-health-care/comprehensive-check/:step`}
        component={ComprehensiveCheckStep}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/medical-checkup/health-care/create-health-care/cancer-screening`}
        component={CancerScreening}
      />
      <PrivateRoute exact path={`${baseUrl}/wellness-hmo`} component={HMO} />
      <PrivateRoute
        exact
        path={`${baseUrl}/wellnesshmo/buy-your-plan`}
        component={BuyPlan}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/wellnesshmo/basic-plan`}
        component={BasicPlan}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}/wellnesshmo/standard-plan`}
        component={StandardPlan}
      />
      <PrivateRoute
        exact
        path={`${baseUrl}`}
        component={Dashboard}
      />
      <Redirect exact from={`${baseUrl}`} to={`${baseUrl}`} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardRoutes;
