import React, { useState } from "react";
import { resetPasswordPageStyle } from "./resetStyle";
import Loader from "../../common/Loader";
import LeftSide from "../Auth/LeftSide";
import { useHistory } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import styles from './index.module.css';



const ResetPassword = () => {
  const classes = resetPasswordPageStyle();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const sendResetPassCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem('email',email);
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/forgot-pwd`,
      {
        email
      });
      toast.success(data?.message,{
        position:"bottom-right"
      });
      history.push("/reset-code");
      setLoading(false);
    }catch(err) {
      //err
      setLoading(false);
    }
    
  };

  return (
    <div className="w-full flex justify-center">
      <LeftSide />
      <Loader visible={loading} />
      
      <div className="w-full ">
        <form className={`${classes.formContainer} ${styles['auth-form']}`} onSubmit={sendResetPassCode}>
          <div className='w-full flex items-center relative'>
            <MdArrowBackIos className="cursor-pointer absolute top-2 left-[-20px] text-[20px] font-bold" onClick={() => history.goBack() } />
          </div>
          <p className={classes.resetP}>Reset your password</p>
          <p className={classes.resetText}>
            Provide your email address and a reset verification code will be sent to you
          </p>
          <div className={classes.inputSection}>
            <label>
              <sup>*</sup>Email Address
            </label>{" "}
            <br />
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Opeyemi@testcompany.com"
              className={classes.formInput}
            />
          </div>
          <br />
          <button className={classes.submitform}>Send Reset code</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
