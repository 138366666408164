import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function AllPlans() {
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);
  return (
    <div className="grid gap-2 grid-cols-2">
        <div className="relative w-[100%] h-[153px] p-[16px] bg-[#DBD5FC] rounded-[8px]">
            <div>
                <div className="text-[#373545] font-bold w-[172px]">Family & Personal Health Plan</div>
                <div className="mt-[25px]">
                    <div className="text-[#373545] text-[10px]">starting from</div>
                    <div className="text-[24px] font-bold">N182,000</div>
                </div>
            </div>
            <div className='absolute top-0 right-0'>
                <svg xmlns="http://www.w3.org/2000/svg" width="304" height="86" viewBox="0 0 304 86" fill="none">
                    <path opacity="0.5" d="M0.0613352 -31.1028C24.1381 -24.7478 39.0326 -21.0466 59.9384 -15.3943C85.8196 -63.0503 97.1366 -114.953 198.34 -139.217C148.431 -85.4134 129.82 -35.0902 201.486 10.0719C270.635 53.8019 358.403 61.5238 457.31 40.3565C385.699 81.084 300.687 93.2753 207.276 81.244C104.015 68.0132 40.4931 29.3175 0.0613352 -31.1028Z" fill="#251A6B"/>
                </svg>
            </div>
            <div className='absolute bottom-0 right-0'>
                <svg xmlns="http://www.w3.org/2000/svg" width="125" height="111" viewBox="0 0 125 111" fill="none">
                    <g clip-path="url(#clip0_10540_68670)">
                    <path opacity="0.5" d="M38.1751 64.5272L38.1766 64.5234C43.8939 49.9989 56.9021 41.0271 71.0569 41.4173L71.0578 41.4173C85.924 41.8078 98.4244 51.6584 103.038 67.0576C103.861 69.9166 104.287 72.8991 104.584 74.9781C104.682 75.6644 104.766 76.2523 104.845 76.7048C104.821 96.9831 93.3586 112.275 77.7557 115.775C62.2713 119.195 47.7935 112.373 39.9791 97.7694C34.2613 86.9725 33.6618 75.8122 38.1751 64.5272Z" stroke="white" stroke-width="1.5"/>
                    <path opacity="0.5" d="M7.80249 34.4839C8.37823 36.0476 8.8985 37.4436 9.39504 38.776C10.363 41.3732 11.2408 43.7286 12.2638 46.6136L12.5541 47.4322L13.3216 47.0257C15.7588 45.7352 18.1636 44.3842 20.558 43.0391C34.8655 35.001 48.802 27.1714 67.0749 33.7073C56.4443 37.1737 47.1197 41.609 40.1902 47.9712C32.7934 54.7624 28.1631 63.7154 27.5403 75.9005C26.9202 87.6339 29.583 97.6157 35.1603 105.909C40.1812 113.375 47.5354 119.431 56.8902 124.169C36.2313 123.188 20.747 113.361 10.0312 96.3233L10.0307 96.3226C-2.15618 76.9944 -1.82011 56.9143 7.80249 34.4839Z" stroke="white" stroke-width="1.5"/>
                    <path opacity="0.5" d="M122.547 75.7523C121.265 55.5629 117.905 37.3987 100.528 24.5757L98.5074 23.0849L99.3791 25.4396C100.565 28.6431 101.994 31.7716 103.435 34.8953C103.521 35.0803 103.606 35.2653 103.692 35.4503C105.052 38.3966 106.413 41.3437 107.605 44.3875C109.726 49.8031 111.298 55.4928 111.337 61.8985C103.374 40.5621 93.6195 27.3389 80.4373 19.8313C66.9417 12.1452 49.986 10.5286 28.0129 12.0967C34.7046 5.46035 45.3232 1.63671 56.9213 0.860099C69.2894 0.0319282 82.6406 2.68079 93.267 8.93836C116.271 22.5366 129.048 50.5368 122.547 75.7523Z" stroke="white" stroke-width="1.5"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_10540_68670">
                    <rect width="125" height="125" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
        <div className="relative w-[100%] h-[153px] p-[16px] bg-[#F5D781] rounded-[8px]">
            <div>
                <div className="text-[#373545] font-bold w-[172px]">Family & Personal Health Plan</div>
                <div className="mt-[25px]">
                    <div className="text-[#373545] text-[10px]">starting from</div>
                    <div className="text-[24px] font-bold">N182,000</div>
                </div>
                <div >

                </div>
            </div>
            <div className='absolute top-0 right-0'>
                <svg xmlns="http://www.w3.org/2000/svg" width="304" height="86" viewBox="0 0 304 86" fill="none">
                    <path opacity="0.5" d="M0.0613352 -31.1028C24.1381 -24.7478 39.0326 -21.0466 59.9384 -15.3943C85.8196 -63.0503 97.1366 -114.953 198.34 -139.217C148.431 -85.4134 129.82 -35.0902 201.486 10.0719C270.635 53.8019 358.403 61.5238 457.31 40.3565C385.699 81.084 300.687 93.2753 207.276 81.244C104.015 68.0132 40.4931 29.3175 0.0613352 -31.1028Z" fill="#251A6B"/>
                </svg>
            </div>
            <div className='absolute bottom-0 right-0'>
                <svg xmlns="http://www.w3.org/2000/svg" width="125" height="111" viewBox="0 0 125 111" fill="none">
                    <g clip-path="url(#clip0_10540_68670)">
                    <path opacity="0.5" d="M38.1751 64.5272L38.1766 64.5234C43.8939 49.9989 56.9021 41.0271 71.0569 41.4173L71.0578 41.4173C85.924 41.8078 98.4244 51.6584 103.038 67.0576C103.861 69.9166 104.287 72.8991 104.584 74.9781C104.682 75.6644 104.766 76.2523 104.845 76.7048C104.821 96.9831 93.3586 112.275 77.7557 115.775C62.2713 119.195 47.7935 112.373 39.9791 97.7694C34.2613 86.9725 33.6618 75.8122 38.1751 64.5272Z" stroke="white" stroke-width="1.5"/>
                    <path opacity="0.5" d="M7.80249 34.4839C8.37823 36.0476 8.8985 37.4436 9.39504 38.776C10.363 41.3732 11.2408 43.7286 12.2638 46.6136L12.5541 47.4322L13.3216 47.0257C15.7588 45.7352 18.1636 44.3842 20.558 43.0391C34.8655 35.001 48.802 27.1714 67.0749 33.7073C56.4443 37.1737 47.1197 41.609 40.1902 47.9712C32.7934 54.7624 28.1631 63.7154 27.5403 75.9005C26.9202 87.6339 29.583 97.6157 35.1603 105.909C40.1812 113.375 47.5354 119.431 56.8902 124.169C36.2313 123.188 20.747 113.361 10.0312 96.3233L10.0307 96.3226C-2.15618 76.9944 -1.82011 56.9143 7.80249 34.4839Z" stroke="white" stroke-width="1.5"/>
                    <path opacity="0.5" d="M122.547 75.7523C121.265 55.5629 117.905 37.3987 100.528 24.5757L98.5074 23.0849L99.3791 25.4396C100.565 28.6431 101.994 31.7716 103.435 34.8953C103.521 35.0803 103.606 35.2653 103.692 35.4503C105.052 38.3966 106.413 41.3437 107.605 44.3875C109.726 49.8031 111.298 55.4928 111.337 61.8985C103.374 40.5621 93.6195 27.3389 80.4373 19.8313C66.9417 12.1452 49.986 10.5286 28.0129 12.0967C34.7046 5.46035 45.3232 1.63671 56.9213 0.860099C69.2894 0.0319282 82.6406 2.68079 93.267 8.93836C116.271 22.5366 129.048 50.5368 122.547 75.7523Z" stroke="white" stroke-width="1.5"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_10540_68670">
                    <rect width="125" height="125" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
            
        </div>
        <div className="relative w-[100%] h-[153px] p-[16px] bg-[#F6C3C3] rounded-[8px]">
            <div>
                <div className="text-[#373545] font-bold w-[172px]">Family & Personal Health Plan</div>
                <div className="mt-[25px]">
                    <div className="text-[#373545] text-[10px]">starting from</div>
                    <div className="text-[24px] font-bold">N182,000</div>
                </div>
                <div className='absolute top-0 right-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="304" height="86" viewBox="0 0 304 86" fill="none">
                        <path opacity="0.5" d="M0.0613352 -31.1028C24.1381 -24.7478 39.0326 -21.0466 59.9384 -15.3943C85.8196 -63.0503 97.1366 -114.953 198.34 -139.217C148.431 -85.4134 129.82 -35.0902 201.486 10.0719C270.635 53.8019 358.403 61.5238 457.31 40.3565C385.699 81.084 300.687 93.2753 207.276 81.244C104.015 68.0132 40.4931 29.3175 0.0613352 -31.1028Z" fill="#251A6B"/>
                    </svg>
                </div>
                <div className='absolute bottom-0 right-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="111" viewBox="0 0 125 111" fill="none">
                        <g clip-path="url(#clip0_10540_68670)">
                        <path opacity="0.5" d="M38.1751 64.5272L38.1766 64.5234C43.8939 49.9989 56.9021 41.0271 71.0569 41.4173L71.0578 41.4173C85.924 41.8078 98.4244 51.6584 103.038 67.0576C103.861 69.9166 104.287 72.8991 104.584 74.9781C104.682 75.6644 104.766 76.2523 104.845 76.7048C104.821 96.9831 93.3586 112.275 77.7557 115.775C62.2713 119.195 47.7935 112.373 39.9791 97.7694C34.2613 86.9725 33.6618 75.8122 38.1751 64.5272Z" stroke="white" stroke-width="1.5"/>
                        <path opacity="0.5" d="M7.80249 34.4839C8.37823 36.0476 8.8985 37.4436 9.39504 38.776C10.363 41.3732 11.2408 43.7286 12.2638 46.6136L12.5541 47.4322L13.3216 47.0257C15.7588 45.7352 18.1636 44.3842 20.558 43.0391C34.8655 35.001 48.802 27.1714 67.0749 33.7073C56.4443 37.1737 47.1197 41.609 40.1902 47.9712C32.7934 54.7624 28.1631 63.7154 27.5403 75.9005C26.9202 87.6339 29.583 97.6157 35.1603 105.909C40.1812 113.375 47.5354 119.431 56.8902 124.169C36.2313 123.188 20.747 113.361 10.0312 96.3233L10.0307 96.3226C-2.15618 76.9944 -1.82011 56.9143 7.80249 34.4839Z" stroke="white" stroke-width="1.5"/>
                        <path opacity="0.5" d="M122.547 75.7523C121.265 55.5629 117.905 37.3987 100.528 24.5757L98.5074 23.0849L99.3791 25.4396C100.565 28.6431 101.994 31.7716 103.435 34.8953C103.521 35.0803 103.606 35.2653 103.692 35.4503C105.052 38.3966 106.413 41.3437 107.605 44.3875C109.726 49.8031 111.298 55.4928 111.337 61.8985C103.374 40.5621 93.6195 27.3389 80.4373 19.8313C66.9417 12.1452 49.986 10.5286 28.0129 12.0967C34.7046 5.46035 45.3232 1.63671 56.9213 0.860099C69.2894 0.0319282 82.6406 2.68079 93.267 8.93836C116.271 22.5366 129.048 50.5368 122.547 75.7523Z" stroke="white" stroke-width="1.5"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_10540_68670">
                        <rect width="125" height="125" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
        <div className="relative w-[100%] h-[153px] p-[16px] bg-[#B7E7D6] rounded-[8px]">
            <div>
                <div className="text-[#373545] font-bold w-[172px]">Family & Personal Health Plan</div>
                <div className="mt-[25px]">
                    <div className="text-[#373545] text-[10px]">starting from</div>
                    <div className="text-[24px] font-bold">N182,000</div>
                </div>
                <div className='absolute top-0 right-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="304" height="86" viewBox="0 0 304 86" fill="none">
                        <path opacity="0.5" d="M0.0613352 -31.1028C24.1381 -24.7478 39.0326 -21.0466 59.9384 -15.3943C85.8196 -63.0503 97.1366 -114.953 198.34 -139.217C148.431 -85.4134 129.82 -35.0902 201.486 10.0719C270.635 53.8019 358.403 61.5238 457.31 40.3565C385.699 81.084 300.687 93.2753 207.276 81.244C104.015 68.0132 40.4931 29.3175 0.0613352 -31.1028Z" fill="#251A6B"/>
                    </svg>
                </div>
                <div className='absolute bottom-0 right-0'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="111" viewBox="0 0 125 111" fill="none">
                        <g clip-path="url(#clip0_10540_68670)">
                        <path opacity="0.5" d="M38.1751 64.5272L38.1766 64.5234C43.8939 49.9989 56.9021 41.0271 71.0569 41.4173L71.0578 41.4173C85.924 41.8078 98.4244 51.6584 103.038 67.0576C103.861 69.9166 104.287 72.8991 104.584 74.9781C104.682 75.6644 104.766 76.2523 104.845 76.7048C104.821 96.9831 93.3586 112.275 77.7557 115.775C62.2713 119.195 47.7935 112.373 39.9791 97.7694C34.2613 86.9725 33.6618 75.8122 38.1751 64.5272Z" stroke="white" stroke-width="1.5"/>
                        <path opacity="0.5" d="M7.80249 34.4839C8.37823 36.0476 8.8985 37.4436 9.39504 38.776C10.363 41.3732 11.2408 43.7286 12.2638 46.6136L12.5541 47.4322L13.3216 47.0257C15.7588 45.7352 18.1636 44.3842 20.558 43.0391C34.8655 35.001 48.802 27.1714 67.0749 33.7073C56.4443 37.1737 47.1197 41.609 40.1902 47.9712C32.7934 54.7624 28.1631 63.7154 27.5403 75.9005C26.9202 87.6339 29.583 97.6157 35.1603 105.909C40.1812 113.375 47.5354 119.431 56.8902 124.169C36.2313 123.188 20.747 113.361 10.0312 96.3233L10.0307 96.3226C-2.15618 76.9944 -1.82011 56.9143 7.80249 34.4839Z" stroke="white" stroke-width="1.5"/>
                        <path opacity="0.5" d="M122.547 75.7523C121.265 55.5629 117.905 37.3987 100.528 24.5757L98.5074 23.0849L99.3791 25.4396C100.565 28.6431 101.994 31.7716 103.435 34.8953C103.521 35.0803 103.606 35.2653 103.692 35.4503C105.052 38.3966 106.413 41.3437 107.605 44.3875C109.726 49.8031 111.298 55.4928 111.337 61.8985C103.374 40.5621 93.6195 27.3389 80.4373 19.8313C66.9417 12.1452 49.986 10.5286 28.0129 12.0967C34.7046 5.46035 45.3232 1.63671 56.9213 0.860099C69.2894 0.0319282 82.6406 2.68079 93.267 8.93836C116.271 22.5366 129.048 50.5368 122.547 75.7523Z" stroke="white" stroke-width="1.5"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_10540_68670">
                        <rect width="125" height="125" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AllPlans