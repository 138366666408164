import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PricingCard, AuthButton, Form, CustomFormControl, usePaystack, ActionModal, PlanModal, CustomSelect } from '../../index';
import { fetchHospitalList, fetchHospitalLocation, getHospitals } from '../../../store/hospitals';
import { useDispatch, useSelector } from 'react-redux';
import { addAmount, addHospital, addMaritalStatus, setPrice } from '../../../store/userData';
import { TextField } from '@mui/material';
import { FaCheck } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchPromo, postBuyOther } from '../../../utils/helper';

function BasicPlan() {
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [maritalStatus, setMaritalStatus] = useState("single");
    const [planType, setPlanType] = useState([]);
    const [sex, setSex] = useState('');
    const [pickedHospital, setPickedHospital] = useState({});
    const [hospitalLocation, setHospitalLocation] = useState("");
    const { list, locations, } = useSelector(getHospitals);
    const [hospital, setHospital] = useState(null);
    const [status, setStatus] = useState("");
    const [message, setMessage] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem("token");
    const [cost, setCost] = useState('');
    const [open, setOpen] = useState(false);
    const [buyOther, setBuyOther] = useState(null);
    const [promo, setPromo] = useState('');
    const [currentPlan, setCurrentPlan] = useState({
        name: "",
        price: ""
    });
    const routePath = useLocation();
    const [planLoad, setPlanLoad] = useState(false);
    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        dispatch(fetchHospitalLocation());
    }, [dispatch]);

    useEffect(() => {
        dispatch(addAmount(0));
        fetchPromo(setPromo);
    }, [promo]);

    useEffect(() => {
        onTop()
    }, [routePath]);
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log("closed");
    };

    const handlePayment = usePaystack();

    const fetchPlanStatus = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/verify_plan`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            });
            console.log(data);
            setStatus(data?.status);
            setMessage(data?.message);
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "bottom-right"
            });
        }
    }

    useEffect(() => {
        fetchPlanStatus();
    }, []);
    useEffect(() => {
        if ((currentPlan?.name != "")) {
            console.log(currentPlan?.price[maritalStatus]);
            dispatch(addAmount(currentPlan?.price[maritalStatus]));
        }
        if (currentPlan?.name == "BASIC") {
            console.log(currentPlan?.price);
            dispatch(addAmount(currentPlan?.price));
        }
    }, [currentPlan, maritalStatus]);

    const postBasic = async (obj) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/basic_plan`,
                obj, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            });

            toast.success(data?.message, {
                position: "bottom-right"
            });
            history.push('/dashboard/wellnesshmo');

        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "bottom-right"
            });
        }
    }

    // console.log(process.env.NODE_ENV)

    const onSuccess = (reference) => {
        if (buyOther == 'myself') {
            let obj = {
                user_id: userData?.id,
                basic_plan_demographics: JSON.stringify(planType),
                price: cost,
                status: "pending",
                sex: sex,
                transaction_id: reference.trxref
            }
            postBasic(obj);

        } else if (buyOther == 'other') {
            let obj = {
                request_type: "basic",
                data: {
                    first_name: firstName,
                    last_name: lastName,
                    phone: phone,
                    email: email,
                    request_type: "basic",
                    service_name: "basic",
                    services: JSON.stringify(planType),
                    amount_paid: cost,
                    hospital_name: pickedHospital?.name,
                    hospital_location: pickedHospital?.location,
                    hospital_address: pickedHospital?.address,
                    doctor_name: "",
                    comment: "",
                    appointment_date: "",
                    has_promo: promo ? true : false,
                    transaction_id: reference.trxref
                }

            }
            postBuyOther(obj, toast, history);
        }

    };


    const fetchType = async (discount) => {
        setPlanLoad(true);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/basic_plan/benefits`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            });

            setPlanType(data.benefits);
            setCurrentPlan({
                name: 'basic',
                price: data.price
            })
            setCost(data.price - discount);
            dispatch(addAmount(data.price - discount));
            dispatch(setPrice(data.price - discount));
            setPlanLoad(false);
        } catch (err) {
            setPlanLoad(false);
        }
    }

    useEffect(() => {
        if (promo) {
            fetchType(promo);
        } else {
            fetchType(0);
        }

    }, [promo]);
    dispatch(addMaritalStatus(maritalStatus));
    dispatch(addHospital(hospital));


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!status && buyOther == 'myself') {
            toast.success(message, {
                position: "bottom-right"
            });
        } else {
            handlePayment(onSuccess, onClose);
        }


    };

    const hanldeSetHospital = (value) => {
        console.log(value);
        const item = list.find((item) => item.name === value);
        const values = {};
        setPickedHospital(item);
    };

    const handleHospitalLocationChange = ({ target }) => {
        setHospitalLocation(target.value);
        dispatch(fetchHospitalList(target.value));
    };

    return (
        <div className='w-full min-h-[90vh] bg-white'>
            {open && <PlanModal open={open} close={() => setOpen(false)} plandata={planType} modalTitle={"Basic Plan Benefit"} />}
            <div className='bg-[#f6c3c3] w-full h-[100px]'></div>
            <div className='p-[20px]'>
                <div className="flex items-center"></div>
                <div className='lg:flex  lg:justify-between flex-column lg:flex-row'>
                    <div className='w-full lg:w-[50%]'>
                        <div className="text-[24px] text-[#E6072C] font-bold">Buy Basic Plan</div>
                        <div className="text-[16px] text-[#5A5454]">Please fill the form to proceed</div>
                        <div>
                            <div className='font-bold '>Plan Details</div>
                            <div className='font-bold mb-2'>Benefits</div>
                            <div className="lg:hidden cursor-pointer" onClick={() => setOpen(true)}>View all</div>
                            <div className="hidden lg:block">
                                {planType.map((data, index) => {
                                    return (
                                        <div className='flex items-center mb-4'>
                                            <FaCheck className="w-[40px]" size={24} color={"#DBD5FC"} />
                                            <div className='ml-[12px] max-w-[300px]'>{data}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit} className="py-5 lg:px-[30px] w-full lg:w-[50%] sticky top-0">
                        <CustomSelect
                            label={'Buy For'}
                            value={buyOther}
                            onChange={({ target }) => setBuyOther(target.value)}
                            loading={false}
                            required
                            items={[
                                { value: "myself", label: "Myself" },
                                { value: "other", label: "Others" },
                            ]}
                        />
                        {
                            buyOther == 'myself' &&
                            <>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={`id-${userData.first_name}`}
                                        label={'Name'}
                                        variant="outlined"
                                        value={`${userData.first_name}${' '} ${userData.last_name}`}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={`id-${userData.email}`}
                                        label={'Email'}
                                        variant="outlined"
                                        value={`${userData.email}`}

                                    />
                                </CustomFormControl>
                                <CustomSelect
                                    label="Sex"
                                    value={sex}
                                    onChange={({ target }) => setSex(target.value)}
                                    loading={false}
                                    items={[
                                        { value: "male", label: "Male" },
                                        { value: "female", label: "Female" },
                                    ]}
                                />
                            </>
                        }

                        {
                            buyOther == 'other' &&
                            <>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'FirstName'}
                                        label={'First Name'}
                                        variant="outlined"
                                        value={firstName}
                                        required
                                        onChange={({ target }) => setFirstName(target.value)}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'LastName'}
                                        label={'Last Name'}
                                        variant="outlined"
                                        value={lastName}
                                        required
                                        onChange={({ target }) => setLastName(target.value)}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'Phone'}
                                        label={'Phone Number'}
                                        variant="outlined"
                                        value={phone}
                                        required
                                        onChange={({ target }) => setPhone(target.value)}
                                    />
                                </CustomFormControl>
                                <CustomFormControl styles={{ mb: 0, mt: 2 }}>
                                    <TextField
                                        id={'email'}
                                        label={'Email Address'}
                                        variant="outlined"
                                        value={email}
                                        required
                                        onChange={({ target }) => setEmail(target.value)}
                                    />
                                </CustomFormControl>
                                <div>
                                    <CustomSelect
                                        label="Hospital Location"
                                        value={hospitalLocation}
                                        onChange={handleHospitalLocationChange}
                                        loading={loading}
                                        items={locations}
                                    />
                                    <CustomSelect
                                        label="Hospital"
                                        value={hospital}
                                        onChange={({ target }) => hanldeSetHospital(target.value)}
                                        loading={loading}
                                        items={list}
                                    />
                                </div>
                            </>
                        }

                        <div className="pb-10">
                            <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
                        </div>
                        <PricingCard disabled={true} />
                        {promo &&
                            <div className='flex'>
                                <div>{promo}% discount</div>
                                <div className='ml-[10px] line-through font-bold'>N{currentPlan?.price}</div>
                            </div>}
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default BasicPlan
