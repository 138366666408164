import React from "react";
import dependents from '../../../assets/images/dependents.svg';
import hospital from '../../../assets/images/hospital-fill.svg';
import video from '../../../assets/images/video-camera.svg';
import plan from '../../../assets/images/plan.svg';
import Card from "../../common/Card";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";


const Dashboard = () => {
  const baseUrl = "/dashboard";
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('user'));
  let isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
  isEnrollee = isEnrollee?.insurance_no;
  return (
    <div className="px-[36px] w-full h-[100vh] bg-[#ffffff]">
      <div className="mb-[10px]  lg:mb-10 py-[20px]">
          <>
            <div>
              <button className="cursor-pointer" onClick={() => history.goBack()}>
                <IoIosArrowBack size={30} />
              </button>
            </div>
            <div className="text-[28px]">
              Hi {userData.first_name} <br />
              Which of these actions would you like to perform?
            </div>
          </>
      </div>
 
      <div>
        <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <Card
            title={"Explore Wellness HMO"}
            subtitle={"View our health insurance plan, benefits and services"}
            image={dependents}
            link={`${baseUrl}/wellnesshmo`}
          />
          
          { 
          !isEnrollee ?
          <Card
            title={"Call a doctor"}
            subtitle={"We are here should you need help"}
            image={plan}
            link={`${baseUrl}/contact`}
          />
          :
          <Card
            title={"Telemedicine"}
            subtitle={"We are here should you need help"}
            image={plan}
            link={`${baseUrl}/telemedicine`}
          />
          }
          
          <Card
            title={"Do your Medical Checkup"}
            subtitle={"Optical checkup, Dental checkup and your full body checkup"}
            image={video}
            link={`${baseUrl}/medical-checkup`}
          />
          {
            !isEnrollee && 
            <Card
              title={"Buy your HMO Plan"}
              subtitle={"Create your own health insurance plan"}
              image={hospital}
              link={`${baseUrl}/wellnesshmo/buy-your-plan`}
            />
          }
        </div>
        
      </div>
      
    </div>
  )
};

export default Dashboard;
