import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sidebarNavBarStyles } from "../../../styles/styles";
import styles from "../index.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowRight";

const SelfServiceCard = ({ title, subtitle, image }) => {
  const classes = sidebarNavBarStyles();
  const history = useHistory();
  let isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
  isEnrollee = isEnrollee?.insurance_no;

  return (
    <div
      className={styles["rightSide"]}
      onClick={() => {
        if(isEnrollee) {
          history.push('/dashboard/contact');
        }else {
          history.push(
            title === "Dental Care"
              ? `/dashboard/medical-checkup/dental-care/${"create-dental-care"}`
              : title === "Optical Care"
              ? `/dashboard/medical-checkup/optical-care/${"create-optical-care"}`
              : title === "Health Checkup"
              ? "/dashboard/medical-checkup/health-care/create-health-care"
              : null
          );
        }
        
      }}
    >
      <div className={styles["combined"]}>
        <div className={styles["image-view"]}>
          <img src={image} alt="SA" className={classes.rightImage} />
          <div className={styles["rightSide-header"]}>
            <div className={styles["rightSide-title"]}>{title}</div>
            <div className={styles["rightSide-subtitle"]}>{subtitle}</div>
          </div>
        </div>
        <ArrowBackIcon color="disabled" onClick={() => {}} className={classes.closeIcon} />
      </div>
    </div>
  );
};

export default SelfServiceCard;
