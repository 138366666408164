import React, { useEffect } from "react";
import styles from "./index.module.css";
import Process from "./Process";
import { FormContainer } from "../../Containers";
import CreatePlan from "./widgets/CreatePlan";
import Biodata from "./widgets/Biodata";
import MedicalService from "./widgets/MedicalService";
import MedicalServiceBenefits from "./widgets/MedicalServiceBenefits";
import { useParams } from "react-router-dom";
import { fetchPromo, getBuyPlanSteps, setSteps } from "../../../utils/helper";
import { BUY_PLAN_STEPS_TITLE } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitalLocation } from "../../../store/hospitals";
import { getInsuranceCalculator, stopLoading } from "../../../store/insuranceCalculator";
import { Alert, ModalLoader } from "../../common";
import { getPlanSteps } from "../../../store/steps";
import _, { pick } from "lodash";
import Dependents from "./widgets/Dependents";
import { IoArrowBackOutline } from "react-icons/io5";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import Plan from "../../common/Card/plan";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { AiOutlineWoman } from "react-icons/ai";
import { addAmount } from "../../../store/userData";
import { toast } from "react-toastify";
import { useState } from "react";

const BuyPlan = () => {
  const { values } = useSelector(getPlanSteps);
  // const { sex, type } = useSelector(getUserInfo);
  // const [steps, setSteps] = useState(values);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentStep = getBuyPlanSteps(params.step);
  const { loading, error } = useSelector(getInsuranceCalculator);
  const [buyOther, setBuyOther] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [pickedHospital, setPickedHospital] = useState('');
  const [promo, setPromo] = useState('');



  useEffect(() => {
    
    fetchPromo(setPromo);
}, [promo]);

  useEffect(() => {
    if (error) Alert.notication("error", "Error", error);
    setSteps(values, currentStep, dispatch);
    dispatch(fetchHospitalLocation());
    dispatch(stopLoading());
  }, [dispatch, error]);


  return (
    <>
      {
        !params.step &&
        <div className="bg-white w-full min-h-[90vh] p-[20px]">
          <div className="mb-[10px] lg:mb-10 flex items-center py-[20px]">
            {currentStep !== BUY_PLAN_STEPS_TITLE.CREATE_YOUR_PLAN && (
              <>
                <button className={styles["back-btn"]} onClick={() => history.push('/dashboard/wellnesshmo')}>
                  <IoArrowBackOutline />
                </button>
                <p className="ml-1.5">Back</p>
              </>
            )}
          </div>
          <div>
            <div className="text-[24px] text-[#E6072C] font-bold">Buy Plan</div>
            <div className="text-[16px] text-[#5A5454]">Please select a plan type category to proceed</div>
            <div className="w-full flex justify-center items-center">
              <div className="w-[100%] lg:w-[60%] mt-3">
                <Plan
                  icon={<MdOutlineCreateNewFolder size={36} color="#E6072C" />}
                  link={"/dashboard/wellnesshmo/buy-your-plan/create-your-plan"}
                  title={"Create a Plan"}
                  subtitle={"Configure your health plan based on your need"}
                />
                <Plan
                  icon={<BiSolidPurchaseTagAlt size={36} color="#59D122" />}
                  link={"/dashboard/wellnesshmo/basic-plan"}
                  title={"Buy Wellness Basic Plan"}
                  subtitle={"An overview of Wellness Basic Plan"}
                />
                <Plan
                  icon={<AiOutlineWoman size={36} color="#4B3BAE" />}
                  link={"/dashboard/wellnesshmo/standard-plan"}
                  title={"Buy Wellness Standard Plan"}
                  subtitle={"An overview of Wellness Standard Plan"}
                />
              </div>

            </div>
          </div>
        </div>
      }

      {
        params.step &&
        <div className={styles["plan-wrapper"]}>
          <div className="mb-[10px] lg:mb-10 flex items-center my-[20px]">
            {currentStep !== BUY_PLAN_STEPS_TITLE.CREATE_YOUR_PLAN && (
              <>
                <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                  <IoArrowBackOutline />
                </button>
                <p className="ml-1.5">Back</p>
              </>
            )}
          </div>
          <div className={styles["plan-container"] + 'block m-0 lg:flex'}>
            <ModalLoader visible={loading} />
            <div className="w-full hidden sm:block">
              <Process processes={values} />
            </div>
            {/* <div className="w-full"> */}
            <FormContainer className={styles["form-container"]}>
              {currentStep === BUY_PLAN_STEPS_TITLE.CREATE_YOUR_PLAN && 
              <CreatePlan 
                buyOther={buyOther} 
                setBuyOther={setBuyOther} 
                firstName={firstName} 
                setFirstName={setFirstName}
                lastName={lastName} 
                setLastName={setLastName}  
                setEmail={setEmail}
                email={email}
                phone={phone}
                setPhone={setPhone}
                pickedHospital={pickedHospital}
                setPickedHospital={setPickedHospital}
                />}
              {currentStep === BUY_PLAN_STEPS_TITLE.BIODATA && <Biodata buyOther={buyOther} />}
              {currentStep === BUY_PLAN_STEPS_TITLE.DEPENDENT && <Dependents />}
              {currentStep === BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES && <MedicalService />}
              {currentStep === BUY_PLAN_STEPS_TITLE.MEDICAL_SERVICES_BENEFITS && 
              <MedicalServiceBenefits 
                buyOther={buyOther} 
                setBuyOther={setBuyOther} 
                promo={promo} 
                setPromo={setPromo} 
                firstName={firstName} 
                lastName={lastName} 
                email={email}
                phone={phone}
                pickedHospital={pickedHospital}
                setPickedHospital={setPickedHospital}
                />}
            </FormContainer>
            {/* </div> */}
          </div>
        </div>
      }
    </>

  );
};

export default BuyPlan;
