import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import CustomSelect from "../../../../../common/Select";
import AuthButton from "../../../../../Containers/AuthButton";
import Form from "../../../../../Containers/Form";
import PricingCard from "../../../../../UI/PricingCard";
import SingleSelect from "../../../../../common/Select/SingleSelect";
import { BUY_PLAN_STEPS_TITLE, DENTAL_CARE_URLS, DENTAL_SERVICES } from "../../../../../../utils/constants";
import { resetSteps, setSteps } from "../../../../../../utils/helper";

import { addField, getUserInfo } from "../../../../../../store/userData";
import { getDentCare } from "../../../../../../store/dentalCare";

import styles from "../index.module.css";

const validateBiodata = (obj) => {
  const keys = Object.keys(obj);
  const isError = keys.every((key) => !obj[key].value);
  return isError;
};

const StepTwo = ({allValue, allField, setAllValue,setAllField,setStep}) => {
  const { fields } = useSelector(getUserInfo);
  const [values, setValues] = useState(fields);
  const [dependent, setDependent] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [option, setOption] = useState("");
  const [data, setData] = useState([]);
  const { dentalCareValue, loading } = useSelector(getDentCare);

  useEffect(() => {
    const newData = dentalCareValue.filter((value) => DENTAL_SERVICES.includes(value.name.toLowerCase()));
    const initialValues = _.cloneDeep(fields);
    for (const data of newData) {
      initialValues[data.name] = { field: "", value: "", type: "dental", ans: "" };
    }
    setData(newData);
    setValues(initialValues);
    dispatch(addField(initialValues));
  }, [dentalCareValue, history, dispatch]);

  const handleOptionChange = (value, name, price) => {
    const newValues = _.cloneDeep(values);
    setOption(value);
    newValues[name].field = name;
    newValues[name].value = value === "Yes" ? price[1] : price[0];
    newValues[name].ans = value;
    let newArrField;
    let newArrValue;
    if(allField.includes(name)) {
    newArrField = allField?.filter((d,index) => {
        if(d !== name) {
          return d;
        }
    });

    setAllField([...newArrField,name])
    
    }else {
      setAllField([...allField,name])
    }


    if(allValue.includes(name)) {
      newArrValue = allValue?.filter((d,index) => {
          if(d !== value) {
            return d;
          }
      });
  
      setAllValue([...newArrValue,value])
      
      }else {
        setAllValue([...allValue,value])
      }
    setValues(newValues);
    dispatch(addField(newValues));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(3);
    // history.push(DENTAL_CARE_URLS.OTHER_SERVICES_TWO);
  };

  return (
    <div className="py-5 w-full">
      <div className={styles["others-service"]}>Other Services</div>
      <Form onSubmit={handleSubmit}>
        {data.length > 0 ? (
          <>
            {data.map((dataOption, index) => (
              <div className="w-full" key={index}>
                {dataOption.name !== "Dental Check Up" ? (
                  <CustomSelect
                    key={dataOption.name + index}
                    label={dataOption.name}
                    items={dataOption.value?.names}
                    value={option}
                    onChange={({ target }) =>
                      handleOptionChange(target.value, dataOption.name, dataOption.value?.prices)
                    }
                  />
                ) : null}
              </div>
            ))}
          </>
        ) : (
          <p>No Info</p>
        )}
        <AuthButton className="my-5 bg-[#EC4D67]">Proceed</AuthButton>
        <PricingCard disabled={true} />
      </Form>
    </div>
  );
};

export default StepTwo;
