import styles from "./index.module.css";
import RightSide from "./ui/RightSide";
import SelfServiceCard from "./ui/SelfServiceCard";
import brokenTooth from "../../../assets/images/broken-tooth.png";
import cardiogram from "../../../assets/images/cardiogram.png";
import eye from "../../../assets/images/eye.png";
import { Avatar } from "@mui/material";
import { sidebarNavBarStyles } from "../../styles/styles";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAmount, setResetInfo } from "../../../store/userData";
import { toast } from "react-toastify";
import { resetDentalCare } from "../../../store/dentalCare";
import { resetOpticalCare } from "../../../store/opticalCare";

const OpticalCare = () => {
  const classes = sidebarNavBarStyles();
  const userData = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem("token");
  const [appointmentData, setAppointmentData] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setResetInfo());
    dispatch(resetDentalCare());
    dispatch(resetOpticalCare());
  });
  const fetchAppointment = async()=> {
    let type = 'upcoming';
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v2/appointments/history/${type}`,
      {
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      })
      let newdata = data?.data?.data.slice(Math.max(data?.data?.data?.length - 4, 0));
      setAppointmentData(newdata[0]);
    }catch(err) {
      //toast.error('Network Error! Refresh page');
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAppointment();
  },[]);

  return (
    <div className={styles["plan-wrapper"]}>
      <div className={styles["leftView"]}>
        <Avatar
          className={classes.small}
          src="https://res.cloudinary.com/dycyotaya/image/upload/v1629366830/IMG_20210705_144144_255_pbxn8l.jpg"
          alt="SA"
        />
        <div className={styles["leftView-title-section"]}>
          <div className={styles["leftView-title"]}>Welcome, {userData.first_name}</div>
          <div className={styles["leftView-subtitle"]}>How are you feeling today?</div>
        </div>
      </div>
      <div className=" mb-[10px] block md:hidden">
        <div className="font-bold">Your next Appointment Date is {appointmentData?.appointment_date}</div>
        <div onClick={() => history.push('/dashboard/profile')} className="text-[#9782FE] cursor-pointer underline">
          View Appointment Details 
        </div>
      </div>
      <div className="flex justify-between w-full md:flex-col lg:flex-row">
        <div className={styles["leftSideView"] }>
          <div className="w-full ">
            <RightSide appointmentDate={appointmentData?.appointment_date} />
          </div>
        </div>
        <div className={styles["rightSideView"]}>
          <div className={styles["selfservice"]}>Self Service</div>
          <SelfServiceCard
            title={"Dental Care"}
            subtitle={"Get access to credible dental services here."}
            image={brokenTooth}
          />
          <SelfServiceCard
            title={"Optical Care"}
            subtitle={"Get access to credible optical services here"}
            image={eye}
          />
          <SelfServiceCard
            title={"Health Checkup"}
            subtitle={"View our list of Medical Checkup Services"}
            image={cardiogram}
          />
        </div>
      </div>   
    </div>
  );
};

export default OpticalCare;
