import React from "react";
import styles from "./index.module.css";

const AuthButton = ({ onClick, className = "", children }) => {
  return (
    <button onClick={onClick} className={`${className} ${styles["auth-button"]}`} type="submit">
      {children}
    </button>
  );
};

export default AuthButton;
