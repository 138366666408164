import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from "./index.module.css";
import { AuthButton, Form, FormContainer } from '../../Containers';
import { ModalLoader } from '../../common';
import { IoArrowBackOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';


let currentOTPIndex = 0;


function HomeVisit() {
  const [otp, setOtp] = useState(new Array(6).fill(""))
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const user = localStorage.getItem('user');
  const inputRef = useRef(null);
  const myOtp = JSON.parse(localStorage.getItem('otp'));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnChange = ({target},index) => {
    const {value} = target;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);  
    
    if(!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);     
    setOtp(newOTP);
  }

  useEffect(() => {
      inputRef.current?.focus();
      console.log(otp.join(""));
  },[activeOTPIndex]);

  const handleRegister = (e) => {
    e.preventDefault();
    
  };

  const handleOnKeyDown = ({key},index) => {
    currentOTPIndex = index;
    if(key == 'Backspace') setActiveOTPIndex(currentOTPIndex - 1);
  }


  return (
  <div className={styles["plan-wrapper"]}>
    <ModalLoader visible={loading} />
    <div className="mb-10 flex items-center">
      <button className={styles["back-btn"]} onClick={() => history.goBack()}>
        <IoArrowBackOutline />
      </button>
      <p className="ml-1.5">Back</p>
    </div>
    <div className={styles["container"] + 'block lg:flex lg:justify-between'}> 
      <div className={styles["title-container"]}>
        <p className={styles["title"]}>Home Visit</p>
        <p className={styles["sub-title"]}>
          Generate a code to map this transaction to a doctor
        </p>
      </div>
      <div className='bg-white w-full lg:w-[50%]  rounded lg:ml-2'>
        <div>
        <div >
          <FormContainer >
            
            <Form className={`${styles["form"]} h-[200px] flex flex-col justify-center `} onSubmit={handleRegister}>
              <div className={styles["form-control"]}>
                <div className="flex items-center justify-center space-x-2 mt-[9px]">
                  {otp.map((_, index) => {
                      return (
                      <React.Fragment key={index}>
                        <input
                          ref={index == activeOTPIndex ? inputRef : null}
                          type="number"
                          className="w-[43px] h-[42px] border rounded-[16px] bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-[#D0D5DD] focus:border-[#F04438] focus:text-gray-700 text-gray-400 transition"
                          onChange={handleOnChange}
                          onKeyDown = {(e) => handleOnKeyDown(e,index)}
                          value={otp[index]}
                        />
                      </React.Fragment>
                      );
                  })}
                </div>
              </div>
              <div className="flex justify-between"></div>
              <div className='flex flex-col mt-[20px]'>
                <AuthButton className="bg-[#EC4D67]">Proceed</AuthButton>
                <div className="flex items-center mt-3 justify-center"></div>
              </div>
            </Form>
          </FormContainer>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default HomeVisit