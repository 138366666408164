import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import goolePlayIcon from "../../../assets/images/google-play.png";
import mobile from "../../../assets/images/mobile.png";

const LeftSide = () => {
  return (
    <div className={`${styles["left-container"]}`}>
      <h1 className="text-xl font-bold text-white">The Power to Choose</h1>
      <div className={styles["image-container"]}>
        <Link>
          <img
            className={styles["play-img"]}
            src={goolePlayIcon}
            alt="google play"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.hazonholdings.android.wellnesspatientapp",
                "_blank"
              )
            }
          />
        </Link>
      </div>
      <div className={styles["mobile-container"]}>
        <img className={styles["mobile-img"]} src={mobile} alt="mobile" />
      </div>
    </div>
  );
};

export default LeftSide;
