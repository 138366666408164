import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { FaCheck } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import ModalLoader from '../../../common/Loader';

function AllBenefit() {
  //const benefits = JSON.parse(localStorage.getItem("benefits"));
  const [benefits, setBenefits] = useState([]);
  console.log(benefits);
  const routePath = useLocation();
  let isEnrollee = JSON.parse(localStorage.getItem("enrollee"));
  isEnrollee = isEnrollee?.insurance_no;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
      onTop()
  }, [routePath]);

  const fetchAllBenefit = async() => {
    setLoading(true);
    console.log(isEnrollee)
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/v1/users/enrollees/retrieve`,
      {
        insurance_no: isEnrollee
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization':`${token}`
        }
      },
      ) 
      setLoading(false);
      setBenefits(data?.data?.benefit?.benefits);
    }catch(err) {
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAllBenefit();
  },[]);


  return (
    <div className='bg-white w-full h-[100vh]'>
      <ModalLoader visible={loading} />
      <div className='bg-[#DBD5FC] w-full h-[100px]'></div>
      <div className='pl-[20px]'>
        <div className='font-bold text-[20px]'>Plan Details</div>
        <div className='font-bold mb-2'>Benefits</div>
        {
          benefits?.map((d,index) => {
            return (
              <div className='flex items-center mb-4'>
                <FaCheck color={"#DBD5FC"} />
                <div className='ml-[12px]'>{d.name}</div>
              </div>
            )
          })
        }
      </div>  
    </div>
  )
}

export default AllBenefit