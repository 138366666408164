import React from "react";
import FormControl from "@mui/material/FormControl";

const CustomFormControl = ({ styles = { mt: 4 }, children, ...props }) => {
  return (
    <FormControl sx={{ width: "100%", backgroundColor: "#FAFAFA", ...styles }} variant="outlined" {...props}>
      {children}
    </FormControl>
  );
};

export default CustomFormControl;
