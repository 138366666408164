import React, { useState } from 'react'
import CustomSelect from '../../../../../../common/Select'
import { fetchHospitalList, fetchHospitalLocation, getHospitals } from '../../../../../../../store/hospitals';
import { useDispatch, useSelector } from 'react-redux';
import { getDentCare } from '../../../../../../../store/dentalCare';
import FormContainer from '../../../../../../Containers/FormContainer';
import styles from "../../index.module.css";
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';
import Form from '../../../../../../Containers/Form';
import AuthButton from '../../../../../../Containers/AuthButton';
import PricingCard from '../../../../../../UI/PricingCard';
import { useEffect } from 'react';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import { toast } from 'react-toastify';


function HospitalLocation({ reason, setReason, visit, handlePatientSubmit, pickedHospital, setPickedHospital, setAppointmentDate, onChange, amount=0, promo=0 }) {
  const token = localStorage.getItem("token");
  const { loading } = useSelector(getDentCare);
  const { locations, } = useSelector(getHospitals);
  const [hospitalLocation, setHospitalLocation] = useState("");
  const [hospital, setHospital] = useState("");
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [location, setLocation] = useState([]);
  const [list, setList] = useState([]);
  const [load, setLoading] = useState(false);

  const fetchHospitalLists = async (item) => {
    setLoading(true);
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive/${item}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setList(data.data.health_centres);
      console.log(data);
      setLoading(false);


    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-right"
      });
    }
  }
  const fetchHospitalLocation = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v2/health-centre/locations/comprehensive`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }
      });
      setLocation(data?.data?.locations);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchHospitalLocation();

  }, []);

  useEffect(() => {
    fetchHospitalLists(hospitalLocation);

  }, [hospitalLocation]);


  const hanldeSetHospital = (value) => {
    console.log(value);
    const item = list.find((item) => item.name === value);
    setPickedHospital(item);
  };

  const handleHospitalLocationChange = ({ target }) => {
    setHospitalLocation(target.value);
    fetchHospitalLists(target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(2);
  }


  return (
    <>
      {
        <div className={styles["plan-wrapper"]}>
          <div className="mb-[10px] lg:mb-10 flex items-center my-[20px]">
            <button className={styles["back-btn"]} onClick={() => history.goBack()}>
              <IoArrowBackOutline />
            </button>
            <p className="ml-1.5">Back</p>
          </div>
          {
            step == 1 &&
            <div className={styles["plan-container"] + 'w-full block m-0 lg:flex'}>
              <div className="w-full lg:w-[50%] hidden sm:block">
                <div className="text-[#EC4D67] text-[24px] font-bold">Select Hospital Location</div>
                <div className='text-[16px]'>Select a location where you would like to receive your care</div>
              </div>
              <FormContainer className={styles["form-container"]}>
                <Form onSubmit={handleSubmit} className="py-5">
                  <CustomSelect
                    label="Hospital Location"
                    value={hospitalLocation}
                    onChange={handleHospitalLocationChange}
                    loading={loading}
                    items={location}
                    required
                  />
                  <CustomSelect
                    label="Hospital"
                    value={hospital}
                    onChange={({ target }) => hanldeSetHospital(target.value)}
                    loading={load}
                    items={list}
                    required
                  />
                  <div className="pb-10">
                    <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
                  </div>
                  {visit ? null : <PricingCard disabled={true} />}
                  {promo &&
                    <div className='flex'>
                      <div>{promo}% discount</div>
                      <div className='ml-[10px] line-through font-bold'>N{amount}</div>
                    </div>}
                </Form>
              </FormContainer>
            </div>
          }
          {
            step == 2 &&
            <div className={styles["plan-container"] + 'w-full block m-0 lg:flex'}>
              <div className="w-full lg:w-[50%] hidden sm:block">
                <div className="text-[#EC4D67] text-[24px] font-bold">{pickedHospital.name}</div>
                <div className='text-[16px]'>Select a date and time to visit {pickedHospital.name}</div>
              </div>
              <FormContainer className={styles["form-container"]}>
                <Form onSubmit={handlePatientSubmit} className="py-5">
                  <div className=' p-[5px] flex flex-col '>
                    <div>
                      <div className="my-[10px]">Select Appointment Date</div>
                      <input required type="date" onChange={(e) => setAppointmentDate(e.target.value)} placeholder="Select Appointment Date" className="border w-full h-[50px] p-[20px] rounded-[8px]" />
                    </div>
                    <div>
                      <div className="my-[10px]">Select Appointment Time</div>
                      <TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
                    </div>
                    {visit &&
                      <textarea
                        required
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder='state reason'
                        className='border h-[200px] p-2 rounded border-[#eded] mt-[20px]'
                      />
                    }
                  </div>
                  <div className="pb-10">
                    <AuthButton className="mt-5 bg-[#EC4D67]">Proceed</AuthButton>
                  </div>
                  {visit ? null :
                    <PricingCard disabled={true} />

                  }
                  {promo &&
                    <div className='flex'>
                      <div>{promo}% discount</div>
                      <div className='ml-[10px] line-through font-bold'>N{amount}</div>
                    </div>}
                </Form>
              </FormContainer>
            </div>
          }
        </div>
      }
    </>

  )
}

export default HospitalLocation