import React, { useEffect, useState } from "react";
import styles from "../../BasicHealthCheckup/index.module.css";
import FormContainer from "../../../../../../../Containers/FormContainer";
import MenBelow40 from "./MenBelow40";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getInsuranceCalculator } from "../../../../../../../../store/insuranceCalculator";
import { addAmount, getUserInfo, setPrice} from "../../../../../../../../store/userData";
import ModalLoader from "../../../../../../../common/Loader";
import axios from "axios";
import { fetchPromo } from "../../../../../../../../utils/helper";

const CancerScreening = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [belowData, setBelowData] = useState([]);
  const { loading} = useSelector(getInsuranceCalculator);
  const [cost, setCost] = useState('');
  const {step} = useParams();
  const [type,setType] = useState('');
  const [genderText, setGenderText] = useState('');
  const { amount_paid } = useSelector(getUserInfo);
  const [promo, setPromo] = useState('');

  useEffect(() => {
    fetchPromo(setPromo);
}, [promo]);

  let myType = '';

  const fetchBelow = async() => {
    setLoad(true);
    if(step == "men-below-40") {
      setType("mb");
      myType = "mb" ;
      setGenderText("Men (Below 40 years)");
    }
     else if(step == "men-above-40") {
      setType("mp");
      myType = "mp" ;
      setGenderText("Men (Above 40 years)");
    } 
   else if(step == "women-below-40") {
      setType("wb");
      myType = "wb" ;
      setGenderText("Women (Below 40 years)");
    } 
   else if (step == "women-above-40") {
      setType("wp");
      myType = "wp" ;
      setGenderText("women (Above 40 years)");
    }
    
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v2/comprehensive/care/${myType}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`${token}`
        }
      });
      
      let newData = [];

      data?.data?.screenings?.values?.map((d,index) => {
          newData.push({ 
            name: data?.data?.screenings?.names[index],
            value: d
          })
      });
      setBelowData(newData);
      setLoad(false);
      setCost(data.data.cost);
      dispatch(addAmount(data.data.cost - (promo/100 * data.data.cost)));
      dispatch(setPrice(data.data.cost - (promo/100 * data.data.cost)))
    }catch(err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchBelow();
  },[step,promo]);


  return (
    <div className={styles["plan-wrapper"]}>
      <div className="mb-10 flex items-center">
        <button className={styles["back-btn"]} onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">{genderText}</p>
      </div>
      <div className={styles["container"]}>
        <>
          <ModalLoader visible={loading} />
          <div className={styles["title-container"]}>
            <p className={styles["title"]}>{genderText}</p>
            <p className={styles["sub-title"]}>This is a plan tailored for {genderText}</p>
          </div>
        </>
        <FormContainer className={styles["form-container"]}>
          <MenBelow40 
            type={type} 
            belowData={belowData}
            cost={cost} 
            amount={amount_paid}
            promo={promo}
            setPromo={setPromo}
           />
        </FormContainer>
      </div>
    </div>
  );
};

export default CancerScreening;
