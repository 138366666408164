import React, { useState } from 'react'
import Avatar from '../../../../assets/images/Avatar.png';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {FaFile} from 'react-icons/fa'
import axios from 'axios';
import ModalLoader from '../../../common/Loader';
import styles from "../Refil/index.module.css";
import { IoArrowBackOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";

function Dependents() {
    const [allDep, setAllDep] = useState([]);
    const routePath = useLocation();
    const [isLoading , setIsLoading] = useState(false);
    const token = localStorage.getItem("token");
    const history = useHistory();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        onTop()
    }, [routePath]);

    const fetchAllDependent = async() => {
        setIsLoading(true);
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/v2/users/dependents`,
            {
              
              headers: {
                'Content-Type': 'application/json',
                'Authorization':`${token}`
              }
            });
            setAllDep(data?.data?.dependants);
            setIsLoading(false);
            console.log(data);

        }catch(err) {
            setIsLoading(false);
            console.log(err);
        }
    }

    useEffect(() => {
        fetchAllDependent();
    },[]);
  return (
    <div className='px-5'>
        <ModalLoader visible={isLoading} />
        <div className="mb-10 flex items-center">
            <button className={styles["back-btn"]} onClick={() => history.goBack()}>
                <IoArrowBackOutline />
            </button>
            <p className="ml-1.5">Back</p>
        </div>
        <div className='mb-[56px]'>
            <div className='font-bold text-[24px] text-[#E6072C]'>Dependents</div>
            <div className='text-[#5A5454] text-[16px]'>Here’s a list of all your dependents</div>
        </div>
        <div className='w-full flex flex-col items-center justify-center'>
            {/* data */}
            {
            allDep.length > 0 ? 
            allDep?.map((d,index) => {
            return (
            <div className='w-full md:w-[70%] flex items-center justify-between mb-[20px] border rounded-[8px] border-[#F5F5F5] p-[8px]'>
                <div className='flex items-center'>
                    <div>
                        <img src={Avatar} width="50px" height="50px" />
                    </div>
                    {' '}
                    <div>
                        <div className="font-bold">{d?.first_name}{' '}{d?.last_name}</div>
                        <div className="text-[#918F9F] text-[12px]">---</div>
                    </div>
                </div>
                {/* <div className=''>
                    <div className="font-bold">Age</div>
                    <div className="text-[#918F9F] text-[12px]">31</div>
                </div> */}
                <div className=''>
                    <div className="font-bold">Date of Birth</div>
                    <div className="text-[#918F9F] text-[12px]">{d?.dob}</div>
                </div>
                <div className=''>
                    <div className="font-bold">Relationship</div>
                    <div className="text-[#918F9F] text-[12px]">{d?.relationship}</div>
                </div>
                <div className=''>
                    <div className="font-bold">Sex</div>
                    <div className="text-[#918F9F] text-[12px]">{d?.sex == 'm'?'Male':'Female'}</div>
                </div>
               
            </div>
                    )
                })
            : 
            <div className='flex flex-col justify-center items-center text-center'>
                <FaSearch size={36} />
                <div className='text-[24px]'>No data found</div>
                <div onClick={() => history.push('/dashboard/adddependant')} className='p-4 bg-blue-800 text-white rounded-[5px] cursor-pointer'>Add Dependant</div>
            </div>
            }
            
            
        </div>
        

        
    </div>
  )
}

export default Dependents