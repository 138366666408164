import React from 'react'
import CategoryList from '../../../../CategoryList'
import DoctorList from '../DoctorList'

function SearchDoc() {
  return (
    <div className='bg-white min-h-[100vh] pb-[50px]'>
        
        <div className='col-span-4 md:col-span-3'>
          <div className='mb-10 px-8 pt-5'>
            <h2 className='font-bold text-[30px]'>All Doctor</h2>
            <div>Access to a doctor and wellness expert at any time at no cost</div>
            <div className='hidden md:block'>
              {/* Category  */}
              <CategoryList />
            </div>
            <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-7 mt-4 lg:grid-cols-4'>
              <DoctorList />
              <DoctorList />
              <DoctorList />
              <DoctorList />
              <DoctorList />
              <DoctorList />
            </div>
          </div>
        </div>
      
    </div>
  )
}

export default SearchDoc