import React, {  } from "react";
import styles from "./index.module.css";
import RightSideUI from "./rightSideUI";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getInsuranceCalculator } from "../../../../../../../store/insuranceCalculator";
import ModalLoader from "../../../../../../common/Loader";

const CancerScreening = () => {
  const history = useHistory();
  const { loading} = useSelector(getInsuranceCalculator);

  return (
    <div className={styles["plan-wrapper"]}>
      <div className="mb-10 flex items-center">
        <button className={styles["back-btn"]} onClick={() => history.goBack()}>
          <IoArrowBackOutline />
        </button>
        <p className="ml-1.5">Comprehensive Annual Check</p>
      </div>
      <div className={styles["container"]}>
        <>
          <ModalLoader visible={loading} />
          <div className={styles["title-container"]}>
            <p className={styles["title"]}>Comprehensive Annual Check</p>
            <p className={styles["sub-title"]}>Please select health checkup based on age </p>
          </div>
        </>
        <div>
          <RightSideUI
            title={"Men (Below 40 Years)"}
            subtitle={"This is a plan tailored for men who are below 40 years of age"}
            url={"/dashboard/medical-checkup/health-care/create-health-care/comprehensive-check/men-below-40"}
          />
          <RightSideUI
            title={"Men (above 40 Years)"}
            subtitle={"This is a plan tailored for men who are above 40 years of age"}
            url={"/dashboard/medical-checkup/health-care/create-health-care/comprehensive-check/men-above-40"}
          />
          <RightSideUI
            title={"Women (Below 40 Years)"}
            subtitle={"This is a plan tailored for women who are below 40 years of age"}
            url={"/dashboard/medical-checkup/health-care/create-health-care/comprehensive-check/women-below-40"}
          />
          <RightSideUI
            title={"Women (40 Years and above)"}
            subtitle={"This is a plan tailored for women who are above 40 years of age"}
            url={"/dashboard/medical-checkup/health-care/create-health-care/comprehensive-check/women-above-40"}
          />
        </div>
      </div>
    </div>
  );
};

export default CancerScreening;
