import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getAuth, register } from "../../../store/auth";
import Loader from "../../common/Loader";
import styles from "./index.module.css";
import wellnessLogo from "../../../assets/images/wellness-logo.png";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import CustomFormControl from "../../common/FormControl";
import LeftSide from "../Auth/LeftSide";
import FormContainer from "../../Containers/FormContainer";
import AuthButton from "../../Containers/AuthButton";
import Form from "../../Containers/Form";
import { MdArrowBackIos} from "react-icons/md";
import { validateEmail } from "../../../utils/helper";
import { useParams } from "react-router-dom";

function Registerenrollee() {
  const enrolleeDetail = JSON.parse(localStorage.getItem('enrollee'));
  const [email, setEmail] = useState({ value: enrolleeDetail?.email, invalid: false });
  const [firstName, setFirstName] = useState({ value: enrolleeDetail?.firstname, invalid: false });
  const [lastName, setLastName] = useState({ value: enrolleeDetail?.lastname , invalid: false });
  const [password, setPassword] = useState({ value: "", invalid: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: enrolleeDetail?.phone, invalid: false });
  const [step, setStep] = useState(1);
  const params = useParams();

  const dispatch = useDispatch();
  const { loading } = useSelector(getAuth);
  const history = useHistory();
  const myUser = localStorage.getItem("otp");
  const checkReg = localStorage.getItem("reg");
  
  useEffect(() => {
    if(myUser && checkReg) {
      history.push('/otp');
      localStorage.removeItem('reg');
    }
  },[history]);
  const doSubmit = async (values) => {
    await dispatch(register(values));
    
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    
  };

  const validInput = (value, prevData, setData) => {
    if (!value.trim()) {
      const newData = { ...prevData };
      newData.invalid = true;
      setData(newData);
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    e.preventDefault();

    // proper error handling here
    if (!validateEmail(email.value)) {
      const newData = { ...email };
      newData.invalid = true;
      setEmail(newData);
      return;
    }
    if (!validInput(firstName.value, firstName, setFirstName)) return;
    if (!validInput(lastName.value, lastName, setLastName)) return;
    if (!validInput(password.value, password, setPassword)) return;
    if (!validInput(phoneNumber.value, phoneNumber, setPhoneNumber)) return;

    const creationData = {
      email: email.value,
      password: password.value,
      first_name: firstName.value,
      last_name: lastName.value,
      phone_number: phoneNumber.value,
    };

    doSubmit(creationData);
  };

  const handleSetValues = (prevValues, newValue, setFn) => {
    const newData = { ...prevValues };
    newData.value = newValue;
    newData.invalid = false;
    setFn(newData);
  };

  return (
    <div className={styles["login-screen"]}>
      <Loader visible={loading} />
      {/* left content*/}
      <div className="bg-[#ec4d67] h-full min-h-[100vh]">
       <LeftSide />
      </div>
      {/* right */}
      <div className={styles["right-container"]}>
        <FormContainer className={styles["form-container"]}>
          <div className='w-full flex items-center relative'>
            <MdArrowBackIos className="cursor-pointer absolute top-4 left-4 text-[20px] font-bold" onClick={() => history.goBack() } />
          </div>
          <img className={styles["form-logo"]} src={wellnessLogo} alt="wellness" />
          <h1 className={styles["form-title"]}>
            We need you to input your detail to get <br />
            your enrollee ID
          </h1>
          <Form className={styles["form"]} onSubmit={handleRegister}>
            <div className={styles["form-control"]}>
              <CustomFormControl styles={{ mt: 0 }}>
                <TextField
                  id="first-name"
                  label="First Name"
                  variant="outlined"
                  error={firstName.invalid}
                  value={firstName.value}
                  onChange={({ target }) => handleSetValues(firstName, target.value, setFirstName)}
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                  id="last-name"
                  label="Last Name"
                  variant="outlined"
                  error={lastName.invalid}
                  value={lastName.value}
                  onChange={({ target }) => handleSetValues(lastName, target.value, setLastName)}
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                  id="email"
                  label="Email Address"
                  variant="outlined"
                  error={email.invalid}
                  value={email.value}
                  onChange={({ target }) => handleSetValues(email, target.value, setEmail)}
                />
              </CustomFormControl>
              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                  type="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  error={password.invalid}
                  value={password.value}
                  onChange={({ target }) => handleSetValues(password, target.value, setPassword)}
                />
              </CustomFormControl>

              <CustomFormControl styles={{ mt: 2 }}>
                <TextField
                  id="phone-number"
                  label="Phone Number"
                  variant="outlined"
                  error={phoneNumber.invalid}
                  value={phoneNumber.value}
                  onChange={({ target }) => handleSetValues(phoneNumber, target.value, setPhoneNumber)}
                />
              </CustomFormControl>
            </div>
            <div className="flex justify-between"></div>
            <div>
              <AuthButton className="bg-[#EC4D67]">Sign Up</AuthButton>
              <div className="flex items-center mt-3 justify-center">
                <p className="mr-3">Don't have an account?</p>
                <Link to="/login" variant="body2">
                  Sign In
                </Link>
              </div>
            </div>
          </Form>
        </FormContainer>
      </div>
    </div>
  )
}

export default Registerenrollee