import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth";
import { createHealthInsurance } from "../../store/insuranceCalculator";
import { addAmount, getUserInfo } from "../../store/userData";
import { EXCLUDE_DEPENDENT_FIELD_NAME } from "../../utils/constants";
import Alert from "../common/Alert";
import { postBuyOther } from "../../utils/helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


const getHealthInfo = (fields) => {
    const VALUES = {
        demographics: {
            fields: [],
            values: [],
        },
        benefits: {
            fields: [],
            values: [],
        },
    };
    const keys = Object.keys(fields);

    for (const key of keys) {
        if (fields[key].type === "demographics" && fields[key] && fields[key].ans) {
            VALUES.demographics.fields.push(key);
            VALUES.demographics.values.push(fields[key].ans === "Select" ? "No" : fields[key].ans);
        }
        if (
            fields[key].type === "benefits" &&
            fields[key] &&
            fields[key].ans &&
            !fields[key].nameId &&
            !EXCLUDE_DEPENDENT_FIELD_NAME.includes(key)
        ) {
            VALUES.benefits.fields.push(key);
            VALUES.benefits.values.push(fields[key].ans === "Select" ? "No" : fields[key].ans);
        }
        if (
            fields[key].type === "benefits" &&
            fields[key] &&
            fields[key].ans &&
            fields[key].nameId &&
            !EXCLUDE_DEPENDENT_FIELD_NAME.includes(key)
        ) {
            VALUES.benefits.fields.push(fields[key].dependentId);
            VALUES.benefits.fields.push(fields[key].field);
            VALUES.benefits.values.push(fields[key].dependentName);
            VALUES.benefits.values.push(fields[key].ans);
        }
    }

    return VALUES;
};
const usePaystackMethods = (buyOther,promo,firstName,lastName,phone,email,pickedHospital,toast) => {
    const { amount_paid, isNormalPrice,normalPrice,fields, sex, type, hospital, spouse_sex, spouse_name } = useSelector(getUserInfo);
    const history = useHistory();
    const dispatch = useDispatch();
    let count;
    if (isNormalPrice) {
      
      count = 10 / 100 * normalPrice;
    } else {
      count = 0;
    }

    let newValue = normalPrice - count;

    const onSuccess = (reference) => {
        if(buyOther == 'myself') {
            const data = {
                newValue,
                sex,
                type,
                hospital,
                ...getHealthInfo(fields),
                transaction_id: reference.trxref,
            };
            if (spouse_sex && spouse_name) {
                data.spouse_name = spouse_name;
                data.spouse_sex = spouse_sex;
            }
            console.log(data);
            dispatch(createHealthInsurance(data));
        }else {
            let demograph = {
                newValue,
                sex,
                type,
                hospital,
                ...getHealthInfo(fields),
            }
            let obj = {
                request_type: "custom",
                data: {
                first_name: firstName,
                last_name: lastName,
                phone: phone,
                email: email,
                request_type: "custom",
                service_name: "custom",
                services: JSON.stringify(demograph),
                amount_paid: newValue,
                hospital_name: pickedHospital?.name,
                hospital_location: pickedHospital?.location,
                hospital_address: pickedHospital?.address,
                doctor_name: "",
                comment: "",
                appointment_date: "",
                has_promo: promo ? true : false,
                transaction_id: reference.trxref
                }
            }
            postBuyOther(obj,toast,history);
        }
        dispatch(addAmount(0));
        window.location.href('/dashboard')
        Alert.notication("success", "Subscription", "subscription successful");
        // Implementation for whatever you want to do with reference and after success call.
        // dispatch(logout());
        window.location.href('/dashboard')
        // setTimeout(() => {
        //      window.location.href('/dashboard');
        // }, 2000);
        console.log(reference);
        
    };

    // you can call this function anything
    const onClose = () => {
       // window.location.reload();
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log("closed");
    };

    return { onSuccess, onClose };
};

export default usePaystackMethods;