import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomFormControl from "../FormControl";
import SmallLoader from "../Loader/Small";
const SingleSelect = ({ loading = false, label, value = "", onChange = () => {}, items = [] }) => {
  return (
    <CustomFormControl fullWidth styles={{ mt: 3 }}>
      <InputLabel id={`select-${label}`}>{label}</InputLabel>
      <Select
        labelId={`select-${label}`}
        id={`select-${label}`}
        value={value}
        label={label}
        onChange={({ target }) => onChange(target.value)}
      >
        {loading ? (
          <SmallLoader visible={loading} />
        ) : (
          items.map((item, index, arr) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })
        )}
      </Select>
    </CustomFormControl>
  );
};

export default SingleSelect;
