import React from "react";
import styles from "./index.module.css";

const ProceedToPayment = ({ className = "", children }) => {
  return (
    <button className={`${className} ${styles["proceed-to-payment"]}`} type="submit">
      {children}
    </button>
  );
};

export default ProceedToPayment;
